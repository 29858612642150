import React, {useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import {
  FormControl,
  Input,
  InputLabel,
  Button as MuiButton,
  Paper,
  Typography,
  Grid as MuiGrid,
  Link as HyperLink
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import styled from "styled-components";

import { forgotPasswordAction  } from '../../redux/actions/authActions'
import ModalProgress from '../../components/dialog/ModalProgress' 
import ByBAlert from '../../components/alerts/ByBAlert'


const Button = styled(MuiButton)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Spacer = styled.div(spacing);
const Wrapper = styled(Paper)`
  background: ${props => props.theme.body.background};
  padding: ${props => props.theme.spacing(6)}px;
  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
  width: 420px;
`;

const ForgotPassword = () => {
  const [ email, setEmail ] = React.useState('');
  const [ alert, setAlert ] = React.useState(false);


  const history = useHistory();
  const dispacth = useDispatch();
  const forgotPassword = (user) => dispacth( forgotPasswordAction(user) );
  const forgotStatus = useSelector( state => state.authReducer.forgotStatus );
  const loading = useSelector( state => state.authReducer.loading );
  

  useEffect(() => {
    if( forgotStatus && forgotStatus === 'success' ){
      setEmail('');
      history.push('/auth/reset-password');
    } else {
      history.push('/auth/forgot-password');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forgotStatus]);
  
  const handleChange = (event) => {
    setAlert(false);
    setEmail(event.target.value);
  };

  const submitEmail = e => {
    e.preventDefault();
    if(email.trim() === '' ){
      setAlert(true);
      return;
    }else{
      setAlert(false);
    }
    forgotPassword( email );
  }
  
  return (
    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
      <Wrapper variant="outlined">
        

        <ByBAlert show={ alert }type="title" severity="error" message="Required fields are missing!"/>
        <Spacer mb={4} />     
        <ModalProgress open = { loading } modalTitle = 'Sending reset password email...'/>
     
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Forgot Password
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Enter your username to reset your password
        </Typography>
        
        <form>
          
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Username</InputLabel>
            <Input id="email" name="email" autoComplete="email" autoFocus inputProps={{name:'email'}} error={( alert )} onChange={handleChange} />
          </FormControl>
          <Spacer mb={4} />

          <Button fullWidth variant="contained" color="primary" mt={2} onClick={submitEmail} >Reset password </Button>
          <Spacer mb={2} />

          <Grid container direction="row" alignItems="center" mb={2}>
            <Grid item>
              <Typography component="h2" variant="body1">Go to {" "}
                <HyperLink href="/">Sign in</HyperLink>
              </Typography>
            </Grid>
          </Grid>

        </form>

      </Wrapper>
    </div>
  );
}
 
export default ForgotPassword;