export const SET_LANGUAGE = 'SET_LANGUAGE'
export const IOWATTS_LIST = 'IOWATTS_LIST'
export const IOWATTS_LIST_SUCCESS = 'IOWATTS_LIST_SUCCESS'
export const IOWATTS_LIST_ERROR = 'IOWATTS_LIST_ERROR'
export const IOWATTS_CREATE = 'IOWATTS_CREATE'
export const IOWATTS_CREATE_SUCCESS = 'IOWATTS_CREATE_SUCCESS'
export const IOWATTS_CREATE_ERROR = 'IOWATTS_CREATE_ERROR'
export const IOWATTS_DELETE = 'IOWATTS_DELETE'
export const IOWATTS_DELETE_SUCCESS = 'IOWATTS_DELETE_SUCCESS'
export const IOWATTS_DELETE_ERROR = 'IOWATTS_DELETE_ERROR'
export const IOWATTS_DELETE_THERE_ARE_PROGRAMS_ERROR = 'IOWATTS_DELETE_THERE_ARE_PROGRAMS_ERROR'
export const SET_CLEAR_STATES = 'SET_CLEAR_STATES'