import { blue, green, grey, indigo, red, teal, purple, lightBlue, cyan, yellow, amber } from "@material-ui/core/colors";

const blueVariant = {
  name: "Blue",
  palette: {
    primary: {
      main: blue[800],
      contrastText: "#FFF"
    },
    secondary: {
      main: blue[600],
      contrastText: "#FFF"
    }
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800]
    },
    indicator: {
      background: blue[600]
    }
  },
  sidebar: {
    color: "#FFF",
    background: blue[700],
    header: {
      color: "#FFF",
      background: blue[800],
      brand: {
        color: "#FFFFFF"
      }
    },
    footer: {
      color: "#FFF",
      background: blue[800],
      online: {
        background: "#FFF"
      }
    },
    category: {
      fontWeight: 400
    },
    badge: {
      color: "#000",
      background: "#FFF"
    }
  },
  body: {
    background: "#F7F9FC"
  }
};

const greenVariant = {
  name: "Green",
  palette: {
    primary: {
      main: green[800],
      contrastText: "#FFF"
    },
    secondary: {
      main: green[500],
      contrastText: "#FFF"
    }
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800]
    },
    indicator: {
      background: green[500]
    }
  },
  sidebar: {
    color: "#FFF",
    background: green[700],
    header: {
      color: "#FFF",
      background: green[800],
      brand: {
        color: "#FFFFFF"
      }
    },
    footer: {
      color: "#FFF",
      background: green[800],
      online: {
        background: "#FFF"
      }
    },
    category: {
      fontWeight: 400
    },
    badge: {
      color: "#000",
      background: "#FFF"
    }
  },
  body: {
    background: "#F9F9FC"
  }
};

const indigoVariant = {
  name: "Indigo",
  palette: {
    primary: {
      main: indigo[600],
      contrastText: "#FFF"
    },
    secondary: {
      main: indigo[400],
      contrastText: "#FFF"
    }
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800]
    },
    indicator: {
      background: indigo[500]
    }
  },
  sidebar: {
    color: "#FFF",
    background: indigo[600],
    header: {
      color: "#FFF",
      background: indigo[700],
      brand: {
        color: "#FFFFFF"
      }
    },
    footer: {
      color: "#FFF",
      background: indigo[700],
      online: {
        background: "#FFF"
      }
    },
    category: {
      fontWeight: 400
    },
    badge: {
      color: "#000",
      background: "#FFF"
    }
  },
  body: {
    background: "#F9F9FC"
  }
};

const tealVariant = {
  name: "Teal",
  palette: {
    primary: {
      main: teal[800],
      contrastText: "#FFF"
    },
    secondary: {
      main: teal[600],
      contrastText: "#FFF"
    }
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800]
    },
    indicator: {
      background: teal[600]
    }
  },
  sidebar: {
    color: "#FFF",
    background: teal[700],
    header: {
      color: "#FFF",
      background: teal[800],
      brand: {
        color: "#FFFFFF"
      }
    },
    footer: {
      color: "#FFF",
      background: teal[800],
      online: {
        background: "#FFF"
      }
    },
    category: {
      fontWeight: 400
    },
    badge: {
      color: "#000",
      background: "#FFF"
    }
  },
  body: {
    background: "#F7F9FC"
  }
};

const lightVariant = {
  name: "Light",
  palette: {
    primary: {
      main: '#6600a0',
      contrastText: "#e1e8ec",
      dark: "3d0b60"
    },
    secondary: {
      main: "#bce7d9",
      contrastText: "#4c5175"
    },
    textPrimary: {
      main: "#4c5175",
      contrastText: "#81888c"
    },
    textSecondary: {
      main: "#f8f8f8",
      contrastText: "#121212"
    },
    error: {
      main: red[900],
      contrastText: red[50]
    },
  },
  header: {
    color: grey[200],
    background: '#6600a0',
    search: {
      color: grey[100]
    },
    indicator: {
      background: red[700]
    }
  },
  sidebar: {
    color: grey[900],
    background: "#FFF",
    header: {
      color: "#FFF",
      background: '#6600a0',
      brand: {
        color: "#FFFFFF"
      }
    },
    footer: {
      color: grey[900],
      background: grey[100],
      online: {
        background: green[500]
      }
    },
    category: {
      fontWeight: 600
    },
    badge: {
      color: "#FFF",
      background: green[600]
    }
  },
  body: {
    background: "#F4F0FE" //F7F9FC
  },
  settingsCard:{
    color: "#f8f8f8",
    background: "#4c5175",
    minWidth: 220,
    maxWidth: 340,
    hover: {
      color: "#6600a0",
      background: "#bce7d9"
    }
  },
  deviceCard:{
    titleColor: purple[900],
    titleSelectedColor: purple[300],
    background: "#f8f8f8",
    selected: "#4c5175",
    hover: {
      background: "#bce7d9",
      selected: "#3c4165",
      alternativeBackground: cyan[50],
    },
    status: {
      success: '#e5ffe5',
      error: red[100],
      pending: yellow[50],
      selected: '#4c5175'
    }
  },
  button:{
    background: "#f8f8f8",
    color: "#4c5175",
    border: "#e1e8ec",
    hover: {
      background: "#bce7d9",
      color: "#6600a0",
      border: "#6600a0",
    },
    active:{
      background: amber[100],
      color: grey[800],
      border: grey[800],
    }
  },
  chip:{
    color: lightBlue[50],
    background: lightBlue[800],
    hover: {
      color: lightBlue[900],
      background: lightBlue[400]
    }
  },
  table:{
    head:{
      background: "#4c5175",
      color: "#f8f8f8",
    },
    row:{
      color: grey[700],
      odd:{
        background: indigo[50],
      },
      even:{
        background: indigo[100],
      }
    }
  }
};

const darkVariant = {
  name: "Dark",
  palette: {
    primary: {
      main: blue[700],
      contrastText: "#FFF"
    },
    secondary: {
      main: blue[500],
      contrastText: "#FFF"
    }
  },
  header: {
    color: grey[500],
    background: "#FFFFFF",
    search: {
      color: grey[800]
    },
    indicator: {
      background: blue[600]
    }
  },
  sidebar: {
    color: grey[200],
    background: "#1B2430",
    header: {
      color: grey[200],
      background: "#232f3e",
      brand: {
        color: blue[500]
      }
    },
    footer: {
      color: grey[200],
      background: "#232f3e",
      online: {
        background: green[500]
      }
    },
    category: {
      fontWeight: 400
    },
    badge: {
      color: "#FFF",
      background: blue[500]
    }
  },
  body: {
    background: "#F7F9FC"
  }
};

const variants = [
  lightVariant,
  darkVariant,
  blueVariant,
  greenVariant,
  indigoVariant,
  tealVariant
];

export default variants;
