import * as types from '../types/language';

const initialState = {
    src: 'us',
    alt: 'English',    
}

export default function(state = initialState, action){
    switch (action.type) {
        case types.CHANGE_LANGUAGE:
            return {
                ...state,
                src: action.payload.src,
                alt: action.payload.alt,
            }
        default:
            return state;
    }
}