export const SET_LANGUAGE = 'SET_LANGUAGE'

export const CLIENTS_LIST = 'CLIENTS_LIST'
export const CLIENTS_LIST_SUCCESS = 'CLIENTS_LIST_SUCCESS'
export const CLIENTS_LIST_ERROR = 'CLIENTS_LIST_ERROR'
export const CLIENTS_CREATE = 'CLIENTS_CREATE'
export const CLIENTS_CREATE_SUCCESS = 'CLIENTS_CREATE_SUCCESS'
export const CLIENTS_CREATE_ERROR = 'CLIENTS_CREATE_ERROR'
export const CLIENTS_UPDATE = 'CLIENTS_UPDATE'
export const CLIENTS_UPDATE_SUCCESS = 'CLIENTS_UPDATE_SUCCESS'
export const CLIENTS_UPDATE_ERROR = 'CLIENTS_UPDATE_ERROR'
export const CLIENTS_DELETE = 'CLIENTS_DELETE'
export const CLIENTS_DELETE_SUCCESS = 'CLIENTS_DELETE_SUCCESS'
export const CLIENTS_DELETE_ERROR = 'CLIENTS_DELETE_ERROR'
export const CLIENTS_HOLLIDAYS_CREATE = 'CLIENTS_HOLLIDAYS_CREATE'
export const CLIENTS_HOLLIDAYS_CREATE_SUCCESS = 'CLIENTS_HOLLIDAYS_CREATE_SUCCESS'
export const CLIENTS_HOLLIDAYS_CREATE_ERROR = 'CLIENTS_HOLLIDAYS_CREATE_ERROR'
export const CLIENTS_HOLLIDAYS_UPDATE = 'CLIENTS_HOLLIDAYS_UPDATE'
export const CLIENTS_HOLLIDAYS_UPDATE_SUCCESS = 'CLIENTS_HOLLIDAYS_UPDATE_SUCCESS'
export const CLIENTS_HOLLIDAYS_UPDATE_ERROR = 'CLIENTS_HOLLIDAYS_UPDATE_ERROR'