import * as types from '../types/gatewayDevices'
import { API, Auth, graphqlOperation  } from 'aws-amplify'
import { createGateways, updateGateways, deleteGateways   } from '../../graphql/mutations'
import { getGatewaysByCustomer, getGatewayById, variableByDevice } from '../../graphql-custom/queries'
import { deleteVariablesByGateway, getCurrentProgramsByGateway } from './iowattUtils'


import { v4 as uuidv4 } from 'uuid'


const dispatchDeviceChecker = async ( gateway, email ) => {
    const apiName = `${process.env.REACT_APP_EXEC_API_NAME}` // replace this with your api name.
    const path = `/programs/circuitor/xml/getInfo` //replace this with the path you have configured on your API
    const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken()
    const messageId = uuidv4()
    console.log(`retreiving devices with messageId ${messageId}`)   
    const payload = {
        body: {
            messageId: messageId,
            message: 'getDevices',
            customerId: gateway.customerGatewaysId,
            powerPlantId: gateway.iowatt.powerPlant,
            gatewayId: gateway.id,
            iowattId: gateway.iowatt.id,
            gatewayIp: gateway.ip,
            user: email,
            sub: `iowatt/sub/devices/${ gateway.iowatt.id }`
        },
        headers: {
            'X-AccessToken': accessToken,
            'X-Channel': 'Web',
            'X-CustIdentNum': gateway.customerGatewaysId,
            'X-CustIdentType': 'NI',
            'X-IsClient': 'Active Client',
            'X-Name': 'IoWatt',
            'X-PowerPlant': gateway.iowatt.powerPlant,
        },
    }
    const res = await API.post(apiName, path, payload).then(response => {
        return response
    }).catch(error => {
        console.log(error.response)
        return { status: 'ERROR', message: error}
    })
    return res
}

/* **************************************************************** */
/* *************************** pull devices *********************** */
/* **************************************************************** */

export function getDevicesAction( customerId )  {
    return async (dispath) => {
        dispath( startGetDevicesAction() )
        try{
            const prevResult = await API.graphql(graphqlOperation( getGatewaysByCustomer, { id : customerId } ))
            dispath( startGetDevicesActionSuccess( prevResult.data.getCustomer.gateways.items ) )
        } catch (error) {
            console.log(error)
            dispath( startGetDevicesActionError())
        }
    }
}

const startGetDevicesAction = () => ({
    type: types.GET_DEVICES,
    payload: true    
})

const startGetDevicesActionSuccess = ( gateways ) => ({
    type: types.GET_DEVICES_SUCCESS,
    payload: gateways
})

const startGetDevicesActionError = () => ({
    type: types.GET_DEVICES_ERROR,
    payload: false
})

export function pullDevicesAction( gateway, email )  {
    return async (dispath) => {
        dispath( startPullDevicesAction() )
        try{
            const result = await API.graphql( graphqlOperation( getGatewayById, { id: gateway.id } ) )
            const devicesByGateway = result.data.getGateways.devices
            if( devicesByGateway.items.length > 0 ) {
                dispath( startPullDevicesActionSuccess( { id: gateway.id, quantity: devicesByGateway.items.length, devices: devicesByGateway } ) )
            } else {
                const deviceChecker = await dispatchDeviceChecker( gateway, email )
                if( deviceChecker && deviceChecker.status === 'OK' ) {
                    for (let i = 0; i < 5; i++) {
                        await new Promise((resolve) => { setTimeout(resolve, 3000)})
                        const result = await API.graphql(graphqlOperation( getGatewayById, { id : gateway.id } ))
                        const devicesByGateway = result.data.getGateways.devices
                        if( devicesByGateway.items.length > 0 ) {
                            dispath( startPullDevicesActionSuccess( { id: gateway.id, quantity: devicesByGateway.items.length, devices: devicesByGateway } ) )
                            break
                        }
                        if( i === 4 && devicesByGateway.items.length < 1 ){
                            dispath( startPullDevicesActionError( gateway.id ) )
                        }
                    }
                } else {
                    dispath( startPullDevicesActionError( gateway.id ) ) 
                }
            }
        } catch (error) {
            console.log(error)
            dispath( startPullDevicesActionError( gateway.id ) )
        }
    }
}

const startPullDevicesAction = () => ({
    type: types.PULL_DEVICES,
    payload: true    
})

const startPullDevicesActionSuccess = ( gateway ) => ({
    type: types.PULL_DEVICES_SUCCESS,
    payload: gateway
})

const startPullDevicesActionError = ( gateway ) => ({
    type: types.PULL_DEVICES_ERROR,
    payload: gateway
})

/* **************************************************************** */
/* ************************** create gateway ********************** */
/* **************************************************************** */

export function createGatewayAction ( customerId, iowattId, gateway ){
    return async (dispath) => {
        dispath( createGatewayActionStart())
        gateway['customerGatewaysId'] = customerId
        gateway['ioWattGatewaysId'] = iowattId
        try{
            const result = await  API.graphql(graphqlOperation( createGateways, { input: gateway } ))
            dispath( createGatewayActionStartSuccess( result.data.createGateways ))
        } catch( error ){
            console.log(error)
            dispath( createGatewayActionStartError())
        }
    }
}

const createGatewayActionStart = () => ({
    type: types.CREATE_DEVICES,
    payload: true
})

const createGatewayActionStartError = () => ({
    type: types.CREATE_DEVICES_ERROR,
    payload: false
})

const createGatewayActionStartSuccess = ( gateway ) => ({
    type: types.CREATE_DEVICES_SUCCESS,
    payload: gateway
})

/* **************************************************************** */
/* ************************** update gateway ********************** */
/* **************************************************************** */

export function updateGatewayAction( gateway ){
    return async (dispath) => {
        dispath( updateGatewayActionStart() )
        try{
            const result = await API.graphql(graphqlOperation( updateGateways, { input: {
                id : gateway.id,
                name : gateway.name,
                ip : gateway.ip,
                dataLake : gateway.dataLake,
                status : gateway.status,
                driverType : gateway.driverType,
                timeOffset: gateway.timeOffset,
                type : gateway.type,
                ioWattGatewaysId: gateway.ioWattGatewaysId
            }}))
            const updated = result.data.updateGateways
            updated['deviceQuantity'] = 'deviceQuantity' in gateway ? gateway.deviceQuantity : 0
            updated['pullIsReady'] = 'pullIsReady' in gateway ? gateway.pullIsReady : false
            dispath( updateGatewayActionSuccess( result.data.updateGateways ));
        } catch (error) {
            console.log(error);
            dispath( updateGatewayActionError() );
        }
    }
}

const updateGatewayActionStart = () => ({
    type: types.UPDATE_DEVICES,
    payload: true
})

const updateGatewayActionError = () => ({
    type: types.UPDATE_DEVICES_ERROR,
    payload: false
})

const updateGatewayActionSuccess = ( gateway ) => ({
    type: types.UPDATE_DEVICES_SUCCESS,
    payload: gateway
})


/* **************************************************************** */
/* ************************** delete gateway ********************** */
/* **************************************************************** */

export function deleteGatewayAction( gateway ){
    return async (dispath) => {
        dispath( deleteGatewayActionStart() )
        try{
            const programs =  await getCurrentProgramsByGateway( gateway )
            if( programs && programs.length > 0 ) {
                dispath( thereAreProgramsError( programs ))
                return
            }
            await API.graphql(graphqlOperation( deleteGateways, { input: { id : gateway.id } } ))
            dispath( deleteGatewayActionSuccess( gateway.id ))
            deleteVariablesByGateway( gateway )
        } catch (error) {
            console.log(error);
            dispath( deleteGatewayActionError() );
        }
    }
}

const deleteGatewayActionStart = () => ({
    type: types.DELETE_DEVICES,
    payload: true
})

const deleteGatewayActionError = () => ({
    type: types.DELETE_DEVICES_ERROR,
    payload: false
})

const deleteGatewayActionSuccess = ( gateway ) => ({
    type: types.DELETE_DEVICES_SUCCESS,
    payload: gateway
})

const thereAreProgramsError =  ( programs ) => ({
    type: types.DELETE_DEVICES_THERE_ARE_PROGRAMS_ERROR,
    payload: programs
})

/* **************************************************************** */
/* *************************** list variables ********************* */
/* **************************************************************** */


export function listVariablesAction( deviceId, nextToken )  {
    return async (dispath) => {
        dispath( startListVariablesAction( nextToken ? true : false ) )
        const criteria = { 
            deviceKey: deviceId,
            statusVarId: {
                beginsWith: {
                    status: "SUCCESS"
                }
            },
            sortDirection: 'ASC',
            limit: 10 
        }
        if( nextToken ){
            criteria['nextToken'] = nextToken
        }
        try{
            const prevResult = await API.graphql(graphqlOperation( variableByDevice, criteria ))
            dispath( startListVariablesActionSuccess( { variables: prevResult.data.variableByDevice, isFirst: nextToken ? false : true } ) )
        } catch (error) {
            console.log(error)
            dispath( startListVariablesActionError())
        }
    }
}

const startListVariablesAction = ( isFirst ) => ({
    type: types.LIST_VARIABLES,
    payload: isFirst    
})

const startListVariablesActionSuccess = ( gateways ) => ({
    type: types.LIST_VARIABLES_SUCCESS,
    payload: gateways
})

const startListVariablesActionError = () => ({
    type: types.LIST_VARIABLES_ERROR,
    payload: false
})

/* **************************************************************** */
/* *************************** set language *********************** */
/* **************************************************************** */

export function setLanguageAction( lan ){
    return async (dispath) => {
        dispath( setLanguage( lan ) )
    }
}

const setLanguage = (lan) => ({
    type: types.SET_LANGUAGE,
    payload: lan
})

/* **************************************************************** */
/* ************************* clear states ************************* */
/* **************************************************************** */

export function devicesClearStatesAction(){
    return async (dispath) => {
        dispath( setClearStates() )
    }
}

const setClearStates = () => ({
    type: types.SET_CLEAR_STATES,
    payload: true
})