import * as constants from '../../constants/index'
import * as types from '../types/iowattDispatcher'

const initialState = {
    language: 'us',
    executing: false,
    alertShow: false,
    alertMessage: null,
}

export default function(state = initialState, action){
    switch (action.type) {
        case types.SET_LANGUAGE:
            return {
                ...state,
                language: action.payload
            }
        case types.START_INSTALLATION:
            return {
                ...state,
                executing: true,
                alertShow: false,
                alertMessage: null,
            }
        case types.START_INSTALLATION_SUCESS:
            return {
                ...state,
                executing: false,
                alertShow: true,
                alertMessage: state.language === constants.LAN_ES ? constants.INSTALLATION_DISPATCHER_START_SUCESS_ES : constants.INSTALLATION_DISPATCHER_START_SUCESS_EN,
            }
        case types.START_INSTALLATION_ERROR:
            return {
                ...state,
                executing: false,
                alertShow: true,
                alertMessage: state.language === constants.LAN_ES ? constants.INSTALLATION_DISPATCHER_START_ERROR_ES : constants.INSTALLATION_DISPATCHER_START_ERROR_EN,
            }
        default:
            return state;
    }
}