export const SET_LANGUAGE = 'SET_LANGUAGE'

export const PRODUCT_LIST = 'PRODUCT_LIST'
export const PRODUCT_LOADING_IMAGE = 'PRODUCT_LOADING_IMAGE'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_ERROR = 'PRODUCT_LIST_ERROR'
export const PRODUCT_CREATE = 'PRODUCT_CREATE'
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS'
export const PRODUCT_CREATE_ERROR = 'PRODUCT_CREATE_ERROR'
export const PRODUCT_UPDATE = 'PRODUCT_UPDATE'
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS'
export const PRODUCT_UPDATE_ERROR = 'PRODUCT_UPDATE_ERROR'
export const PRODUCT_DELETE = 'PRODUCT_DELETE'
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS'
export const PRODUCT_DELETE_ERROR = 'PRODUCT_DELETE_ERROR'