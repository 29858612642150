export const SET_LANGUAGE = 'SET_LANGUAGE'
export const IOWATT_GET_INFO = 'IOWATT_GET_INFO'
export const IOWATT_GET_INFO_SUCCESS = 'IOWATT_GET_INFO_SUCCESS'
export const IOWATT_GET_INFO_ERROR = 'IOWATT_GET_INFO_ERROR'
export const IOWATT_RELEASE_INFO = 'IOWATT_RELEASE_INFO'
export const GET_VARIABLES_BY_IOWATT = 'GET_VARIABLES_BY_IOWATT'
export const GET_VARIABLES_BY_IOWATT_SUCCESS = 'GET_VARIABLES_BY_IOWATT_SUCCESS'
export const GET_VARIABLES_BY_IOWATT_ERROR = 'GET_VARIABLES_BY_IOWATT_ERROR'
export const PROGRAMS_LIST = 'PROGRAMS_LIST'
export const PROGRAMS_LIST_SUCCESS = 'PROGRAMS_LIST_SUCCESS'
export const PROGRAMS_LIST_ERROR = 'PROGRAMS_LIST_ERROR'
export const PROGRAMS_SHADOW_LIST_SUCCESS = 'PROGRAMS_SHADOW_LIST_SUCCESS'
export const PROGRAMS_CREATE = 'PROGRAMS_CREATE'
export const PROGRAMS_CREATE_SUCCESS = 'PROGRAMS_CREATE_SUCCESS'
export const PROGRAMS_CREATE_ERROR = 'PROGRAMS_CREATE_ERROR'
export const PROGRAMS_UPDATE = 'PROGRAMS_UPDATE'
export const PROGRAMS_UPDATE_SUCCESS = 'PROGRAMS_UPDATE_SUCCESS'
export const PROGRAMS_UPDATE_ERROR = 'PROGRAMS_UPDATE_ERROR'
export const PROGRAMS_DELETE = 'PROGRAMS_DELETE'
export const PROGRAMS_DELETE_SUCCESS = 'PROGRAMS_DELETE_SUCCESS'
export const PROGRAMS_DELETE_ERROR = 'PROGRAMS_DELETE_ERROR'
export const SET_CLEAR_STATES = 'SET_CLEAR_STATES'
