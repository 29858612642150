import React, {useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Helmet from 'react-helmet';

import styled from "styled-components";
import { getCountries } from '../../helpers';
import { spacing } from "@material-ui/system";
import {
  FormControl,
  Select as MuiSelect,
  Input,
  InputLabel,
  Button as MuiButton,
  Paper,
  Typography,
  Grid as MuiGrid,
  Link as HyperLink
} from "@material-ui/core";
import validator from 'validator'

import ModalProgress from '../../components/dialog/ModalProgress' 
import ByBAlert from '../../components/alerts/ByBAlert'
import { createUserAction  } from '../../redux/actions/authActions'


const Select = styled(MuiSelect)(spacing);
const Button = styled(MuiButton)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Spacer = styled.div(spacing);

const Wrapper = styled(Paper)`
  background: ${props => props.theme.body.background};
  padding: ${props => props.theme.spacing(6)}px;
  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
  width: 420px;
`;


function SignUp() {

  const history = useHistory();
  const [ account, setAccount ] = React.useState({
    password: '',
    email: '',
    company: '',
    country: '',
    phone: ''
  });

  const [ error, setError ] = React.useState({
    password: false,
    email: false,
    country: false,
    phone: false
  });

  const [ alert, setAlert ] = React.useState(false);
  const [ start, setStart ] = React.useState(false);
  const [ filling, setFilling ] = React.useState(false);
  const [ valid, setValid ] = React.useState(true);

  const dispacth = useDispatch();
  const createStatus = useSelector( state => state.authReducer.status );
  const loading = useSelector( state => state.authReducer.loading );
  const createMessage = useSelector( state => state.authReducer.message );
  const createUsername = useSelector( state => state.authReducer.username );
  const createUser = (user) => dispacth( createUserAction(user) );

  
  useEffect(() => {
    if(createUsername){
      setAccount({ email: '', password: '', company: '', country: '', phone: '' });
      setError({ email: false, password: false, country: false, phone: false });
      history.push('/auth/confirm-sign-up');
    } else {
      history.push('/auth/sign-up');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createUsername]);
  
  
  const handleChange = (event) => {
    setFilling(true);
    setValid(true);
    setAccount({
      ...account,
      [ event.target.name]: event.target.value,
    });
    setError({
      ...error,
      [ event.target.name ]: event.target.value.trim() ? true : false,
    });
  };

  const { email, password, company, country, phone } = account

  const submitAccount = e => {
    e.preventDefault();
    setStart(true);
    setFilling(false);
    if( email.trim() === '' || password.trim() === '' ||  country.trim() === '' || phone.trim() === '' ) {
      setAlert(true);
      return;
    }else{
      setAlert(false);
    }

    if( !validator.isEmail(email.trim()) ){
      setValid(false);
      return;
    } else {
      setValid(true);
    }
    createUser({ email , password, phone, country, company });
  }

  return (
    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
      <Wrapper variant="outlined">
        <Helmet title="Sign Up" />        
        
        <ByBAlert show={ createStatus && !filling && !alert } type="title" severity={ createStatus } message={ createMessage }/> 
        <ByBAlert show={!filling && alert} type="title" severity="error" message="Required fields are missing!"/>   
        <Spacer mb={4} />     
        <ModalProgress open = { loading } modalTitle = 'Creating account...'/>
        
        <Typography component="h1" variant="h4" align="center" gutterBottom>Create a new account</Typography>

        <form>
          
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email" >Email Address</InputLabel>
            <Input id="email" name="email" inputProps={{name:'email'}} error={( !error.email && start )} onChange={handleChange} />
            <ByBAlert show={!valid} type="field" severity="error" message="Please enter a valid email address."/>  
          </FormControl>

          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input name="password" type="password" id="password" inputProps={{name:'password'}} error={( !error.password && start )} onChange={handleChange} />
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="company">Company</InputLabel>
            <Input id="company" name="company" inputProps={{name:'company'}} error={false} onChange={handleChange}  />
          </FormControl>
          
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="country">Country</InputLabel>
            <Select native value={account.country} inputProps={{name: 'country',id: 'country',}} error={( !error.country && start )} onChange={handleChange}>
              <option aria-label="None" value="" />
              { getCountries().map( index => ( <option key={index.code} value={index.dial_code + "@" + index.name }>{index.name}</option>))}
            </Select>
          </FormControl>

          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="phone">Phone number</InputLabel>
            <Input id="phone" name="phone" inputProps={{name:'phone'}} error={( !error.phone && start )} onChange={handleChange}/>
          </FormControl>

          <Spacer mb={8} />

          <Button fullWidth variant="contained" color="primary" mt={2} onClick={submitAccount}>Sign up</Button>

        </form>

        <Spacer mb={4} />

        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <Typography component="h2" variant="body1">
              Have an account? {" "}
              <HyperLink href="/">Sign in</HyperLink>
            </Typography>
          </Grid>
        </Grid>

      </Wrapper>
    </div>
  );
}

export default SignUp;
