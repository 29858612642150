export const SET_LANGUAGE = 'SET_LANGUAGE'

export const CALENDAR_LIST = 'CALENDAR_LIST'
export const CALENDAR_LIST_SUCCESS = 'CALENDAR_LIST_SUCCESS'
export const CALENDAR_LIST_ERROR = 'CALENDAR_LIST_ERROR'
export const CALENDAR_CREATE = 'CALENDAR_CREATE'
export const CALENDAR_CREATE_SUCCESS = 'CALENDAR_CREATE_SUCCESS'
export const CALENDAR_CREATE_ERROR = 'CALENDAR_CREATE_ERROR'
export const CALENDAR_UPDATE = 'CALENDAR_UPDATE'
export const CALENDAR_UPDATE_SUCCESS = 'CALENDAR_UPDATE_SUCCESS'
export const CALENDAR_UPDATE_ERROR = 'CALENDAR_UPDATE_ERROR'
export const CALENDAR_DELETE = 'CALENDAR_DELETE'
export const CALENDAR_DELETE_SUCCESS = 'CALENDAR_DELETE_SUCCESS'
export const CALENDAR_DELETE_ERROR = 'CALENDAR_DELETE_ERROR'
export const CALENDAR_HOLLIDAYS_CREATE = 'CALENDAR_HOLLIDAYS_CREATE'
export const CALENDAR_HOLLIDAYS_CREATE_SUCCESS = 'CALENDAR_HOLLIDAYS_CREATE_SUCCESS'
export const CALENDAR_HOLLIDAYS_CREATE_ERROR = 'CALENDAR_HOLLIDAYS_CREATE_ERROR'
export const CALENDAR_HOLLIDAYS_UPDATE = 'CALENDAR_HOLLIDAYS_UPDATE'
export const CALENDAR_HOLLIDAYS_UPDATE_SUCCESS = 'CALENDAR_HOLLIDAYS_UPDATE_SUCCESS'
export const CALENDAR_HOLLIDAYS_UPDATE_ERROR = 'CALENDAR_HOLLIDAYS_UPDATE_ERROR'

export const HOLLIDAYS_GET = 'HOLLIDAYS_GET'
export const HOLLIDAYS_GET_SUCCESS = 'HOLLIDAYS_GET_SUCCESS'
export const HOLLIDAYS_GET_ERROR = 'HOLLIDAYS_GET_ERROR'
export const HOLLIDAYS_CREATE = 'HOLLIDAYS_CREATE'
export const HOLLIDAYS_CREATE_SUCCESS = 'HOLLIDAYS_CREATE_SUCCESS'
export const HOLLIDAYS_CREATE_ERROR = 'HOLLIDAYS_CREATE_ERROR'
export const HOLLIDAYS_UPDATE = 'HOLLIDAYS_UPDATE'
export const HOLLIDAYS_UPDATE_SUCCESS = 'HOLLIDAYS_UPDATE_SUCCESS'
export const HOLLIDAYS_UPDATE_ERROR = 'HOLLIDAYS_UPDATE_ERROR'
