export const SET_LANGUAGE = 'SET_LANGUAGE'
export const PULL_DEVICES = 'PULL_DEVICES'
export const PULL_DEVICES_SUCCESS = 'PULL_DEVICES_SUCCESS'
export const PULL_DEVICES_ERROR = 'PULL_DEVICES_ERROR'
export const GET_DEVICES = 'GET_DEVICES'
export const GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS'
export const GET_DEVICES_ERROR = 'GET_DEVICES_ERROR'
export const CREATE_DEVICES = 'CREATE_DEVICES'
export const CREATE_DEVICES_SUCCESS = 'CREATE_DEVICES_SUCCESS'
export const CREATE_DEVICES_ERROR = 'CREATE_DEVICES_ERROR'
export const UPDATE_DEVICES = 'UPDATE_DEVICES'
export const UPDATE_DEVICES_SUCCESS = 'UPDATE_DEVICES_SUCCESS'
export const UPDATE_DEVICES_ERROR = 'UPDATE_DEVICES_ERROR'
export const DELETE_DEVICES = 'DELETE_DEVICES'
export const DELETE_DEVICES_SUCCESS = 'DELETE_DEVICES_SUCCESS'
export const DELETE_DEVICES_ERROR = 'DELETE_DEVICES_ERROR'
export const DELETE_DEVICES_THERE_ARE_PROGRAMS_ERROR = 'DELETE_DEVICES_THERE_ARE_PROGRAMS_ERROR'
export const LIST_VARIABLES = 'LIST_VARIABLES'
export const LIST_VARIABLES_SUCCESS = 'LIST_VARIABLES_SUCCESS'
export const LIST_VARIABLES_ERROR = 'LIST_VARIABLES_ERROR'
export const SET_CLEAR_STATES = 'SET_CLEAR_STATES'