import * as types from '../types/gatewayConfig'
import * as constants from '../../constants/index'

const initialState = {
    language: 'us',
    loading: false,
    loadingMessage: null,
    alertShow: false,
    alertSeverity: constants.SUCCESS,
    alertMessage: null,
    versions: {},
    unhealthyCores: [],
    configs: [],
    lastInitialConfig: null,
    lastConfigInvokeStatus: null,
    lastDBConfingIvokeStatus: null,
    lastDbConfigData: null
}

export default function(state = initialState, action){
    switch (action.type) {
        case types.SET_LANGUAGE:
            return {
                ...state,
                language: action.payload
            }
        case types.SET_CLEAR_STATES:
            return {
                language: 'us',
                loading: false,
                loadingMessage: null,
                alertShow: false,
                alertSeverity: constants.SUCCESS,
                alertMessage: null,
                versions: {},
                unhealthyCores: [],
                configs: [],
                lastInitialConfig: null,
                lastConfigInvokeStatus: null,
                lastDBConfingIvokeStatus: null,
                lastDbConfigData: null
            } 
        case types.COMPONENT_LIST:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.COMPONENT_LIST_EN : constants.COMPONENT_LIST_ES,
                alertShow: false,
            }
        case types.COMPONENT_LIST_SUCCESS:
            const name = action.payload.name
            const componentVersions = state.versions
            if(  action.payload.response && action.payload.response.status === constants.OK && action.payload.response.message 
                && action.payload.response.message.componentVersions && action.payload.response.message.componentVersions.length > 0  ){
                componentVersions[name] = action.payload.response.message.componentVersions[0].componentVersion
            } else {
                componentVersions[name] =  constants.UNKNOWN
            }
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: false,
                alertSeverity: constants.SUCCESS,
                alertMessage: null,
                versions: componentVersions
            }
        case types.COMPONENT_LIST_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.COMPONENT_LIST_ERROR_EN : constants.COMPONENT_LIST_ERROR_ES,
            }        
        case types.CORES_HEALTH_LIST:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.CORES_HEALTH_LIST_EN : constants.CORES_HEALTH_LIST_ES,
                alertShow: false,
            }
        case types.CORES_HEALTH_LIST_SUCCESS:
            let unhealthyCores
            if(  action.payload.response && action.payload.response.status === constants.OK && action.payload.response.message 
                && action.payload.response.message.coreDevices && action.payload.response.message.coreDevices.length > 0  ){
                    unhealthyCores = action.payload.response.message.coreDevices.map( cd => ({ 
                        coreGroup: action.payload.coreGroup,
                        coreDevice: cd.coreDeviceThingName, 
                        lastStatusUpdate: cd.lastStatusUpdateTimestamp, 
                        status: cd.status  
                    }))
            } else {
                unhealthyCores = []
            }
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: false,
                alertSeverity: constants.SUCCESS,
                alertMessage: null,
                unhealthyCores: unhealthyCores
            }
        case types.CORES_HEALTH_LIST_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.CORES_HEALTH_LIST_ERROR_EN : constants.CORES_HEALTH_LIST_ERROR_ES,
            }
        
        case types.LIST_CONFIG:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.LIST_CONFIG_EN : constants.LIST_CONFIG_ES,
                alertShow: false,
                alertSeverity: null,
                alertMessage: null
            }
        case types.LIST_CONFIG_SUCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: null,
                alertShow: true,
                alertSeverity: constants.SUCCESS,
                alertMessage: state.language === constants.LAN_EN ? constants.LIST_CONFIG_SUCESS_EN : constants.LIST_CONFIG_SUCESS_ES,
                lastInitialConfig: action.payload
            }
        case types.LIST_CONFIG_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: null,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.LIST_CONFIG_ERROR_EN : constants.LIST_CONFIG_ERROR_ES,
            }      
        case types.LIST_FULL_CONFIG:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.LIST_FULL_CONFIG_EN : constants.LIST_FULL_CONFIG_ES,
                alertShow: false,
                alertSeverity: null,
                alertMessage: null
            }
        case types.LIST_FULL_CONFIG_SUCESS:
            //
            return {
                ...state,
                loading: false,
                loadingMessage: null,
                alertShow: true,
                alertSeverity: constants.SUCCESS,
                alertMessage: state.language === constants.LAN_EN ? constants.LIST_FULL_CONFIG_SUCESS_EN : constants.LIST_FULL_CONFIG_SUCESS_ES,
                configs: action.payload && action.payload.length > 0 ? action.payload[0] : {}
            }
        case types.LIST_FULL_CONFIG_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: null,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.LIST_FULL_CONFIG_ERROR_EN : constants.LIST_FULL_CONFIG_ERROR_ES,
            }         
        case types.SET_CONFIG:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.SET_CONFIG_EN : constants.SET_CONFIG_ES,
                alertShow: false,
                alertSeverity: null,
                alertMessage: null
            }
        case types.SET_CONFIG_SUCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: null,
                alertShow: true,
                alertSeverity: constants.SUCCESS,
                alertMessage: state.language === constants.LAN_EN ? constants.SET_CONFIG_SUCESS_EN : constants.LIST_CONFIG_SUCESS_ES,
                lastConfigInvokeStatus: action.payload
            }
        case types.SET_CONFIG_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: null,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.SET_CONFIG_ERROR_EN : constants.SET_CONFIG_ERROR_ES,
            }
        case types.GET_DB_CONFIG:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.GET_DB_CONFIG_EN : constants.GET_DB_CONFIG_ES,
                alertShow: false,
                alertSeverity: null,
                alertMessage: null
            }
        case types.GET_DB_CONFIG_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: null,
                alertShow: true,
                alertSeverity: constants.SUCCESS,
                alertMessage: state.language === constants.LAN_EN ? constants.GET_DB_CONFIG_SUCESS_EN : constants.GET_DB_CONFIG_SUCESS_ES,
                lastDBConfingIvokeStatus: action.payload
            }
        case types.GET_DB_CONFIG_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: null,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.GET_DB_CONFIG_ERROR_EN : constants.GET_DB_CONFIG_ERROR_ES,
            }
        case types.CHECK_RESULT_CONFIG:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.CHECK_RESULT_CONFIG_EN : constants.CHECK_RESULT_CONFIG_SUCESS_ES,
                alertShow: false,
                alertSeverity: null,
                alertMessage: null
            }
        case types.CHECK_RESULT_CONFIG_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: null,
                alertShow: true,
                alertSeverity: constants.SUCCESS,
                alertMessage: state.language === constants.LAN_EN ? constants.CHECK_RESULT_CONFIG_SUCESS_EN : constants.CHECK_RESULT_CONFIG_SUCESS_ES,
                lastDbConfigData: action.payload
            }
        case types.CHECK_RESULT_CONFIG_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: null,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.CHECK_RESULT_CONFIG_ERROR_EN : constants.CHECK_RESULT_CONFIG_ERROR_EN,
            }    
        case types.DELETE_DB_CONFIG:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.DELETE_DB_CONFIG_EN : constants.DELETE_DB_CONFIG_ES,
                alertShow: false,
                alertSeverity: null,
                alertMessage: null
            }
        case types.DELETE_DB_CONFIG_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: null,
                alertShow: true,
                alertSeverity: constants.SUCCESS,
                alertMessage: state.language === constants.LAN_EN ? constants.DELETE_DB_CONFIG_SUCESS_EN : constants.DELETE_DB_CONFIG_SUCESS_ES,
                lastDBConfingIvokeStatus: action.payload
            }
        case types.DELETE_DB_CONFIG_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: null,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.DELETE_DB_CONFIG_ERROR_EN : constants.DELETE_DB_CONFIG_ERROR_ES,
            }        
        default:
            return state
    }
}
