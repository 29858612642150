import React, {useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Helmet from 'react-helmet';

import styled from "styled-components";
import {
  Grid as MuiGrid,
  Link as HyperLink,
  Avatar,
  FormControl,
  Input,
  InputLabel,
  Button as MuiButton,
  Paper,
  Typography
} from "@material-ui/core";
import { spacing } from "@material-ui/system";


import ModalProgress from '../../components/dialog/ModalProgress' 
import ByBAlert from '../../components/alerts/ByBAlert'
import { loginUserAction  } from '../../redux/actions/authActions'
import { createPermissionsAction, createMagnitudesAction } from '../../redux/actions/customersAction'
import { listComponentLatestVersionAction, listCoresHealthAction, listFullConfigAction } from '../../redux/actions/gatewayConfigActions'
import { startClockAction } from '../../redux/actions/clockActions'

import * as constants from '../../constants/index'




const Wrapper = styled(Paper)`
  background: ${props => props.theme.body.background};
  padding: ${props => props.theme.spacing(6)}px;
  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
  width: 420px;
`;

const BigAvatar = styled(Avatar)`
  width: 122px;
  height: 122px;
  text-align: center;
  margin: 0 auto ${props => props.theme.spacing(5)}px;
`;

const Button = styled(MuiButton)(spacing);
const Spacer = styled.div(spacing);
const Grid = styled(MuiGrid)(spacing);

function SignIn() {

  const history = useHistory()
  
  const [ account, setAccount ] = React.useState({ name: '', password: '' ,})
  const [ error, setError ] = React.useState({ name: false, password: false ,})
  const [ alert, setAlert ] = React.useState(false)
  const [ start, setStart ] = React.useState(false)
  const [ filling, setFilling ] = React.useState(false)
  
  const dispacth = useDispatch()
  const loginUser = (user) => dispacth( loginUserAction(user) )
  const createPermissionsTrigger = ( customerId, customerRoot ) => dispacth( createPermissionsAction( customerId, customerRoot ) )
  const createMagnitudesTrigger = () => dispacth( createMagnitudesAction() )
  const listComponentLatestVersionTrigger = ( name, arn, customerId ) => dispacth( listComponentLatestVersionAction( name, arn, customerId ) ) 
  const listCoresHealthTrigger = ( coreGroup, status, customerId ) => dispacth( listCoresHealthAction( coreGroup, status, customerId ) ) 
  const listFullConfigTrigger = ( customerId ) => dispacth( listFullConfigAction( customerId ) )
  const startClockTrigger = (interval) => dispacth( startClockAction( interval ) )
  
  const loginStatus = useSelector( state => state.authReducer.status )
  const loading = useSelector( state => state.authReducer.loading )
  const loginMessage = useSelector( state => state.authReducer.message )
  const actionType = useSelector( state => state.authReducer.actionType )
  const groups = useSelector( state => state.authReducer.groups )
  const customersCount = useSelector( state => state.authReducer.customersCount )
  const customerId = useSelector( state => state.authReducer.customerId )
  const company = useSelector( state => state.authReducer.company )
  const newFlag = useSelector( state => state.authReducer.newFlag )
  const missingMagnitudes = useSelector( state => state.authReducer.missingMagnitudes )
  const root = useSelector( state => state.authReducer.root )
 
  useEffect(() => {
    if( actionType && (  actionType === 'LOGIN_USER_SUCCESS' || actionType === 'LOGIN_USER_ERROR' ) ){
      if( actionType === 'LOGIN_USER_SUCCESS' ){
        
        if( !groups.includes('sa') ){
          listComponentLatestVersionTrigger(  constants.NUCLEUS_COMPONENT_NAME, constants.NUCLEUS_COMPONENT_ARN, customerId )
          listComponentLatestVersionTrigger(  constants.CLI_COMPONENT_NAME, constants.CLI_COMPONENT_ARN, customerId )
          listComponentLatestVersionTrigger(  constants.SECRET_COMPONENT_NAME, constants.SECRET_COMPONENT_ARN, customerId )
          listComponentLatestVersionTrigger(  constants.STREAM_COMPONENT_NAME, constants.STREAM_COMPONENT_ARN, customerId )
          listComponentLatestVersionTrigger(  constants.OWNER_STREAM_COMPONENT_NAME, constants.OWNER_STREAM_COMPONENT_ARN, customerId )
          listComponentLatestVersionTrigger(  constants.OWNER_DEVICES_COMPONENT_NAME, constants.OWNER_DEVICES_COMPONENT_ARN, customerId )
          listComponentLatestVersionTrigger(  constants.OWNER_CONFIG_COMPONENT_NAME, constants.OWNER_CONFIG_COMPONENT_ARN, customerId )
          listFullConfigTrigger( customerId )
          if( company ){
            listCoresHealthTrigger( `${company}CoreGroup`, constants.STATUS_UNHEALTHY, customerId )
          }        
          if( newFlag && root === 'pending'){
            createPermissionsTrigger( customerId, root )
          }
        }
        startClockTrigger(1)        
        if( missingMagnitudes && groups.includes('sa') ){
          createMagnitudesTrigger()
        }
        
      }      
      if(  actionType === 'LOGIN_USER_SUCCESS' && groups.includes('sa') && customersCount > 1){
        history.push('/customer-select')
      } else {
        if( actionType === 'LOGIN_USER_SUCCESS' ){
          history.push('/home')
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ actionType, company ]);
  
  const handleChange = (event) => {
    setFilling(true);
    setAccount({
      ...account,
      [ event.target.name]: event.target.value,
    });
    setError({
      ...error,
      [ event.target.name ]: event.target.value.trim() ? true : false,
    });
  };
  
  const { name, password } = account

  const submitAccount = async e => {
    e.preventDefault();
    setStart(true);
    setFilling(false);
    if( name.trim() === '' || password.trim() === '' ){
      setAlert(true);
      return;
    }else{
      setAlert(false);  
    }
    loginUser({ name , password});
  }


  return (
    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
      <Wrapper variant="outlined" >
        <Helmet title="Sign In Iowatt" />
        
        <ByBAlert show={ loginStatus && !filling && !alert } type="title" severity={ loginStatus } message={ loginMessage }/> 
        <ByBAlert show={!filling && alert} type="title" severity="error" message="Required fields are missing!"/>   
        <Spacer mb={4} />     
        <ModalProgress open = { loading } modalTitle = 'Login account...'/>


        <BigAvatar alt="Iowatt" src="/static/img/avatars/iowatt.jpg" />
        <Typography component="h2" variant="h2" align="center" gutterBottom>IOWATT</Typography>
        <Spacer mb={4} />

        <Typography component="h2" variant="body1" align="center">Sign in to your account to continue</Typography>

        <form>
          
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="username">Username</InputLabel>
            <Input id="username" name="username" autoComplete="off" autoFocus inputProps={{name:'name'}} error={( !error.name && start )} onChange={handleChange} />
          </FormControl>
          
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input name="password" type="password" id="password" inputProps={{name:'password'}} error={( !error.password && start )} onChange={handleChange} />
          </FormControl>
          <Spacer mb={8} />

          <Button fullWidth variant="contained" color="primary" mb={2} onClick={submitAccount}>Sign in</Button>
          <Spacer mb={4} />

          <Grid container direction="row" alignItems="center" mb={2}>
            <Grid item>
              <Typography component="h2" variant="body1">No account? {" "}<HyperLink href="/auth/sign-up">Create account</HyperLink></Typography>
            </Grid>
          </Grid>

          <Grid container direction="row" alignItems="center" mb={2}>
              <Grid item>
                <Typography component="h2" variant="body1">Forgot your password? {" "}<HyperLink href="/auth/forgot-password">Reset Password</HyperLink></Typography>
              </Grid>
          </Grid>

        </form>
      </Wrapper>
    </div>
  );
}

export default SignIn;