import * as types from '../types/programs'
import { API, graphqlOperation } from 'aws-amplify';
import { createProgram, updateProgram, deleteProgram, createProgramIowatts, deleteProgramIowatts } from '../../graphql/mutations'
import { getProgramsByCustomer, getIoWatt, variableByIoWatt, getProgramRelations } from '../../graphql-custom/queries'


/*******************************************************************************************/
/************************************* get iowatt info *************************************/
/*******************************************************************************************/

export function getIoWattAction( iowattId ) {
    return async (dispath) => {
        dispath( getIoWattStart() )
        try{
            const result = await API.graphql(graphqlOperation( getIoWatt, { id : iowattId } ))
            dispath( getIoWattSuccess( result.data.getIoWatt ))
        } catch (error) {
            console.log(error)
            dispath( getIoWattError() )
        }
    }
}

const getIoWattStart = () => ({
    type: types.IOWATT_GET_INFO,
    payload: true
})

const getIoWattSuccess = iowatt => ({
    type: types.IOWATT_GET_INFO_SUCCESS,
    payload: iowatt
})

const getIoWattError = () => ({
    type: types.IOWATT_GET_INFO_ERROR,
    payload: false
})

export function releaseIowattInfoAction() {
    return async (dispath) => {
        dispath( releaseIowattInfo() )
    }
}

const releaseIowattInfo = () => ({
    type: types.IOWATT_RELEASE_INFO,
    payload: false
})

/*******************************************************************************************/
/*************************************** program list **************************************/
/*******************************************************************************************/

export function listProgramsAction( customerId ) {
    return async (dispath) => {
        dispath( listProgramsStart() )
        try{
            const result = await API.graphql(graphqlOperation( getProgramsByCustomer, { id : customerId } ))
            dispath( listProgramsSuccess( result.data.getCustomer.programs.items ))
        } catch (error) {
            console.log(error)
            dispath( listProgramsError() )
        }
    }
}

const listProgramsStart = () => ({
    type: types.PROGRAMS_LIST,
    payload: true
})

const listProgramsSuccess = programs => ({
    type: types.PROGRAMS_LIST_SUCCESS,
    payload: programs
})

const listProgramsError = () => ({
    type: types.PROGRAMS_LIST_ERROR,
    payload: false
})

export function listProgramsShadowAction( customerId ) {
    return async (dispath) => {
        try{
            const result = await API.graphql(graphqlOperation( getProgramsByCustomer, { id : customerId } ))
            dispath( listProgramsShadowSuccess( result.data.getCustomer.programs.items ))
        } catch (error) {
            console.log(error)
        }
    }
}

const listProgramsShadowSuccess = programs => ({
    type: types.PROGRAMS_SHADOW_LIST_SUCCESS,
    payload: programs
})

/*******************************************************************************************/
/************************************** create program *************************************/
/*******************************************************************************************/

export function createProgramAction( programStep, customerId ){
    return async (dispath) => {
        dispath( createProgramStart() )
        const program = {}
        program['customerProgramsId'] = customerId
        program['id'] = programStep.programPage.id
        program['name'] = programStep.programPage.name
        program['description'] = programStep.programPage.description
        program['goal'] = programStep.programPage.goal
        program['goalUnits'] = programStep.programPage.goalUnits
        program['fees'] = programStep.programPage.fees
        program['currency'] = programStep.programPage.currency        
        program['startTime'] = programStep.programPage.startTime
        program['endTime'] = programStep.programPage.endTime
        program['products'] = JSON.stringify(programStep.programPage.products) 
        program['customers'] = JSON.stringify(programStep.programPage.customers) 
        program['variables'] = JSON.stringify(programStep.measurePage.variables) 
        program['gateways'] = JSON.stringify(programStep.measurePage.gateways) 
        program['devices'] = JSON.stringify(programStep.measurePage.devices)      
        program['progCalendar'] = {
            name: programStep.programPage.progCalendar.name,
            schedule: programStep.programPage.progCalendar.schedule,
            type: programStep.programPage.progCalendar.type
        } 
        program['projections'] = JSON.stringify(programStep.projectionsPage) 
        try{
            const result = await API.graphql(graphqlOperation( createProgram, { input: program } ))
            dispath( createProgramSuccess( result.data.createProgram ))
            programStep.measurePage.iowatts.forEach( iowatt => {
                API.graphql(graphqlOperation( createProgramIowatts, { input: {
                    programID: result.data.createProgram.id,
                    ioWattID: iowatt.id
                }}))
            });            
        } catch (error) {
            console.log(error)
            dispath( createProgramError() )
        }
    }
}

const createProgramStart = () => ({
    type: types.PROGRAMS_CREATE,
    payload: true
})

const createProgramSuccess = createdClient => ({
    type: types.PROGRAMS_CREATE_SUCCESS,
    payload: createdClient
})

const createProgramError = () => ({
    type: types.PROGRAMS_CREATE_ERROR,
    payload: false
})

/*******************************************************************************************/
/************************************** create program *************************************/
/*******************************************************************************************/

export function updateProgramAction( programStep, customerId ){
    return async (dispath) => {
        dispath( updateProgramStart() )
        try{
            const program = {}
            program['customerProgramsId'] = customerId
            program['id'] = programStep.programPage.id
            program['name'] = programStep.programPage.name
            program['description'] = programStep.programPage.description
            program['goal'] = programStep.programPage.goal
            program['goalUnits'] = programStep.programPage.goalUnits
            program['fees'] = programStep.programPage.fees
            program['currency'] = programStep.programPage.currency        
            program['startTime'] = programStep.programPage.startTime
            program['endTime'] = programStep.programPage.endTime
            program['products'] = JSON.stringify(programStep.programPage.products) 
            program['customers'] = JSON.stringify(programStep.programPage.customers) 
            program['variables'] = JSON.stringify(programStep.measurePage.variables) 
            program['gateways'] = JSON.stringify(programStep.measurePage.gateways) 
            program['devices'] = JSON.stringify(programStep.measurePage.devices)
            program['progCalendar'] = {
                name: programStep.programPage.progCalendar.name,
                schedule: programStep.programPage.progCalendar.schedule,
                type: programStep.programPage.progCalendar.type
            } 
            program['projections'] = JSON.stringify(programStep.projectionsPage) 
            const result = await API.graphql(graphqlOperation( updateProgram, { input: program}))
            dispath( updateProgramSuccess( result.data.updateProgram ))
            
            
            const relations = await API.graphql( graphqlOperation( getProgramRelations, { id : programStep.programPage.id } ))
            const iowatts = relations.data.getProgram.iowatts.items
            const programStepIowattsWithGateways = programStep.measurePage.iowatts.filter( psi => {
                const match = programStep.measurePage.gateways.filter( pg => pg.ioWattGatewaysId === psi.id )
                return ( match && match.length > 0 )
            })
            const toAdd = programStepIowattsWithGateways.filter( i => {
                const match = iowatts.filter( io => io.ioWattID === i.id )
                return !( match && match.length > 0 )
            })
            const toDelete = iowatts.filter( io => {
                const match = programStepIowattsWithGateways.filter( i => i.id === io.ioWattID )
                return !( match && match.length > 0 )
            })
            toAdd.forEach( iowatt => {
                API.graphql(graphqlOperation( createProgramIowatts, { input: {
                    programID: programStep.programPage.id,
                    ioWattID: iowatt.id
                }}))
            })
            toDelete.forEach( prograIowatt => {
                API.graphql(graphqlOperation( deleteProgramIowatts, { input: { id : prograIowatt.id } } ))
            })
        } catch (error) {
            console.log(error)
            dispath( updateProgramError() )
        }
    }
}

const updateProgramStart = () => ({
    type: types.PROGRAMS_UPDATE,
    payload: true
})

const updateProgramSuccess = updatedProgram => ({
    type: types.PROGRAMS_UPDATE_SUCCESS,
    payload: updatedProgram
})

const updateProgramError = () => ({
    type: types.PROGRAMS_UPDATE_ERROR,
    payload: false
})

/*******************************************************************************************/
/************************************** update program *************************************/
/*******************************************************************************************/

export function deleteProgramAction( programId ){
    return async (dispath) => {
        dispath( deleteProgramStart() )
        try{
            const relations = await API.graphql( graphqlOperation( getProgramRelations, { id : programId } ))
            const iowatts = relations.data.getProgram.iowatts.items
            iowatts.forEach( programIowatt => {
                API.graphql(graphqlOperation( deleteProgramIowatts, { input: { id : programIowatt.id } } ))
            })
            await API.graphql(graphqlOperation( deleteProgram, { input: { id : programId} } ))
            dispath( deleteProgramSuccess( programId ))
        } catch (error) {
            console.log(error);
            dispath( deleteProgramError() )
        }
    }
}

const deleteProgramStart = () => ({
    type: types.PROGRAMS_DELETE,
    payload: true
})

const deleteProgramSuccess = programId => ({
    type: types.PROGRAMS_DELETE_SUCCESS,
    payload: programId
})

const deleteProgramError = () => ({
    type: types.PROGRAMS_DELETE_ERROR,
    payload: false
})

/*******************************************************************************************/
/************************************** Get variables **************************************/
/*******************************************************************************************/


export function getVariblesByIowattAction( iowattId, variable ){
    return async (dispath) => {
        dispath( getVariblesByIowattStart() )
        const criteria = {
            iowattKey: iowattId,
            statusIdEx: {
                beginsWith:{
                    status: "SUCCESS",
                    idEx: variable
                }
            },
            sortDirection: 'ASC',
            limit: 7
        }
        try {
            const result = await API.graphql( graphqlOperation( variableByIoWatt, criteria ))
            dispath( getVariblesByIowattSuccess( result.data.variableByIoWatt.items ) )
        } catch (error) {
            console.log(error)
            dispath( getVariblesByIowattError() )
        }
    }
}

const getVariblesByIowattStart = () =>  ({
    type: types.GET_VARIABLES_BY_IOWATT,
    payload: true
})

const getVariblesByIowattSuccess = ( variables ) =>  ({
    type: types.GET_VARIABLES_BY_IOWATT_SUCCESS,
    payload: variables
})

const getVariblesByIowattError = () =>  ({
    type: types.GET_VARIABLES_BY_IOWATT_ERROR,
    payload: true
})

/*******************************************************************************************/
/***************************************** language ****************************************/
/*******************************************************************************************/

export function setLanguageAction( lan ){
    return async (dispath) => {
        dispath( setLanguage( lan ) )
    }
}

const setLanguage = (lan) => ({
    type: types.SET_LANGUAGE,
    payload: lan
})

/* **************************************************************** */
/* ************************* clear states ************************* */
/* **************************************************************** */

export function programClearStatesAction(){
    return async (dispath) => {
        dispath( setClearStates() )
    }
}

const setClearStates = () => ({
    type: types.SET_CLEAR_STATES,
    payload: true
})
