export const SET_LANGUAGE = 'SET_LANGUAGE'
export const IOT_CONNECT = 'IOT_CONNECT'
export const IOT_CONNECT_SUCCESS = 'IOT_CONNECT_SUCCESS'
export const IOT_CONNECT_ERROR = 'IOT_CONNECT_ERROR'
export const IOT_MESSAGE_DISCONNECT = 'IOT_MESSAGE_DISCONNECT'
export const IOT_MESSAGE_ARRIVED_SUCCESS = 'IOT_MESSAGE_ARRIVED_SUCCESS'
export const IOT_MESSAGE_ARRIVED_ERROR = 'IOT_MESSAGE_ARRIVED_ERROR'
export const IOT_MESSAGE_DISCONNECT_ARRIVED = 'IOT_MESSAGE_DISCONNECT_ARRIVED'
export const IOT_DISCONNECT = 'IOT_DISCONNECT'
export const IOT_DISCONNECT_SUCCESS = 'IOT_DISCONNECT_SUCCESS'
export const IOT_DISCONNECT_ERROR = 'IOT_DISCONNECT_ERROR'