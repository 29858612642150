import * as constants from '../../constants/index'
import * as types from '../types/iot'

const initialState = {
    language: 'us',
    executing: false,
    alertShow: false,
    alertMessage: null,
    systemError: false,
    subs: [],
    installations:[],
    callback:{},
}

export default function(state = initialState, action){
    switch (action.type) {
        case types.SET_LANGUAGE:
            return {
                ...state,
                language: action.payload
            }
        case types.IOT_CONNECT:
            return {
                ...state,
                executing: true,
                alertShow: false,
                alertMessage: null,
                systemError: false,
            }      
        case types.IOT_CONNECT_SUCCESS:
            const prevSubs = state.subs.filter( sub => sub.executionId !== action.payload.executionId )
            const updatedSubs = [ ...prevSubs, action.payload ]
            return {
                ...state,
                executing: true,
                alertShow: true,
                alertMessage: state.language === constants.LAN_ES ? constants.PUBSUB_INSTALL_SUCCESS_STARTED_ES : constants.PUBSUB_INSTALL_SUCCESS_STARTED_EN,
                systemError: false,
                subs: updatedSubs,
            }
        case types.IOT_CONNECT_ERROR:
            return {
                ...state,
                executing: false,
                alertShow: true,
                alertMessage: state.language === constants.LAN_ES ? constants.PUBSUB_INSTALL_START_FAILED_ES : constants.PUBSUB_INSTALL_START_FAILED_EN,
                systemError: true
            }
        case types.IOT_MESSAGE_ARRIVED_SUCCESS:
            const callbacks = [ ...state.installations, action.payload ]
            const subs = state.subs
            //befor map
            subs.forEach( sub => {
                const numCb = callbacks.filter( cb => cb.executionId === sub.executionId )
                sub.count = numCb && numCb.length > 0 ? numCb.length : 0
            })

            return {
                ...state,
                executing: true,
                alertShow: false,
                alertMessage: null,
                systemError: false,
                installations: callbacks,
                subs: subs,
                callback: action.payload,
            }
        case types.IOT_MESSAGE_ARRIVED_ERROR:
            return {
                ...state,
                executing: false,
                alertShow: true,
                alertMessage: state.language === constants.LAN_ES ? constants.PUBSUB_INSTALL_MSG_FAILED_ES : constants.PUBSUB_INSTALL_MSG_FAILED_EN,
                systemError: true
            }
        case types.IOT_MESSAGE_DISCONNECT_ARRIVED:
            return {
                ...state,
                executing: false,
                alertShow: false,
                alertMessage: null,
                systemError: false
            }            
        case types.IOT_DISCONNECT:
            return {
                ...state,
                executing: true,
                alertShow: false,
                alertMessage: null,
                systemError: false
            }
        case types.IOT_DISCONNECT_SUCCESS:
            const notDeletedInstallations = state.installations.filter( ins => ins.executionId !== action.payload )
            const notDeletedSubs = state.subs.filter( sub => sub.executionId !== action.payload )
            return {
                ...state,
                executing: notDeletedSubs && notDeletedSubs.length < 1 ? false : true,
                alertShow: true,
                alertMessage: state.language === constants.LAN_ES ? constants.PUBSUB_INSTALL_END_SUCCESS_ES : constants.PUBSUB_INSTALL_END_SUCCESS_EN,
                systemError: false,
                installations: notDeletedInstallations,
                subs: notDeletedSubs
            }  
        case types.IOT_DISCONNECT_ERROR:
            return {
                ...state,
                executing: false,
                alertShow: true,
                alertMessage: state.language === constants.LAN_ES ? constants.PUBSUB_INSTALL_END_DISCONN_FAILED_ES : constants.PUBSUB_INSTALL_END_DISCONN_FAILED_EN,
                systemError: true
            }         
        default:
            return state;
    }
}