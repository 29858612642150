import * as constants from '../../constants/index'
import * as types from '../types/iowatts'

const initialState = {
    language: 'us',
    loading: false,
    loadingMessage: null,
    alertShow: false,
    alertSeverity: null,
    alertMessage: null,
    powerPlants: [],
    iowatts: []    
}

export default function(state = initialState, action){
    switch (action.type) {
        case types.SET_LANGUAGE:
            return {
                ...state,
                alertShow: false,
                language: action.payload
            }
        case types.SET_CLEAR_STATES:
            return {
                language: 'us',
                loading: false,
                loadingMessage: null,
                alertShow: false,
                alertSeverity: null,
                alertMessage: null,
                powerPlants: [],
                iowatts: [] 
            } 
        case types.IOWATTS_LIST:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.IOWATTS_GETTING_EN : constants.IOWATTS_GETTING_ES,
                alertShow: false,
            }
        case types.IOWATTS_LIST_SUCCESS:
            let iowatts = []
            //before map
            action.payload.map( a => ( a.iowatts.items.forEach( i => { iowatts = [...iowatts, i ] })))
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: false,
                alertSeverity: constants.SUCCESS,
                alertMessage: null,
                powerPlants: action.payload.map( p => ({ 
                    id: p.id,
                    updatedAt: p.updatedAt,
                    name: p.name,
                    googlePlaceId: p.googlePlaceId,
                    country: p.country,
                    formattedAddress: p.formattedAddress,
                    addressComponents: p.addressComponents,
                    lat: p.lat,
                    lng: p.lng,
                    description: p.description 
                })),
                iowatts: iowatts.map( i => ({
                    id: i.id,
                    name: i.name,
                    powerPlant: i.powerPlant,
                    hardware: i.hardware,
                    core: i.core,
                    coreGroup: i.coreGroup,
                    ip: i.ip,
                    proxy: i.proxy,
                    status: i.status,
                    installStatus: i.installStatus,
                    nucleusVersion: i.nucleusVersion,
                    secretVersion: i.secretVersion,
                    streamVersion: i.streamVersion,
                    cliVersion: i.cliVersion,
                    ownerStreamsVersion: i.ownerStreamsVersion,
                    ownerDevicesVersion: i.ownerDevicesVersion,
                    ownerConfigsVersion: i.ownerConfigsVersion,
                    createdAt: i.createdAt,
                    updatedAt: i.updatedAt,
                    installCommands: i.installCommands.items.map( i => ({ 
                        id: i.id,
                        step: i.step,
                        status: i.status,
                        createdAt: i.createdAt,
                        stdout: JSON.parse(i.stdout), 
                        stderr: JSON.parse(i.stderr), 
                        error: JSON.parse(i.error), 
                    })),
                    programs: i.programs && i.programs.items ? i.programs.items.map( p => p.program ) : []
                }))
            }
        case types.IOWATTS_LIST_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.IOWATTS_GETTING_ERROR_EN : constants.IOWATTS_GETTING_ERROR_ES
            }
        case types.IOWATTS_CREATE:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.IOWATTS_CREATING_EN : constants.IOWATTS_CREATING_ES,
                alertShow: false,
                alertMessage: null,
            }
        case types.IOWATTS_CREATE_SUCCESS:            
            const prevIowatts = state.iowatts.filter( iowatt => iowatt.id !== action.payload.id )
            const updatedIowattsList = [ ...prevIowatts, action.payload ]
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,            
                alertMessage: state.language === constants.LAN_EN ? constants.IOWATTS_CREATING_SUCCESS_EN : constants.IOWATTS_CREATING_SUCCESS_ES,
                iowatts: updatedIowattsList.map( i => ({
                    id: i.id,
                    name: i.name,
                    powerPlant: i.powerPlant,
                    hardware: i.hardware,
                    core: i.core,
                    ip: i.ip,
                    proxy: i.proxy,
                    cliVersion: i.cliVersion,
                    nucleusVersion: i.nucleusVersion,
                    status: i.status,
                    installStatus: i.installStatus,
                    createdAt: i.createdAt,
                    updatedAt: i.updatedAt,
                    installCommands: []
                }))
            }
        case types.IOWATTS_CREATE_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.IOWATTS_CREATING_ERROR_EN : constants.IOWATTS_CREATING_ERROR_ES
            }
        
        case types.IOWATTS_DELETE:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.CLIENTS_DELETING_EN : constants.CLIENTS_DELETING_ES,
                alertShow: false,
                alertMessage: null,
            }        
        case types.IOWATTS_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,            
                alertMessage: state.language === constants.LAN_EN ? constants.IOWATTS_DELETING_SUCCESS_EN : constants.IOWATTS_DELETING_SUCCESS_ES,                                
                iowatts: state.iowatts.filter( iowatt => !action.payload.includes(iowatt.id) )
            }
        case types.IOWATTS_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.IOWATTS_DELETING_ERROR_EN : constants.IOWATTS_DELETING_ERROR_ES
            }
        case types.IOWATTS_DELETE_THERE_ARE_PROGRAMS_ERROR:
            const messageEn =  constants.IOWATTS_DELETE_THERE_ARE_PROGRAMS_ERROR_EN.replaceAll('@count', action.payload.length )
            const messageEs =  constants.IOWATTS_DELETE_THERE_ARE_PROGRAMS_ERROR_ES.replaceAll('@count', action.payload.length )
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? messageEn : messageEs
            }                    
        default:
            return state;
    }
}