import * as types from '../types/customers';

import { API, graphqlOperation } from 'aws-amplify';
import { listCustomers } from '../../graphql-custom/queries'
import { createPage, createMagnitude } from '../../graphql-custom/mutations'

import { getToCreatePermissions, getToCreateMagnitudes } from '../../helpers'
import { v4 as uuidv4 } from 'uuid'



/* **************************************************************** */
/* ********************** list customer ********************* */
/* **************************************************************** */

export function listCustomerAction() {
    return async (dispath) => {
        dispath( listCustomer() )
        try{
            const result = await API.graphql(graphqlOperation( listCustomers ))
            dispath( listCustomerSuccess( result.data.listCustomers.items ))
        } catch (error) {
            console.log(error);
            dispath( listCustomerError() )
        }
    }    
}

const listCustomer = () => ({
    type: types.LIST_CUSTOMERS,
    payload: true
});

const listCustomerSuccess = customer => ({
    type: types.LIST_CUSTOMERS_SUCCESS,
    payload: customer
});

const listCustomerError = status => ({
    type: types.LIST_CUSTOMERS_ERROR,
    payload: status
});

/* **************************************************************** */
/* **************** Create new customer permisssions ************** */
/* **************************************************************** */

export function createPermissionsAction( customerId ) {
    return async (dispath) => {
        dispath( createPermissionsStart() )
        try{
            const pages = getToCreatePermissions( customerId )
            await Promise.all( pages.map( async (page) => {
                page['customerPagesId'] = customerId
                page.id = uuidv4()
                await API.graphql(graphqlOperation( createPage, { input: page } ));
            }))
            dispath( createPermissionsSuccess())
        } catch (error) {
            console.log(error)
            dispath( createPermissionsError() )
        }
    }    
}

const createPermissionsStart = () => ({
    type: types.CREATE_CUSTOMER_PERMISSIONS,
    payload: true
});

const createPermissionsSuccess = customer => ({
    type: types.CREATE_CUSTOMER_PERMISSIONS_SUCCESS,
    payload: customer
});

const createPermissionsError = status => ({
    type: types.CREATE_CUSTOMER_PERMISSIONS_ERROR,
    payload: status
});


/* **************************************************************** */
/* **************** Create new customer maginutudes *************** */
/* **************************************************************** */

export function createMagnitudesAction() {
    return async (dispath) => {
        dispath( createMagnitudeStart() )
        try{
            const magnitudes = getToCreateMagnitudes()
            await Promise.all( magnitudes.map( async (magnitude) => {
                await API.graphql(graphqlOperation( createMagnitude, { input: magnitude } ));
            }))
            dispath( createMagnitudeSuccess())
        } catch (error) {
            console.log(error)
            dispath( createMagnitudeError() )
        }
    }    
}

const createMagnitudeStart = () => ({
    type: types.CREATE_CUSTOMER_MAGNITUDES,
    payload: true
});

const createMagnitudeSuccess = customer => ({
    type: types.CREATE_CUSTOMER_MAGNITUDES_SUCCESS,
    payload: customer
});

const createMagnitudeError = status => ({
    type: types.CREATE_CUSTOMER_MAGNITUDES_ERROR,
    payload: status
});

/* **************************************************************** */
/* *************************** LANGUAGE *************************** */
/* **************************************************************** */

export function setLanguageAction( lan ){
    return async (dispath) => {
        dispath( setLanguage( lan ) )
    }
}

const setLanguage = (lan) => ({
    type: types.SET_LANGUAGE,
    payload: lan
})