import React from "react";
import async from "../sections/Async";
import { 
  Settings as SettingsIcon,
  Memory as MemoryIcon, 
  Home as HomeIcon
} from "@material-ui/icons"
import styled from "styled-components"

// Documentation
const Presentation = async(() => import("../pages/docs/Presentation"));

//IoWatt
const ObjectCreator = async(() => import("../pages/iowatt/ObjectCreator"));
const ObjectProgrammer = async(() => import("../pages/iowatt/ObjectProgrammer"));

//Settings
const PowerPlant = async(() => import("../pages/settings/powerplants/PowerPlant"));
const GatewayTypes = async(() => import("../pages/settings/gatewaytypes/GatewayTypes"));

//Devices
const Gateways = async(() => import("../pages/devices/Gateways"));

const IotIcon = styled.img`
  font-size: 20px;
  width: 20px;
  height: 20px;
  opacity: 0.5;
`;

const presentationRoutes = {
  id: "Home",
  id_es: "Principal",
  path: "/home",
  header: "Docomentation",
  header_es : "Documentación",
  icon: <HomeIcon />,
  component: Presentation,
  children: null
};


const iowattRouters = {
  id: "IoWatt",
  id_es: "IoWatt",
  path: "/iowatt",
  header: "Administration",
  header_es: "Administración",
  icon: <IotIcon src="/static/img/icons/iot.png" alt="iot" />,
  containsHome: false,
  children: [
    {
      path: "/iowatt/create",
      name: "Create Object",
      name_es: "Creación de objeto",
      component: ObjectCreator
    },
    {
      path: "/iowatt/program",
      name: "Program Object",
      name_es: "Programar un objeto",
      component: ObjectProgrammer
    }
  ]
}

const settingsRouters = {
  id: "Settings",
  id_es: "Configuraciones",
  path: "/settings",
  icon: <SettingsIcon />,
  children: [
    {
      path: "/settings/powerplant",
      name: "Create PowerPlant",
      name_es: "Crear una planta",
      component: PowerPlant
    },
    {
      path: "/settings/gateways",
      name: "Gateways Types",
      name_es: "Tipos de Gateway",
      component: GatewayTypes
    }
  ]
}

const gatewaysRoutes = {
  id: "Gateways",
  id_es: "Gateways",
  path: "/gateway/list",
  icon: <MemoryIcon />,
  component: Gateways,
  children: null
};

export const dashboardLayoutRoutes = [
  presentationRoutes,
  iowattRouters,
  gatewaysRoutes,
  settingsRouters,  
];

// Routes visible in the sidebar
export const sidebarRoutes = [
  presentationRoutes,
  iowattRouters,
  gatewaysRoutes,
  settingsRouters,  
];