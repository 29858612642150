import { API, graphqlOperation  } from 'aws-amplify'
import { 
    configByCustomer, 
    getIoWattPrograms, 
    getProgramWithIowatts,
    variableByIoWatt 
}  from '../../graphql-custom/queries'

import { deleteGateways, deleteVariables, deleteDevices } from '../../graphql/mutations'


export const getProgramsByGateway = ( programId, gatewayId, iowattAllPrograms ) => {
    if( iowattAllPrograms  && iowattAllPrograms.length > 0){
        return iowattAllPrograms.filter( program => {
            const gateways = JSON.parse( program.gateways )
            const gatewayIds = gateways.map( g => g.id )
            return gatewayIds.includes( gatewayId ) && program.id !== programId
        })
    } else {
        return []
    }
} 

export const setGatewayData = ( customerId, customerName, gateway ) => {
    const g =  {}
    g.gatewayId           = gateway.id
    g.gatewayName         = gateway.name
    g.gatewayType         = gateway.type
    g.driverType          = gateway.driverType
    g.gatewayIp           = gateway.ip
    g.customerId          = customerId
    g.customerName        = customerName
    g.customerDataLake    = gateway.dataLake
    g.timeOffset          = gateway.timeOffset
    return g   
}

export const setGatewayProgram = ( user, iowattId, gateway, program, variables, products, programsCustomers, projections ) => {
    const g               = gateway
    const vars            = variables.filter( v => v.gatewaysVariablesId === g.gatewayId )    
    const schedule        = program.progCalendar.schedule     
    g.shortCountry        = vars[0]['shortCountry']
    if( !g.programs ){
        g.programs = []
    }

    g.programs.push({
        programId: program.id,
        programUser: user,
        programIowatt: iowattId,
        programName: program.name,
        programGoal: program.goal,
        programGoalUnits: program.goalUnits,
        programFees: program.fees,
        programCurrency: program.currency,
        programStartTime: program.startTime,
        programEndTime: program.endTime,
        products: products,
        productCustomers: programsCustomers,
        programCalendar: {
            name: program.progCalendar.name,
            type: program.progCalendar.type,
            schedule: typeof schedule === 'string' ? JSON.parse(schedule) : {},
            hollidays: program.progCalendar.hollidays,
            beginHour: program.progCalendar.beginHour,
            endHour: program.progCalendar.endHour,
            createdAt: program.progCalendar.createdAt
        },                        
        variables: vars.map( v => ({ 
            deviceId: v.idEx.split('.')[0],
            varId: v.varId, 
            idEx: v.idEx, 
            title: v.title,
            timeBase: v.interval,
            powerPlant: v.powerPlant,
            country: v.country,
            latitude: v.latitude,
            longitude: v.longitude,
            magnitude: v.magnitude,
            units: v.units,
            badge: v.badge,
            isGoalCounter: v.isGoalCounter,
            isManofacturedCounter: v.isManofacturedCounter
        })),
        projections: Object.keys(projections).filter( p => projections[p])
    })
    return g       
}

export const setGatewayDevice = (  gateway, devices ) => {
    const localGateway        = { ...gateway }
    const localDevices        = devices.filter( d => localGateway.gatewayId ? d.gatewaysDevicesId === localGateway.gatewayId : d.gatewaysDevicesId === localGateway.id  )
    const localDevicesIds     = localDevices.map( d => d.deviceId )
    const localGatewayDevices = gateway['devices'] && gateway['devices'].length > 0 ? gateway['devices'] : []
    const mergedDevices       = localGatewayDevices.concat(localDevicesIds)
    const mergedDevicesIds    = [ ...new Set( mergedDevices )]     
    localGateway['devices'] = mergedDevicesIds
    return localGateway
}

export const getMissingGateways = ( iowattId,  programGateways, otherProgrmas ) => {    
    let gateways = [] 
    const tmpProgramGateways = otherProgrmas.map( p => {
        return JSON.parse( p.gateways )
    })
    tmpProgramGateways.forEach( programGatewayArray => {
        programGatewayArray.forEach( gateway => {
            const coincidence = gateways.some( g => g.id === gateway.id )
            if( !coincidence ){
                gateways = gateways.concat( gateway )  
            }
        })
    })
    const gatewaysByIoWatt = gateways && gateways.length > 0 ? gateways.filter( g => g.ioWattGatewaysId === iowattId ) : []
    return gatewaysByIoWatt.filter( gbi => {
        const match = programGateways.filter( g => gbi.id === g.id)
        return !( match && match.length > 0 )
    })
}

export const setDeviceRules = ( programInfo, iowatt, user, customerId, customerName ) => {
    const { programPage: program, projectionsPage: projections  } = programInfo
    const { gateways: allGateways, devices, variables  } = programInfo.measurePage
    
    const products = program.products.map( p => ({ 
        name: p.name,
        line: p.line,
        price: p.price,
        fees: p.fees,
        quantities: p.quantities,
        volume: p.volume,
        units: p.units,
        startTime: p.startTime,
        endTime: p.endTime
    })) 
    
    const programsCustomers = program.customers.map( c => ({ name: c.name, type: c.type, country: c.country  }))
    const gateways = allGateways.filter( gateway => gateway.ioWattGatewaysId === iowatt.iowattId )
    const missingGateways = getMissingGateways( iowatt.iowattId,  gateways, iowatt.programs )
    gateways.forEach( g => {
        const gatewayData =  setGatewayData( customerId, customerName, g )        
        let gatewayProgram =  setGatewayProgram( user, iowatt.iowattId, gatewayData, program, variables, products, programsCustomers, projections ) 
        gatewayProgram = setGatewayDevice(  gatewayProgram, devices )
        const otherPrograms = iowatt.programs ? getProgramsByGateway( program.id , gatewayProgram.gatewayId, iowatt.programs ) : []        
        
        if( otherPrograms.length > 0 ) {
            otherPrograms.forEach( p => {
                const prog              = p
                const progProjections   = JSON.parse( p.projections )
                const progDevices       = JSON.parse( p.devices )
                const progVariables     = JSON.parse( p.variables )
                const progProducts      = JSON.parse( p.products )
                const progCustomers     = JSON.parse( p.customers )
                gatewayProgram = setGatewayProgram( user, iowatt.iowattId, gatewayProgram, prog, progVariables, progProducts, progCustomers, progProjections ) 
                gatewayProgram = setGatewayDevice(  gatewayProgram, progDevices )

            })
        }
        iowatt.gateways.push(gatewayProgram)  
    })

    missingGateways.forEach( g => {
        let gatewayProgram =  setGatewayData( customerId, customerName, g )
        const otherPrograms = iowatt.programs ? getProgramsByGateway( program.id , g.id, iowatt.programs ) : []
        if( otherPrograms.length > 0 ) {
            otherPrograms.forEach( p => {
                const prog              = p
                const progProjections   = JSON.parse( p.projections )
                const progDevices       = JSON.parse( p.devices )
                const progVariables     = JSON.parse( p.variables )
                const progProducts      = JSON.parse( p.products )
                const progCustomers     = JSON.parse( p.customers )
                gatewayProgram = setGatewayProgram( user, iowatt.iowattId, gatewayProgram, prog, progVariables, progProducts, progCustomers, progProjections ) 
                gatewayProgram = setGatewayDevice(  gatewayProgram, progDevices )
            })
        }
        iowatt.gateways.push(gatewayProgram)
    })
    delete iowatt['programs']
    return {...iowatt}
}

export const excludeProgramFrom = ( iowatt, program ) => {
    const iowattPrograms = [ ...iowatt.programs ]
    const filteredPrograms = iowattPrograms.filter( p => p.id !== program.programPage.id )
    const firstProgram = {...filteredPrograms[0]}
    filteredPrograms.shift()
    iowatt.programs = filteredPrograms
    return {
        firstProgram: firstProgram,
        iowatt: iowatt,
    }
}

export const buildProgramFromData = ( programData ) => {
    const projections = JSON.parse( programData.projections )
    return {
        programPage:{
            id: programData.id,
            name: programData.name,
            goal: programData.goal,
            goalUnits: programData.goalUnits,
            fees: programData.fees,
            currency: programData.currency,
            startTime: programData.startTime,
            endTime: programData.endTime,
            description: programData.description,
            progCalendar: programData.progCalendar,
            customers: JSON.parse( programData.customers ),
            products: JSON.parse( programData.products ),
        },
        measurePage: {
            gateways:  JSON.parse( programData.gateways ),
            devices:  JSON.parse( programData.devices ),
            variables:  JSON.parse( programData.variables ),
            iowatts: []
        },        
        projectionsPage: {
            iowattId: projections.iowattId,
            iowattName: projections.iowattName,
            powerPlant: projections.powerPlant,
            deviceId: projections.deviceId,
            gatewayId: projections.gatewayId,
            gatewayName: projections.gatewayName,
            gatewayType: projections.gatewayType,
            gayewayDriverType: projections.gayewayDriverType,
            gatewayIp: projections.gatewayIp,
            programId: projections.programId,
            programName: projections.programName,
            programDate: projections.programDate,
            programCalendar: projections.programCalendar,
            programGoal: projections.programGoal,
            programGoalUnits: projections.programGoalUnits,
            programExpectedGoal: projections.programExpectedGoal,
            programFees: projections.programFees,
            programCurrency: projections.programCurrency,
            programStartTime: projections.programStartTime,
            programEndTime: projections.programEndTime,            
            programManofacturedExpected: projections.programManofacturedExpected,
            compliance: projections.compliance,
            productivity: projections.productivity,
            programManufactured: projections.programManufactured,            
            productName: projections.productName,
            productFees: projections.productFees,
            productPrice: projections.productPrice,
            productQuantities: projections.productQuantities,
            productVolume: projections.productVolume,
            productVolumeUnits: projections.productVolumeUnits,
            productLine: projections.productLine,
            productCustomerName: projections.productCustomerName,
            productCustomerCountry: projections.productCustomerCountry,
            idEx: projections.idEx,
            title: projections.title,
            hasValue: projections.hasValue,
            hasLogger: projections.hasLogger,
            hasForced: projections.hasForced,
            sampleMode: projections.sampleMode,
            measureUnits: projections.measureUnits,
            unitsFactor: projections.unitsFactor,
            decimals: projections.decimals,
            varType: projections.varType,
            longitude: projections.longitude,
            latitude: projections.latitude
        }
    }
}

export const buildProgramWithIowatts = ( programData ) => {
    const projections = JSON.parse( programData.projections )
    const iowattsWithoutFormat = programData.iowatts.items.map( i  => i.ioWatt)
    return {
        programPage:{
            id: programData.id,
            name: programData.name,
            goal: programData.goal,
            goalUnits: programData.goalUnits,
            fees: programData.fees,
            currency: programData.currency,
            startTime: programData.startTime,
            endTime: programData.endTime,
            description: programData.description,
            progCalendar: programData.progCalendar,
            customers: JSON.parse( programData.customers ),
            products: JSON.parse( programData.products ),
        },
        measurePage: {
            gateways: JSON.parse( programData.gateways ),
            devices: JSON.parse( programData.devices ),
            variables: JSON.parse( programData.variables ),
            iowatts: iowattsWithoutFormat.items.map( i => ({ id: i.id, name: i.name, programs: i.programs.items.map( p  => p.program ) }))
        },
        projectionsPage: {
            iowattId: projections.iowattId,
            iowattName: projections.iowattName,
            powerPlant: projections.powerPlant,
            deviceId: projections.deviceId,
            gatewayId: projections.gatewayId,
            gatewayName: projections.gatewayName,
            gatewayType: projections.gatewayType,
            gayewayDriverType: projections.gayewayDriverType,
            gatewayIp: projections.gatewayIp,
            programId: projections.programId,
            programName: projections.programName,
            programDate: projections.programDate,
            programCalendar: projections.programCalendar,
            programGoal: projections.programGoal,
            programGoalUnits: projections.programGoalUnits,
            programExpectedGoal: projections.programExpectedGoal,
            programFees: projections.programFees,
            programCurrency: projections.programCurrency,
            programStartTime: projections.programStartTime,
            programEndTime: projections.programEndTime,            
            programManofacturedExpected: projections.programManofacturedExpected,
            compliance: projections.compliance,
            productivity: projections.productivity,
            programManufactured: projections.programManufactured,            
            productName: projections.productName,
            productFees: projections.productFees,
            productPrice: projections.productPrice,
            productQuantities: projections.productQuantities,
            productVolume: projections.productVolume,
            productVolumeUnits: projections.productVolumeUnits,
            productLine: projections.productLine,
            productCustomerName: projections.productCustomerName,
            productCustomerCountry: projections.productCustomerCountry,
            idEx: projections.idEx,
            title: projections.title,
            hasValue: projections.hasValue,
            hasLogger: projections.hasLogger,
            hasForced: projections.hasForced,
            sampleMode: projections.sampleMode,
            measureUnits: projections.measureUnits,
            unitsFactor: projections.unitsFactor,
            decimals: projections.decimals,
            varType: projections.varType,
            longitude: projections.longitude,
            latitude: projections.latitude
        }
    }
}

export const deleteDeviceRule  =  ( iowatt, program, user, customerId, customerName ) => {
    const excluded  = excludeProgramFrom( iowatt, program )
    const newProgram = buildProgramFromData( excluded.firstProgram )
    const newIowatt = excluded.iowatt
    return setDeviceRules( newProgram, newIowatt, user, customerId, customerName )
}

export const getConfigs = async ( customerId, iowatts ) =>  {
    try{
        const result = await API.graphql(graphqlOperation( configByCustomer, { id : customerId } ))
        return result.data.getCustomer.configs.items.filter( cfg => {
            return iowatts.some( io => io['id'] === cfg.iowattId && cfg['message'] === 'GetDbConfig')
        })
    } catch (error) {
        console.log(error)
        return []
    }  
}

export const getCurrentProgramsByIowatt  = async ( iowattId ) =>  {
    try {
        const result = await API.graphql( graphqlOperation( getIoWattPrograms, { id : iowattId } ))
        return result.data.getIoWatt && result.data.getIoWatt.programs ? result.data.getIoWatt.programs.items.map(  ip => ip['program']) : []
    } catch (error) {
        console.log(error)
        return
    }
}

export const getCurrentProgramsByGateway  = async ( gateway ) =>  {
    try {
        const programs =  await getCurrentProgramsByIowatt( gateway.iowatt.id )
        return programs.filter( p => {
            const programGateways = JSON.parse( p.gateways ) 
            return programGateways.some( pg => pg.id === gateway.id  )
        })
    } catch (error) {
        console.log(error)
        return
    }
}

export const deleteVariablesByGateway = async ( gateway ) => {
    let criteria = {
        iowattKey: gateway.iowatt.id
    }
    let nextToken = null
    let allVariableIds = []
    do{
        const result = await API.graphql( graphqlOperation( variableByIoWatt, criteria ))
        const variables = result.data.variableByIoWatt.items
        allVariableIds = allVariableIds.concat( variables.map( v => ({ id: v.id, devicesVariablesId: v.devicesVariablesId, gatewaysVariablesId: v.gatewaysVariablesId })) )
        nextToken =  result.data.variableByIoWatt.nextToken
        if( nextToken ) {
            criteria['nextToken'] = nextToken
        } 
    } while ( nextToken )
    const variableIds = allVariableIds.filter( av => av.gatewaysVariablesId === gateway.id )
    const devices = variableIds.length > 1 ? [...new Set( variableIds.map( x => x.devicesVariablesId ))] : variableIds[0].devicesVariablesId
    variableIds.forEach( variableId => {
        API.graphql(graphqlOperation( deleteVariables, { input: { id : variableId.id } } ))
    })
    devices.forEach( device => {
        API.graphql(graphqlOperation( deleteDevices, { input: { id : device } } ))
    })
}

export const getCurrentProgramsByIowatts  = async ( iowatts ) =>  {
    try {
        await Promise.all( iowatts.map( async ( iowatt ) => {
            const result = await API.graphql( graphqlOperation( getIoWattPrograms, { id : iowatt.id } ))
            iowatt['programs'] = result.data.getIoWatt && result.data.getIoWatt.programs ? result.data.getIoWatt.programs.items.map(  ip => ip['program']) : []
        }))
        return iowatts
    } catch (error) {
        console.log(error)
        return
    }
}

export const getCurrentIowatts = async ( id ) =>  {
    try {
        const result = await API.graphql( graphqlOperation( getProgramWithIowatts, { id : id  } ))
        return result.data.getProgram
    } catch (error) {
        console.log(error)
        return
    }
}

export const getVaribleByIoWatt = async (  iowattId, variable ) => {
    const criteria = {
        iowattKey: iowattId,
        statusIdEx: {
            beginsWith:{
                status: "SUCCESS",
                idEx: variable
            }
        },
        sortDirection: 'ASC',
        limit: 7
    }
    try {
        const result = await API.graphql( graphqlOperation( variableByIoWatt, criteria ))
        return result.data.variableByIoWatt.items
    } catch (error) {
        console.log(error)
        return []
    }
}

export const deleteVariablesByIoWatt = async ( iowattId ) => {
    let criteria = {
        iowattKey: iowattId
    }
    let nextToken = null
    let variableIds = []
    try {
        do{
            const result = await API.graphql( graphqlOperation( variableByIoWatt, criteria ))
            const variables = result.data.variableByIoWatt.items
            variableIds = variableIds.concat( variables.map( v => ({ id: v.id, devicesVariablesId: v.devicesVariablesId, gatewaysVariablesId: v.gatewaysVariablesId })) )
            nextToken =  result.data.variableByIoWatt.nextToken
            if( nextToken ) {
                criteria['nextToken'] = nextToken
            } 
        } while ( nextToken )
        const devices = variableIds.length > 1 ? [...new Set( variableIds.map( x => x.devicesVariablesId ))] : variableIds[0].devicesVariablesId
        const gateways = variableIds.length > 1 ? [...new Set( variableIds.map( x => x.gatewaysVariablesId ))] : variableIds[0].gatewaysVariablesId
        variableIds.forEach( variableId => {
            API.graphql(graphqlOperation( deleteVariables, { input: { id : variableId.id } } ))
        })
        devices.forEach( device => {
            API.graphql(graphqlOperation( deleteDevices, { input: { id : device } } ))
        })
        gateways.forEach( gateway => {
            API.graphql(graphqlOperation( deleteGateways, { input: { id : gateway } } ))
        })
    } catch (error) {
        console.log(error)
        return []
    }
}

export const getPowerPlantGateways = ( powerPlant, iowatts, gateways ) =>  {
    const selectedIowatts = iowatts.filter( i => i.powerPlant === powerPlant.id )
    const selectedIowattsIds = selectedIowatts.map( si => si.id )
    return gateways.filter(  gateway => selectedIowattsIds.includes(  gateway.iowatt.id ) )
}

export const getPowerPlantDevices = ( gateways ) => {
    let devices = []
    gateways.forEach( gateway => {
        const targetDevices = gateway.devices && gateway.devices.items.length > 0 ? gateway.devices.items.filter( g => g.master ===  'No') : []
        devices = devices.concat( targetDevices )
    })
    return devices
}
        