import * as types from '../types/auth'
import { Auth } from 'aws-amplify'

import { customerByName, listCustomers, listMagnitudes } from '../../graphql-custom/queries'
import { createCustomer, updateCustomer } from '../../graphql-custom/mutations'

import { API, graphqlOperation } from 'aws-amplify';
import * as slugify from 'slugify'
import { getGuestPermissions, getToCreatePermissions, sort } from '../../helpers';


/* **************************************************************** */
/* **************************** Logout user ************************ */
/* **************************************************************** */

export function logoutUserAction() {
    return async (dispath) => {
        dispath( logoutUser() );
        try{
            await Auth.signOut();
            dispath( logoutUserSuccess() );
        } catch (error) {
            console.log(error);
            dispath( logoutUserError() );
        }
    }    
}

const logoutUser = () => ({
    type: types.LOGOUT_USER,
    payload: true
});

const logoutUserSuccess = () => ({
    type: types.LOGOUT_USER_SUCCESS,
    payload: true
});

const logoutUserError = () => ({
    type: types.LOGOUT_USER_ERROR,
    payload: false
});




/* **************************************************************** */
/* **************************** Login user ************************ */
/* **************************************************************** */

const listPolicyTargets = async ( pageSize, marker, customer ) => {
    const apiName = `${process.env.REACT_APP_EXEC_API_NAME}` // replace this with your api name.
    let path = `/programs/iotcore/policies/IoTFrontPubSubCallback` //replace this with the path you have configured on your API
    const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken()
    if( pageSize ){
        path = `${path}?pageSize=${pageSize}`
    }
    if( marker ){
        path = `${path}?marker=${marker}`
    }    
    const payload = {
        headers: {
            'Content-Type':'application/json',
            'X-AccessToken': accessToken,
            'X-Channel': 'Web',
            'X-CustIdentNum': customer,
            'X-CustIdentType': 'NI',
            'X-IsClient': 'Active Client',
            'X-Name': 'IoWatt',
            'X-PowerPlant': 'NA',
        },
    }
    const res = await API.get(apiName, path, payload).then(response => {
        return response
    }).catch(error => {
        console.log(error.response)
        return { status: 'ERROR', message: error}
    })
    return res
}

const attachPolicy = async ( identityId, customer ) => {
    const apiName = `${process.env.REACT_APP_EXEC_API_NAME}` // replace this with your api name.
    let path = `/programs/iotcore/policies/IoTFrontPubSubCallback?action=attach` //replace this with the path you have configured on your API
    const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken()
    const payload = {
        body: {
            target: identityId
        },
        headers: {
            'Content-Type':'application/json',
            'X-AccessToken': accessToken,
            'X-Channel': 'Web',
            'X-CustIdentNum': customer,
            'X-CustIdentType': 'NI',
            'X-IsClient': 'Active Client',
            'X-Name': 'IoWatt',
            'X-PowerPlant': 'NA',
        },
    }
    const res = await API.post(apiName, path, payload).then(response => {
        return response
    }).catch(error => {
        console.log(error.response)
        return { status: 'ERROR', message: error}
    })
    return res
}

const attachIdentityToIotPolicy = async ( identityId, pageSize, customer ) => {
    const response = await listPolicyTargets( pageSize, null, customer )
    if( response.status && response.status === 'OK'){
        //const nextMarker = response.message.nextMarker
        const targets = response.message.targets
        const filtered = targets.filter( identity => identity.includes(identityId))
        if( filtered && filtered.length < 1 ){
            await attachPolicy( `${identityId}`, customer )
        } else {
            console.log('Identity exist into iot policy')
        }
    }
}

export function selectCustomerAction( customer ) {
    return async (dispath) => {
        customer['pages'] = getToCreatePermissions( customer.id )
        dispath( selectCustomer( customer ) )
        const credentials =  await Auth.currentCredentials()
        const identityId = credentials.identityId            
        attachIdentityToIotPolicy( identityId, 100, customer.id )
        customer['identityId'] = identityId
        dispath( setIdentityId( customer ) )        
    }
}

export function loginUserAction(user) {
    return async (dispath) => {
        dispath( loginUser() );
        try{
            const response = await Auth.signIn(user.name, user.password)
            const groups = response.signInUserSession.accessToken.payload["cognito:groups"]
            response['iowattGroups'] = groups

            if( groups && groups.includes('sa')){
                const lc = await API.graphql(graphqlOperation( listCustomers, { limit : 3 } ));
                const customers = lc.data.listCustomers.items
                if( customers.length === 0 ) {
                    response.pages = getToCreatePermissions('f115e23f-6193-48e1-9e64-23be5d4dd135')
                    response.customerId = 'f115e23f-6193-48e1-9e64-23be5d4dd135'
                    response.customersCount = 0
                } else if ( customers.length === 1 ) {
                    const customer = customers[0]
                    response.pages =  getToCreatePermissions(  customer.id )
                    response.customerId = customer.id
                    response.customersCount = 1
                } else {
                    response.customersCount = customers.length
                }
                const lm = await API.graphql(graphqlOperation( listMagnitudes, { limit : 3 } ));
                const magnitudes = lm.data.listMagnitudes.items
                if( magnitudes.length === 0 ) {
                    response['missingMagnitudes'] = true
                } else {
                    response['missingMagnitudes'] = false
                }
            }  else  {
                const cus = response.attributes["custom:company"]
                const country = response.attributes["custom:country"]
                const username = response.attributes.email
                const slug = slugify(cus,  { lower: true })                
                const cbn = await API.graphql(graphqlOperation( customerByName, { name : cus } ));
                const customers = cbn.data.customerByName.items
                if( customers.length < 1 ){
                    const cc = await API.graphql(graphqlOperation( createCustomer, { input: { name : cus, slug : slug, root: username, country: country } } ))
                    const newCustomer = cc.data.createCustomer
                    response.customerId = newCustomer.id
                    response['iowattGroups'] = ['guest']
                    response.pages = getToCreatePermissions( newCustomer.id )
                    response.newFlag = true
                    response.newRoot = 'pending'
                } else if ( !groups || groups.length < 1 ) {
                    response.customerId = customers[0].id
                    response['iowattGroups'] = ['guest']
                    response.pages = getGuestPermissions( customers[0].id, customers[0].root )
                } else {
                    if( groups && groups[0] === 'root' && username !== customers[0].root ){
                        await API.graphql(graphqlOperation( updateCustomer, { input: { id : customers[0].id, root: username } } ));
                    }                    
                    const credentials =  await Auth.currentCredentials()
                    const identityId = credentials.identityId            
                    attachIdentityToIotPolicy( identityId, 100, customers[0].id )  
                    response.identityId = identityId
                    response.customerId =  customers[0].id
                    response.pages = customers[0].pages.items
                    response.versions = sort( customers[0].versions.items, 'updatedAt', 'DESC' )
                }
            }
            dispath( loginUserSuccess(response) )
        } catch (error) {
            console.log(error);
            dispath( loginUserError(true) );
        }
    }    
}

const selectCustomer = ( customer ) => ({
    type: types.LOGIN_SELECT_CUSTOMER,
    payload: customer
})

const setIdentityId = ( identity ) => ({
    type: types.LOGIN_SET_IDENTITY_ID,
    payload: identity
})

const loginUser = () => ({
    type: types.LOGIN_USER,
    payload: true
});

const loginUserSuccess = user => ({
    type: types.LOGIN_USER_SUCCESS,
    payload: user
});

const loginUserError = status => ({
    type: types.LOGIN_USER_ERROR,
    payload: status
});


/* **************************************************************** */
/* **************************** Create user ************************ */
/* **************************************************************** */

export function createUserAction(user) {
    return async (dispath) => {
        dispath( createUser() );
        try{
            await Auth.signUp({
                username : user.email,
                password : user.password,
                attributes: {
                    email : user.email,
                    phone_number : user.country.split('@')[0] + user.phone,
                    'custom:country' : user.country.split('@')[1], 
                    'custom:company' : user.company
                }
            });
            dispath( createUserSuccess(user.email) );
        } catch (error) {
            console.log(error);
            dispath( createUserError(true) );
        }
    }
};

const createUser = () => ({
    type: types.CREATE_USER,
    payload: true
});

const createUserSuccess = username => ({
    type: types.CREATE_USER_SUCCESS,
    payload: username
});

const createUserError = status => ({
    type: types.CREATE_USER_ERROR,
    payload: status
});

/* **************************************************************** */
/* ************************** Confirm user ************************ */
/* **************************************************************** */


export function confirmUserAction(user) {
    return async (dispath) => {
        dispath( confirmUser() );
        try{
            const response = await Auth.confirmSignUp(user.username, user.code);
            dispath( confirmUserSuccess(response) );
        } catch (error) {
            console.log(error);
            dispath( confirmUserError(true) );
        }
    }
};

const confirmUser = () => ({
    type: types.CONFIRM_USER,
    payload: true
});

const confirmUserSuccess = status => ({
    type: types.CONFIRM_USER_SUCCESS,
    payload: status
});

const confirmUserError = status => ({
    type: types.CONFIRM_USER_ERROR,
    payload: status
});

/* **************************************************************** */
/* ************************** Resend code ************************ */
/* **************************************************************** */


export function resendCodeAction(user) {
    return async (dispath) => {
        dispath( resendCode() );
        try{
            const response = await Auth.resendSignUp(user.username);
            dispath( resendCodeSuccess(response) );
        } catch (error) {
            console.log(error);
            dispath( resendCodeError(true) );
        }
    }
};

const resendCode = () => ({
    type: types.RESEND_CODE,
    payload: true
});

const resendCodeSuccess = status => ({
    type: types.RESEND_CODE_SUCCESS,
    payload: status
});

const resendCodeError = status => ({
    type: types.CONFIRM_USER_ERROR,
    payload: status
});

/* **************************************************************** */
/* *********************** Forgot password ************************ */
/* **************************************************************** */

export function forgotPasswordAction(username){
    return async (dispatch) => {
        dispatch( forgotPassword() );
        Auth.forgotPassword( username )
        .then(data => dispatch( forgotPasswordSuccess( username ) ))
        .catch(err => dispatch( forgotPasswordError( true ) ));
    }
}

const forgotPassword = () => ({
    type: types.FORGOT_PASSWORD,
    payload: true
});

const forgotPasswordSuccess = username => ({
    type: types.FORGOT_PASSWORD_SUCCESS,
    payload: username
});

const forgotPasswordError = status => ({
    type: types.FORGOT_PASSWORD_ERROR,
    payload: status
});

/* **************************************************************** */
/* ***************** Forgot password submit *********************** */
/* **************************************************************** */

export function forgotPasswordSubmitAction(user){
    return async (dispatch) => {
        dispatch( forgotPasswordSubmit() );
        Auth.forgotPasswordSubmit(user.username, user.code, user.newPassword)
        .then(data => dispatch( forgotPasswordSubmitSuccess(data) ))
        .catch(err => dispatch( forgotPasswordSubmitError(true) ));
    }
}

const forgotPasswordSubmit = () => ({
    type: types.FORGOT_PASSWORD_SUBMIT,
    payload: true
});

const forgotPasswordSubmitSuccess = data => ({
    type: types.FORGOT_PASSWORD_SUBMIT_SUCCESS,
    payload: data
});

const forgotPasswordSubmitError = status => ({
    type: types.FORGOT_PASSWORD_SUBMIT_ERROR,
    payload: status
});

