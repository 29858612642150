import * as constants from '../../constants/index'
import * as types from '../types/calendars'


const initialState = {
    language: 'us',
    loading: false,
    loadingMessage: null,
    alertShow: false,
    alertSeverity: constants.SUCCESS,
    alertMessage: null,
    calendars: null,
    calendarList: [],
    hollidays: []
}

export default function(state = initialState, action){
    switch (action.type) {
        case types.SET_LANGUAGE:
            return {
                ...state,
                language: action.payload
            }
        case types.CALENDAR_LIST:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_ES ? constants.CALENDAR_GETTING_ES : constants.CALENDAR_GETTING_EN,
                alertShow: false,
                alertMessage: null
            }
        case types.CALENDAR_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertMessage: null,
                alertShow: false,
                calendars: action.payload,
                calendarList: action.payload.map( i => i.name),
            }
        case types.CALENDAR_LIST_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertMessage: state.language === constants.LAN_ES ? constants.CALENDAR_GETTING_ERROR_ES : constants.CALENDAR_GETTING_ERROR_EN,
                alertShow: true,
                alertSeverity: constants.ERROR,
            }
        case types.CALENDAR_CREATE:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_ES ? constants.CALENDAR_CREATING_ES : constants.CALENDAR_CREATING_EN,
                alertShow: false,
                alertMessage: null
            }
        case types.CALENDAR_CREATE_SUCCESS:
            const prevCalendars = state.calendars.filter( calendar => calendar.id !== action.payload.id )
            const updatedCalendarList = [ ...prevCalendars, action.payload ]
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,            
                alertMessage: state.language === constants.LAN_ES ? constants.CALENDAR_CREATE_SUCCESS_ES : constants.CALENDAR_CREATE_SUCCESS_EN,
                calendars: updatedCalendarList,
                calendarList: updatedCalendarList.map( i => i.name)
            }
        case types.CALENDAR_CREATE_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_ES ? constants.CALENDAR_CREATE_ERROR_ES : constants.CALENDAR_CREATE_ERROR_EN
            }
        case types.CALENDAR_UPDATE:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_ES ? constants.CALENDAR_UPDATING_ES : constants.CALENDAR_UPDATING_EN,
                alertShow: false,
                alertMessage: null
            }
        case types.CALENDAR_UPDATE_SUCCESS:
            const index = state.calendars.findIndex( calendar => calendar.id === action.payload.id )
            const updatedCalendars = [
                ...state.calendars.slice( 0, index ),
                action.payload,
                ...state.calendars.slice( index + 1 ),
            ]
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,
                alertMessage: state.language === constants.LAN_ES ? constants.CALENDAR_UPDATE_SUCCESS_ES : constants.CALENDAR_UPDATE_SUCCESS_EN,
                calendars: updatedCalendars,
                calendarList: updatedCalendars.map( i => i.name)
            }
        case types.CALENDAR_UPDATE_ERROR:
            return {
                ...state,
                loading: false,
                alertShow: true,
                alertSeverity: constants.ERROR,
                loadingMessage: constants.END_ERROR,
                alertMessage: state.language === constants.LAN_ES ? constants.CALENDAR_UPDATE_ERROR_ES : constants.CALENDAR_UPDATE_ERROR_EN
            }       
        case types.CALENDAR_DELETE:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_ES ? constants.CALENDAR_DELETING_ES : constants.CALENDAR_DELETING_EN,
                alertShow: false,
                alertMessage: null
            }        
        case types.CALENDAR_DELETE_SUCCESS:
            const notDeletedCalendars = state.calendars.filter( calendar => !action.payload.includes( calendar.id ) )
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,
                alertMessage: state.language === constants.LAN_ES ? constants.CALENDAR_DELETE_SUCCESS_ES : constants.CALENDAR_DELETE_SUCCESS_EN,
                calendars: notDeletedCalendars,
                calendarList: notDeletedCalendars.map( i => i.name),
            }
        case types.CALENDAR_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_ES ? constants.CALENDAR_DELETE_ERROR_EN : constants.CALENDAR_DELETE_ERROR_EN
            }
        case types.HOLLIDAYS_GET:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_ES ? constants.CALENDAR_GETTING_HOLLIDAYS_ES : constants.CALENDAR_GETTING_HOLLIDAYS_EN,
                alertShow: false,
                alertMessage: null
            }
        case types.HOLLIDAYS_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,
                alertMessage: null,
                hollidays: action.payload
            }
        case types.HOLLIDAYS_GET_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_ES ? constants.CALENDAR_GETTING_HOLLIDAYS_ERROR_ES : constants.CALENDAR_GETTING_HOLLIDAYS_ERROR_EN
            }
        case types.HOLLIDAYS_CREATE:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_ES ? constants.CALENDAR_CREATING_HOLLIDAYS_ES : constants.CALENDAR_CREATING_HOLLIDAYS_EN,
                alertShow: false,
                alertMessage: null
            }
        case types.HOLLIDAYS_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,
                alertMessage: state.language === constants.LAN_ES ? constants.CALENDAR_CREATE_HOLLIDAYS_SUCCESS_ES : constants.CALENDAR_CREATE_HOLLIDAYS_SUCCESS_EN,
                hollidays: action.payload
            }
        case types.HOLLIDAYS_CREATE_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_ES ? constants.CALENDAR_CREATE_HOLLIDAYS_ERROR_ES : constants.CALENDAR_CREATE_HOLLIDAYS_ERROR_EN
            }
        case types.HOLLIDAYS_UPDATE:
            return {
                ...state,
                loading: true,
                alertShow: false,
                loadingMessage: state.language === constants.LAN_ES ? constants.CALENDAR_UPDATING_HOLLIDAYS_ES : constants.CALENDAR_UPDATING_HOLLIDAYS_EN,
                alertMessage: null
            }
        case types.HOLLIDAYS_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,
                alertMessage: state.language === constants.LAN_ES ? constants.CALENDAR_UPDATE_HOLLIDAYS_SUCCESS_ES : constants.CALENDAR_UPDATE_HOLLIDAYS_SUCCESS_EN,
                hollidays: action.payload
            }
        case types.HOLLIDAYS_UPDATE_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_ES ? constants.CALENDAR_UPDATE_HOLLIDAYS_ERROR_ES : constants.CALENDAR_UPDATE_HOLLIDAYS_ERROR_EN
            }
        default:
            return state;
    }
}













