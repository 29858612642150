import * as constants from '../../constants/index'
import * as types from '../types/clients'

const initialState = {
    language: 'us',
    loading: false,
    loadingMessage: null,
    alertShow: false,
    alertSeverity: constants.SUCCESS,
    alertMessage: null,
    clients: []
}

export default function(state = initialState, action){
    switch (action.type) {
        case types.SET_LANGUAGE:
            return {
                ...state,
                language: action.payload
            }
        case types.CLIENTS_LIST:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.CLIENTS_GETTING_EN : constants.CLIENTS_GETTING_ES,
                alertShow: false,
            }
        case types.CLIENTS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: false,
                alertSeverity: constants.SUCCESS,
                alertMessage: null,
                clients: action.payload
            }
        case types.CLIENTS_LIST_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.CLIENTS_GETTING_ERROR_EN : constants.CLIENTS_GETTING_ERROR_ES,
            }
        case types.CLIENTS_CREATE:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.CLIENTS_CREATING_EN : constants.CLIENTS_CREATING_ES,
                alertShow: false,
                alertMessage: null,
            }
        case types.CLIENTS_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,            
                alertMessage: state.language === constants.LAN_EN ? constants.CLIENTS_CREATE_SUCCESS_EN : constants.CLIENTS_CREATE_SUCCESS_ES,
                clients: [ ...state.clients.filter( client => client.id !== action.payload.id ), action.payload ]
            }
        case types.CLIENTS_CREATE_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.CLIENTS_CREATE_ERROR_EN : constants.CLIENTS_CREATE_ERROR_ES
            }
        case types.CLIENTS_UPDATE:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.CLIENTS_UPDATING_EN : constants.CLIENTS_UPDATING_EN,
                alertShow: false,
                alertMessage: null,
            }
        case types.CLIENTS_UPDATE_SUCCESS:
            const index = state.clients.findIndex( client => client.id === action.payload.id )
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,            
                alertMessage: state.language === constants.LAN_EN ? constants.CLIENTS_UPDATE_SUCCESS_EN : constants.CLIENTS_UPDATE_SUCCESS_ES,
                clients: [ ...state.clients.slice( 0, index ), action.payload, ...state.clients.slice( index + 1 ),]
            }
        case types.CLIENTS_UPDATE_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.CLIENTS_UPDATE_ERROR_EN : constants.CLIENTS_UPDATE_ERROR_ES
            }
        case types.CLIENTS_DELETE:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.CLIENTS_DELETING_EN : constants.CLIENTS_DELETING_ES,
                alertShow: false,
                alertMessage: null,
            }
        case types.CLIENTS_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,            
                alertMessage: state.language === constants.LAN_EN ? constants.CLIENTS_DELETE_SUCCESS_EN : constants.CLIENTS_DELETE_SUCCESS_ES,
                clients: state.clients.filter( client => !action.payload.includes(client.id) )
            }
        case types.CLIENTS_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.CLIENTS_DELETE_ERROR_EN : constants.CLIENTS_DELETE_ERROR_ES
            }
        default:
            return state;
    }
}


