import * as types from '../types/clock';

const initialState = {
    emmit: false,
    counter: 0
}

export default function(state = initialState, action){
    switch (action.type) {
        case types.SET_START_CLOCK:
            return {
                ...state,
                emmit: false
            }
        case types.EMMIT_INTERVAL_HAPPENS:
            return {
                ...state,
                emmit: !state.emmit,
                counter: state.counter > 86400 ?  0 : state.counter + 1
            }
        default:
            return state;
    }
}