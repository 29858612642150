import { combineReducers } from 'redux';

import themeReducer from './themeReducers';
import customersReducer from './customersReducer';
import authReducer from './authReducers';
import languageReducer from './languageReducers'
import calendarsReducers from './calendarsReducers';
import clientsReducers from './clientsReducers'
import toastReducers from './toastReducers'
import driverReducers from './driverReducers'
import powerPlantReducers from './powerPlantReducers'
import magnitudeReducers from './magnitudeReducers'
import productReducers from './productReducers'
import iotReducers from './iotReducers';
import iowattDispatcherReducers from './iowattDispatcherReducers'
import iowattReducers from './iowattReducers'
import gatewayDevicesReducers from './gatewayDevicesReducers'
import programReducers from './programReducers'
import gatewayConfigReducers from './gatewayConfigReducers'
import clockReducers from './clockReducers'

export default combineReducers({
	themeReducer,
	customersReducer,
	authReducer,
	languageReducer,
	calendarsReducers,
	clientsReducers,
	driverReducers,
	powerPlantReducers,
	magnitudeReducers,
	productReducers,
	toastReducers,
	iotReducers,
	iowattDispatcherReducers,
	iowattReducers,
	gatewayDevicesReducers,
	gatewayConfigReducers,
	programReducers,
	clockReducers,
});
