import * as types from '../types/gatewayConfig'
import { API, Auth, graphqlOperation  } from 'aws-amplify'
import { v4 as uuidv4 } from 'uuid'
import { configByCustomer }  from '../../graphql-custom/queries'
import { delay, sort } from '../../helpers'
import { setDeviceRules, deleteDeviceRule, getConfigs, getCurrentProgramsByIowatts } from './iowattUtils'
import * as constants from '../../constants/index'



/* **************************************************************** */
/* ************************ SetDeviceRule ************************* */
/* **************************************************************** */

export function setGatewayConfigAction( program, user, customerId, customerName, action )  {
    return async (dispath) => {
        try{
            const apiName = `${process.env.REACT_APP_EXEC_API_NAME}`
            const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken()
            const messageId =  uuidv4()

            //wait while iowatts programs database relations is updated
            await delay(30)
            
            //Check config so you can compare between before and after updateAt such if they are not equal then new config is not updated
            dispath( startListConfigAction() )
            const beforeUpdateConfigs = await getConfigs(  customerId, program.measurePage.iowatts )
            dispath( beforeUpdateConfigs && beforeUpdateConfigs.length > 0 ? listConfigActionSuccess(beforeUpdateConfigs) : listConfigActionError() )            
            console.log('beforeUpdateConfigs')
            console.log(beforeUpdateConfigs && beforeUpdateConfigs.length > 0 ? beforeUpdateConfigs[0]['updatedAt'] : '--')

            //Fetch current programs associated to current iowatt
            const iowattPrograms = await getCurrentProgramsByIowatts( program.measurePage.iowatts )
            
            dispath( startGatewayConfigAction() )
            let burst = []
            iowattPrograms.forEach( io => {
                const iowatt        = {}
                iowatt.messageId    = messageId
                iowatt.customerId   = customerId
                iowatt.iowattId     = io.id
                iowatt.iowattName   = io.name
                iowatt.user         = user
                iowatt.sub          = `iowatt/sub/config/${ io.id }`
                iowatt.gateways     = []
                iowatt.programs     = io.programs

                if( action === constants.ACTION_CREATE_OR_UPDATE ) {
                    const hasGateways = program.measurePage.gateways.some( g => g.ioWattGatewaysId === iowatt.iowattId  ) 
                    let newIowatt = {}
                    if( !hasGateways ){
                        newIowatt = deleteDeviceRule( iowatt, program, user, customerId, customerName )
                    } else {
                        newIowatt = setDeviceRules( program, iowatt, user, customerId, customerName )
                    }
                    burst.push(newIowatt)
                } else {
                    const  newIowatt = deleteDeviceRule( iowatt, program, user, customerId, customerName )
                    burst.push(newIowatt)
                }                
            })  

            const payload = {
                body: burst,
                headers: {
                    'X-AccessToken': accessToken,
                    'X-Channel': 'Web',
                    'X-CustIdentNum': customerId,
                    'X-CustIdentType': 'NI',
                    'X-IsClient': 'Active Client',
                    'X-Name': 'IoWatt',
                    'X-PowerPlant': 'powerPlant',
                },
            }

            await API.post(apiName, '/programs/circuitor/config/setDevicesRules', payload).then(response => {
                dispath( gatewayConfigActionSuccess(response) )
            }).catch(error => {
                console.log(error)
                dispath( gatewayConfigActionError() )
            })

            //Send update config table asynchronous trigger so new config post iowatt commands sent is updated
            dispath( startGetGatewayDBConfigAction() )
            burst = []
            program.measurePage.iowatts.forEach( io => {
                const iowatt        = {}
                iowatt.messageId    = io.id
                iowatt.customerId   = customerId
                iowatt.iowattId     = io.id
                iowatt.user         = user
                iowatt.sub          = `iowatt/sub/config/${ io.id }`
                burst.push(iowatt)
            })
            payload['body'] = burst
            await API.post(apiName, '/programs/circuitor/config/getDbConfig', payload).then(response => {
                dispath( getGatewayDBConfigActionSuccess(response) )
            })
            .catch(error => {
                console.log(error)
                dispath( getGatewayDBConfigActionError() )
            })


            dispath( startCheckCompleteConfigAction())            
            if( beforeUpdateConfigs.length > 0  ){
                for ( let index = 0; index < 10; index++ ){
                    const after = await getConfigs(  customerId, program.measurePage.iowatts )
                    if( after.length > 0 && after.every( a => !beforeUpdateConfigs.some( buc => { 
                        console.log('checking tries')
                        console.log(buc['updatedAt'])
                        console.log(a['updatedAt'])
                        return buc['updatedAt'] === a['updatedAt']
                    })) ){
                        console.log('program end successfull')
                        dispath( checkCompleteConfigActionSuccess(after))
                        return
                    } else {
                        await delay(5)
                    }                    
                }
                console.log('error checking db configs after changes')
                dispath( checkCompleteConfigActionError())
                return
            }
            console.log('this is a first program')
            dispath( checkCompleteConfigActionSuccess({}))
            
        } catch (error) {
            console.log(error);
            dispath( checkCompleteConfigActionError() )
        }
    }
}

/* **************************************************************** */
/* ************************ getDBConfig ************************* */
/* **************************************************************** */

export function getDBConfigAction( iowatts, user, customerId )  {
    return async (dispath) => {
        console.log('aqui')
        dispath( startGetGatewayDBConfigAction() )
        try{
            const apiName = `${process.env.REACT_APP_EXEC_API_NAME}`
            const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken()
            
            let burst = []
            iowatts.forEach( io => {
                const iowatt        = {}
                iowatt.messageId    = io.id
                iowatt.customerId   = customerId
                iowatt.iowattId     = io.id
                iowatt.user         = user
                iowatt.sub          = `iowatt/sub/config/${ io.id }`
                burst.push(iowatt)
            })
            const payload = {
                body: burst,
                headers: {
                    'X-AccessToken': accessToken,
                    'X-Channel': 'Web',
                    'X-CustIdentNum': customerId,
                    'X-CustIdentType': 'NI',
                    'X-IsClient': 'Active Client',
                    'X-Name': 'IoWatt',
                    'X-PowerPlant': 'NA',
                },
            }

            await API.post(apiName, '/programs/circuitor/config/getDbConfig', payload).then(response => {
                dispath( getGatewayDBConfigActionSuccess(response) )
            })
            .catch(error => {
                console.log(error)
                dispath( getGatewayDBConfigActionError() )
            })
        } catch (error) {
            console.log(error);
            dispath( getGatewayDBConfigActionError() )
        }
    }
}

const startListConfigAction  = () => ({
    type: types.LIST_CONFIG,
    payload: true
})

const listConfigActionSuccess  = (beforeUpdateConfigs) => ({
    type: types.LIST_CONFIG_SUCESS,
    payload: beforeUpdateConfigs
})

const listConfigActionError  = () => ({
    type: types.LIST_CONFIG_ERROR,
    payload: false
})

const startGatewayConfigAction = () => ({
    type: types.SET_CONFIG,
    payload: true    
})

const gatewayConfigActionSuccess = (response) => ({
    type: types.SET_CONFIG_SUCESS,
    payload: response
})

const gatewayConfigActionError = () => ({
    type: types.SET_CONFIG_ERROR,
    payload: false
})

const startGetGatewayDBConfigAction = () => ({
    type: types.GET_DB_CONFIG,
    payload: true
})

const getGatewayDBConfigActionSuccess = (response) => ({
    type: types.GET_DB_CONFIG_SUCCESS,
    payload: response
})

const getGatewayDBConfigActionError = () => ({
    type: types.GET_DB_CONFIG_ERROR,
    payload: false
})

const startCheckCompleteConfigAction = () => ({
    type: types.CHECK_RESULT_CONFIG,
    payload: true
})

const checkCompleteConfigActionSuccess = (data) => ({
    type: types.CHECK_RESULT_CONFIG_SUCCESS,
    payload: data
})

const checkCompleteConfigActionError = () => ({
    type: types.CHECK_RESULT_CONFIG_ERROR,
    payload: false
})


/* **************************************************************** */
/* ********************** Lis Full Config ************************* */
/* **************************************************************** */

export function  listFullConfigAction ( customerId ) {
    return async (dispath) => {
        dispath( startListFullConfigAction() )
        try{            
            const result = await API.graphql(graphqlOperation( configByCustomer, { id : customerId } ))
            dispath( listFullConfigActionSuccess( sort( result.data.getCustomer.configs.items, 'updatedAt', 'DESC' ) ) )
        } catch (error) {
            console.log(error);
            dispath( listFullConfigActionError() )
        }
    }
}

const startListFullConfigAction  = () => ({
    type: types.LIST_FULL_CONFIG,
    payload: true
})

const listFullConfigActionSuccess  = (beforeUpdateConfigs) => ({
    type: types.LIST_FULL_CONFIG_SUCESS,
    payload: beforeUpdateConfigs
})

const listFullConfigActionError  = () => ({
    type: types.LIST_FULL_CONFIG_ERROR,
    payload: false
})


/* **************************************************************** */
/* ********************** Delete DBConfig ************************* */
/* **************************************************************** */


export function deleteDBConfigAction( iowatts, user, customerId )  {
    return async (dispath) => {
        dispath( startDeleteDBConfigAction() )
        try{
            const apiName = `${process.env.REACT_APP_EXEC_API_NAME}`
            const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken()
            const messageId =  uuidv4()

            let burst = []
            iowatts.forEach( io => {
                const iowatt        = {}
                iowatt.messageId    = messageId
                iowatt.customerId   = customerId
                iowatt.iowattId     = io.id
                iowatt.user         = user
                iowatt.sub          = `iowatt/sub/config/${ io.id }`
                burst.push(iowatt)
            })
            const payload = {
                body: burst,
                headers: {
                    'X-AccessToken': accessToken,
                    'X-Channel': 'Web',
                    'X-CustIdentNum': customerId,
                    'X-CustIdentType': 'NI',
                    'X-IsClient': 'Active Client',
                    'X-Name': 'IoWatt',
                    'X-PowerPlant': 'NA',
                },
            }

            await API.post(apiName, '/programs/circuitor/config/deleteDbConfig', payload).then(response => {
                dispath( deleteDBConfigActionSuccess(response) )
            })
            .catch(error => {
                console.log(error)
                dispath( deleteDBConfigActionError() )
            })
        } catch (error) {
            console.log(error);
            dispath( deleteDBConfigActionError() )
        }
    }
}

const startDeleteDBConfigAction = () => ({
    type: types.DELETE_DB_CONFIG,
    payload: true
})

const deleteDBConfigActionSuccess = (response) => ({
    type: types.DELETE_DB_CONFIG_SUCCESS,
    payload: response
})

const deleteDBConfigActionError = () => ({
    type: types.DELETE_DB_CONFIG_ERROR,
    payload: false
})


/* **************************************************************** */
/* ****************** checkComponents versions ******************** */
/* **************************************************************** */

export function listComponentLatestVersionAction ( name, arn, customerId ) {
    return  async ( dispath ) => {
        dispath( startListComponentLatestVersion() )
        try {
            const apiName = `${process.env.REACT_APP_EXEC_API_NAME}`
            const path = `/programs/greengrass/componentVersions`
            const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken()

            const payload = {
                body: {
                    arn: arn
                },
                headers: {
                    'X-AccessToken': accessToken,
                    'X-Channel': 'Web',
                    'X-CustIdentNum': customerId,
                    'X-CustIdentType': 'NI',
                    'X-IsClient': 'Active Client',
                    'X-Name': 'IoWatt',
                    'X-PowerPlant': 'None',
                },
            }

            await API.post(apiName, path, payload).then(response => {
                dispath( startListComponentLatestVersionSuccess( { name, response } ) )
            })
            .catch(error => {
                console.log(error)
                dispath( startListComponentLatestVersionError() )
            })

        } catch (error){
            console.log(error);
            dispath( startListComponentLatestVersionError() )
        }
    }
}


const startListComponentLatestVersion = () => ({
    type: types.COMPONENT_LIST,
    payload: true    
})

const startListComponentLatestVersionSuccess = (versions) => ({
    type: types.COMPONENT_LIST_SUCCESS,
    payload: versions
})

const startListComponentLatestVersionError = () => ({
    type: types.COMPONENT_LIST_ERROR,
    payload: false
})

/* **************************************************************** */
/* ********************** checkCore health ************************ */
/* **************************************************************** */

export function listCoresHealthAction ( coreGroup, status, customerId ) {
    return  async ( dispath ) => {
        dispath( startListCoresHealth() )
        try {
            const apiName = `${process.env.REACT_APP_EXEC_API_NAME}`
            const path = `/programs/greengrass/coreDevices/${coreGroup}?coreStatus=${status}`
            const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken()

            const payload = {
                headers: {
                    'X-AccessToken': accessToken,
                    'X-Channel': 'Web',
                    'X-CustIdentNum': customerId,
                    'X-CustIdentType': 'NI',
                    'X-IsClient': 'Active Client',
                    'X-Name': 'IoWatt',
                    'X-PowerPlant': 'None',
                },
            }

            await API.get(apiName, path, payload).then(response => {
                dispath( startListCoresHealthSuccess( { coreGroup, response } ) )
            })
            .catch(error => {
                console.log(error)
                dispath( startListCoresHealthError() )
            })

        } catch (error){
            console.log(error);
            dispath( startListCoresHealthError() )
        }
    }
}

const startListCoresHealth = () => ({
    type: types.CORES_HEALTH_LIST,
    payload: true    
})

const startListCoresHealthSuccess = (coresHealth) => ({
    type: types.CORES_HEALTH_LIST_SUCCESS,
    payload: coresHealth
})

const startListCoresHealthError = () => ({
    type: types.CORES_HEALTH_LIST_ERROR,
    payload: false
})

/* *******************+********************************************************************* */
/* ************************************** set language ************************************* */
/* *******************+********************************************************************* */

export function setLanguageAction( lan ){
    return async (dispath) => {
        dispath( setLanguage( lan ) )
    }
}

const setLanguage = (lan) => ({
    type: types.SET_LANGUAGE,
    payload: lan
})

/* **************************************************************** */
/* ************************* clear states ************************* */
/* **************************************************************** */

export function gatewayConfigClearStatesAction(){
    return async (dispath) => {
        dispath( setClearStates() )
    }
}

const setClearStates = () => ({
    type: types.SET_CLEAR_STATES,
    payload: true
})