import * as constants from '../../constants/index'
import * as types from '../types/magnitude'

const initialState = {
    language: 'us',
    loading: false,
    loadingMessage: null,
    alertShow: false,
    alertSeverity: constants.SUCCESS,
    alertMessage: null,
    magnitudes: []
}

export default function(state = initialState, action){
    switch (action.type) {
        case types.SET_LANGUAGE:
            return {
                ...state,
                language: action.payload
            }        
        case types.MAGNITUDE_LIST:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.MAGNITUDE_GETTING_EN : constants.MAGNITUDE_GETTING_ES,
                alertShow: false,
            }        
        case types.MAGNITUDE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: false,
                alertSeverity: constants.SUCCESS,
                alertMessage: null,
                magnitudes: action.payload
            }
        case types.MAGNITUDE_LIST_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.MAGNITUDE_GETTING_ERROR_EN : constants.MAGNITUDE_GETTING_ERROR_ES,
            }
        

        case types.MAGNITUDE_CREATE:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.MAGNITUDE_CREATING_EN: constants.MAGNITUDE_CREATING_ES,
                alertShow: false,
                alertMessage: null,
            }
        case types.MAGNITUDE_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,            
                alertMessage: state.language === constants.LAN_EN ? constants.MAGNITUDE_CREATE_SUCCESS_EN : constants.MAGNITUDE_CREATE_SUCCESS_ES,
                magnitudes: [ ...state.magnitudes.filter( magnitude => magnitude.id !== action.payload.id ), action.payload ],
            }
        case types.MAGNITUDE_CREATE_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.MAGNITUDE_CREATE_ERROR_EN : constants.MAGNITUDE_CREATE_ERROR_ES,
            }

        case types.MAGNITUDE_UPDATE:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.MAGNITUDE_UPDATING_EN : constants.MAGNITUDE_UPDATING_ES,
                alertShow: false,
                alertMessage: null,
            }
        case types.MAGNITUDE_UPDATE_SUCCESS:
            const index = state.magnitudes.findIndex( magnitude => magnitude.id === action.payload.id )
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,            
                alertMessage: state.language === constants.LAN_EN ? constants.MAGNITUDE_UPDATE_SUCCESS_EN : constants.MAGNITUDE_UPDATE_SUCCESS_ES,
                magnitudes: [ ...state.magnitudes.slice( 0, index ), action.payload, ...state.magnitudes.slice( index + 1), ],
            }
        case types.MAGNITUDE_UPDATE_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.MAGNITUDE_UPDATE_ERROR_EN : constants.MAGNITUDE_UPDATE_ERROR_ES,
            }

        case types.MAGNITUDE_DELETE:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_ES ? constants.MAGNITUDE_DELETING_ES : constants.MAGNITUDE_DELETING_EN,
                alertShow: false,
                alertMessage: null
            }
        case types.MAGNITUDE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,            
                alertMessage: state.language === constants.LAN_EN ? constants.MAGNITUDE_DELETE_SUCCESS_EN : constants.MAGNITUDE_DELETE_SUCCESS_ES,
                magnitudes: state.magnitudes.filter( magnitude => !action.payload.includes( magnitude.id ) ),
            }
        case types.MAGNITUDE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.MAGNITUDE_DELETE_ERROR_EN : constants.MAGNITUDE_DELETE_ERROR_ES,
            }
        default:
            return state;
    }
}