import React, {useEffect} from "react"
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"
import { API, graphqlOperation } from 'aws-amplify';
import { onCreateCustomer } from '../../graphql-custom/subscriptions'
import { listCustomerAction, /*setLanguageAction*/ } from '../../redux/actions/customersAction'
import { selectCustomerAction } from '../../redux/actions/authActions'

import Helmet from 'react-helmet'
import styled from "styled-components"
import {
  Avatar,
  FormControl,
  TextField,
  Button,
  Paper,
  Typography
} from "@material-ui/core";
import { spacing } from "@material-ui/system"
import { Autocomplete } from "@material-ui/lab"
import ModalProgress from '../../components/dialog/ModalProgress' 
import ByBAlert from '../../components/alerts/ByBAlert'
import * as constants from '../../constants/index'

import { objectEmpty } from '../../helpers'




const Wrapper = styled(Paper)`
  background: ${props => props.theme.body.background};
  padding: ${props => props.theme.spacing(6)}px;
  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
  width: 420px;
`;

const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${props => props.theme.spacing(5)}px;
`;

const Spacer = styled.div(spacing);

const CustomerSelect = () => {

    const history = useHistory();

     // customers dispatchers
    const dispacth = useDispatch();
    const listCustomerTrigger = () => dispacth( listCustomerAction() )
    const selectCustomerTrigger = ( customer ) => dispacth( selectCustomerAction(customer) ) 
    //const setLanguageTrigger = (language) => dispacth( setLanguageAction(language) )

    // customers reducers
    const loadingMessage = useSelector( state => state.customersReducer.loadingMessage )
    const loading = useSelector( state => state.customersReducer.loading )
    const customers = useSelector( state => state.customersReducer.customers )
    const alertShow = useSelector( state => state.customersReducer.alertShow )
    const alertSeverity = useSelector( state => state.customersReducer.alertSeverity ) 
    const alertMessage = useSelector( state => state.customersReducer.alertMessage ) 
    const language = useSelector( state => state.languageReducer.src )

    // local states
    const [ selected, setSelected] = React.useState({})
    const [ start, setStart ] = React.useState(false)
       
    useEffect( () => {
        try{
            if( !start ) {
                listCustomerTrigger()
                setStart(true)
            } 
            const createCustomerListener = API.graphql(graphqlOperation(onCreateCustomer)).subscribe({
                next: clientData => {
                    console.log('response on create customer listener')
                    console.log( clientData )
                }
            });
            console.log(`type of create customer listener is ${ typeof createCustomerListener }`)
        } catch (error) {
            console.log(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ customers ])

    const submitAccount = async e => {
        e.preventDefault()
        selectCustomerTrigger(selected)
        
        //await new Promise((resolve) => { setTimeout(resolve, 5000)})
        history.push('/home')
      }

    return (
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
            <Wrapper variant="outlined" >
                <Helmet title="Sign In Iowatt" />            
                <ByBAlert show={alertShow} type="title" severity={alertSeverity} message={alertMessage}/>   
                <Spacer mb={4} />     
                <ModalProgress open={ loading } modalTitle={ loadingMessage }/>
                <BigAvatar alt="Iowatt" src="/static/img/avatars/iowatt.jpg" />
                <Typography component="h2" variant="h2" align="center" gutterBottom>IOWATT</Typography>
                <Spacer mb={4} />
                <Typography component="h2" variant="body1" align="center">{ language === constants.LAN_ES ? 'Selección de cliente para el sa': 'Customer selection for sa'}</Typography>
                <form>
                    <FormControl margin="normal" required fullWidth>
                        <Autocomplete
                            id="interval"
                            options={customers}
                            getOptionLabel={(option) => option.name } 
                            filterSelectedOptions
                            onChange={ (e, selected) => {
                                setSelected(selected)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin="normal"
                                    variant="outlined"
                                    label= { language === constants.LAN_ES ? 'Seleccionar cliente:*' : 'Select customer:*'}
                                    onChange={ (e) => {
                                        setSelected(e.target.value)       
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                    <Spacer mb={8} />
                    <Button 
                        fullWidth 
                        variant="contained" 
                        color="primary" 
                        mb={2} 
                        onClick={submitAccount}
                        disabled={ objectEmpty(selected) }
                    >
                        { language === constants.LAN_ES ? 'Ir a la página principal' : 'Go to index page' }
                    </Button>
                </form>
            </Wrapper>
        </div>
    )
}
 
export default CustomerSelect;