import * as types from '../types/toast'
import { SUCCESS, FALSE, TRUE } from '../../constants'

const initialState = {
    alertShow: false,
    alertSeverity: SUCCESS,
    alertMessage: null,
}

export default function(state = initialState, action){
    switch (action.type) {
        case types.SHOW_TOAST:
            return {
                ...state,
                alertShow: TRUE,
                alertSeverity: action.payload.severity,
                alertMessage: action.payload.message,
            }
        case types.CLOSE_TOAST:
            return {
                ...state,
                alertShow: FALSE,
                alertSeverity: null,
                alertMessage: null,
            }
        default:
            return state;
    }
}