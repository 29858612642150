import React, {useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import { spacing } from "@material-ui/system";
import styled from "styled-components";
import {
  FormControl,
  Input,
  InputLabel,
  Button as MuiButton,
  Paper,
  Typography,
  Grid as MuiGrid,
  Link as HyperLink
} from "@material-ui/core";

import ModalProgress from '../../components/dialog/ModalProgress' 
import ByBAlert from '../../components/alerts/ByBAlert'
import { forgotPasswordSubmitAction  } from '../../redux/actions/authActions'


const Button = styled(MuiButton)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Spacer = styled.div(spacing);
const Wrapper = styled(Paper)`
  background: ${props => props.theme.body.background};
  padding: ${props => props.theme.spacing(6)}px;
  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
  width: 420px;
`;

function ResetPassword() {

  const history = useHistory();
  
  const [ account, setAccount ] = React.useState({
    username: '',
    code: '',
    newPassword: ''
  });

  const [ error, setError ] = React.useState({
    username: false,
    code: false,
    newPassword: false
  });

  const [ alert, setAlert ] = React.useState(false);
  const [ start, setStart ] = React.useState(false);
  const [ filling, setFilling ] = React.useState(false);

  const dispacth = useDispatch();
  const forgotPasswordSubmit = (user) => dispacth( forgotPasswordSubmitAction(user) );
  const resetUsername = useSelector( state => state.authReducer.username );
  const resetStatus = useSelector( state => state.authReducer.status );
  const resetMessage = useSelector( state => state.authReducer.message );
  const loading = useSelector( state => state.authReducer.loading );
  
  
  useEffect(() => {
    setAccount({ username: '', code: '', newPassword: '' });
    setError({ username: false, code: false, newPassword: false })
    setStart(false);
  }, [ resetStatus ]);

  const handleChange = (event) => {
    setFilling(true);
    setAccount({
      ...account,
      [ event.target.name]: event.target.value,
    });
    setError({
      ...error,
      [ event.target.name ]: event.target.value.trim() ? true : false,
    });
  };

  const { code, newPassword } = account
  
  const submitNewPassword= e => {
    e.preventDefault();
    setFilling(false);
    if( code.trim() === '' && newPassword.trim() === '' ){
      setStart(true);
      setAlert(true);
      return;
    }else{
      setAlert(false);  
    }
    
    forgotPasswordSubmit({'username' : resetUsername, code, newPassword });
    history.push('/auth/reset-password')
  }


  return (
    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
      <Wrapper variant="outlined">
        <ByBAlert show={ resetStatus && !filling && !alert } type="title" severity={ resetStatus } message={ resetMessage }/>
        <ByBAlert show={!filling && alert} type="title" severity="error" message="Required fields are missing!"/>
        <Spacer mb={4} />     
        <ModalProgress open = { loading } modalTitle = 'Updating new password...'/>

        <Typography component="h1" variant="h4" align="center" gutterBottom>Setup new password</Typography>

        <form>
          
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="name">Username</InputLabel>
            <Input id="name" name="name" autoFocus inputProps={{name:'username'}} value={ resetUsername } disabled />
          </FormControl>

          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">New Password</InputLabel>
            <Input name="password" type="password" id="password" inputProps={{name:'newPassword'}} error={( !error.newPassword && start )} onChange={handleChange} value={ account.newPassword } />
          </FormControl>

          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="code">Confirmation code</InputLabel>
            <Input id="code" name="code" autoComplete="code" inputProps={{name:'code'}} error={( !error.code && start )} onChange={handleChange} value={account.code} />
          </FormControl>
          <Spacer mb={8} />

          <Button fullWidth variant="contained" color="primary" mt={2} onClick={submitNewPassword} >Update</Button>

        </form>

        <Spacer mb={4} />

        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <Typography component="h2" variant="body1"><HyperLink href="/">Back to Sign In</HyperLink></Typography>
          </Grid>
        </Grid>
      
      </Wrapper>
    </div>
  );
}

export default ResetPassword;
