export const SET_LANGUAGE = 'SET_LANGUAGE'

export const LIST_CONFIG = 'LIST_CONFIG'
export const LIST_CONFIG_SUCESS = 'LIST_CONFIG_SUCESS'
export const LIST_CONFIG_ERROR = 'LIST_CONFIG_ERROR'
export const LIST_FULL_CONFIG = 'LIST_FULL_CONFIG'
export const LIST_FULL_CONFIG_SUCESS = 'LIST_FULL_CONFIG_SUCESS'
export const LIST_FULL_CONFIG_ERROR = 'LIST_FULL_CONFIG_ERROR'
export const SET_CONFIG = 'SET_CONFIG'
export const SET_CONFIG_SUCESS = 'SET_CONFIG_SUCESS'
export const SET_CONFIG_ERROR = 'SET_CONFIG_ERROR'
export const GET_DB_CONFIG = 'GET_DB_CONFIG'
export const GET_DB_CONFIG_SUCCESS = 'GET_DB_CONFIG_SUCCESS'
export const GET_DB_CONFIG_ERROR = 'GET_DB_CONFIG_ERROR'
export const CHECK_RESULT_CONFIG = 'CHECK_RESULT_CONFIG'
export const CHECK_RESULT_CONFIG_SUCCESS = 'CHECK_RESULT_CONFIG_SUCCESS'
export const CHECK_RESULT_CONFIG_ERROR = 'CHECK_RESULT_CONFIG_ERROR'
export const DELETE_DB_CONFIG = 'DELETE_DB_CONFIG'
export const DELETE_DB_CONFIG_SUCCESS = 'DELETE_DB_CONFIG_SUCCESS'
export const DELETE_DB_CONFIG_ERROR = 'DELETE_DB_CONFIG_ERROR'

export const COMPONENT_LIST = "COMPONENT_LIST"
export const COMPONENT_LIST_SUCCESS = "COMPONENT_LIST_SUCCESS"
export const COMPONENT_LIST_ERROR = "COMPONENT_LIST_ERROR"

export const CORES_HEALTH_LIST = "CORES_HEALTH_LIST"
export const CORES_HEALTH_LIST_SUCCESS = "CORES_HEALTH_LIST_SUCCESS"
export const CORES_HEALTH_LIST_ERROR = "CORES_HEALTH_LIST_ERROR"

export const SET_CLEAR_STATES = 'SET_CLEAR_STATES'