export const SET_LANGUAGE = 'SET_LANGUAGE'

export const MAGNITUDE_LIST = 'MAGNITUDE_LIST'
export const MAGNITUDE_LOADING_IMAGE = 'MAGNITUDE_LOADING_IMAGE'
export const MAGNITUDE_LIST_SUCCESS = 'MAGNITUDE_LIST_SUCCESS'
export const MAGNITUDE_LIST_ERROR = 'MAGNITUDE_LIST_ERROR'
export const MAGNITUDE_CREATE = 'MAGNITUDE_CREATE'
export const MAGNITUDE_CREATE_SUCCESS = 'MAGNITUDE_CREATE_SUCCESS'
export const MAGNITUDE_CREATE_ERROR = 'MAGNITUDE_CREATE_ERROR'
export const MAGNITUDE_UPDATE = 'MAGNITUDE_UPDATE'
export const MAGNITUDE_UPDATE_SUCCESS = 'MAGNITUDE_UPDATE_SUCCESS'
export const MAGNITUDE_UPDATE_ERROR = 'MAGNITUDE_UPDATE_ERROR'
export const MAGNITUDE_DELETE = 'MAGNITUDE_DELETE'
export const MAGNITUDE_DELETE_SUCCESS = 'MAGNITUDE_DELETE_SUCCESS'
export const MAGNITUDE_DELETE_ERROR = 'MAGNITUDE_DELETE_ERROR'