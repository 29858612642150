import * as types from '../types/gatewayDevices'
import * as constants from '../../constants/index'

const initialState = {
    language: 'us',
    loading: false,
    loadingMessage: null,
    alertShow: false,
    alertSeverity: constants.SUCCESS,
    alertMessage: null,
    gateways: [],
    variables: [],
    countVariablePages: 1,
    nextToken: 'initial'
}

export default function(state = initialState, action){
    switch (action.type) {
        case types.SET_LANGUAGE:
            return {
                ...state,
                language: action.payload
            }
        case types.SET_CLEAR_STATES:
            return {
                ...state,
                loading: false,
                loadingMessage: null,
                alertShow: false,
                alertSeverity: constants.SUCCESS,
                alertMessage: null,
                gateways: [],
                variables: [],
                countVariablePages: 1,
                nextToken: 'initial'
            }        
        case types.PULL_DEVICES:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.PULL_DEVICES_EN : constants.PULL_DEVICES_ES,
                alertShow: false,
            }
        case types.PULL_DEVICES_SUCCESS:
            const indexGatewayDevicesFound = state.gateways.findIndex( gateway => gateway.id === action.payload.id )
            const gatewayDevicesFound = state.gateways.filter( gateway => gateway.id === action.payload.id )[0]
            gatewayDevicesFound['deviceQuantity'] = action.payload.quantity
            gatewayDevicesFound['pullIsReady'] = true
            gatewayDevicesFound['devices'] = action.payload.devices
            return {
                ...state,
                loading: false,
                loadingMessage: constants.SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,
                alertMessage: (state.language === constants.LAN_EN ? constants.PULL_DEVICES_SUCCESS_EN : constants.PULL_DEVICES_SUCCESS_ES).replace('@devices', action.payload.quantity.toString()),
                gateways: [ ...state.gateways.slice( 0, indexGatewayDevicesFound ), gatewayDevicesFound, ...state.gateways.slice( indexGatewayDevicesFound + 1 )]
            }
        case types.PULL_DEVICES_ERROR:
            const indexGatewayDevicesNotFound = state.gateways.findIndex( gateway => gateway.id === action.payload )
            const gatewayDevicesNotFound = state.gateways.filter( gateway => gateway.id === action.payload )[0]
            gatewayDevicesNotFound['pullIsReady'] = false
            return {
                ...state,
                loading: false,
                loadingMessage: null,
                alertSeverity: constants.ERROR,
                alertMessage: ( state.language === constants.LAN_EN ? constants.PULL_DEVICES_ERROR_EN : constants.PULL_DEVICES_ERROR_ES ).replace('@gateway', gatewayDevicesNotFound.name ) ,
                alertShow: true,
                gateways: [ ...state.gateways.slice( 0, indexGatewayDevicesNotFound ), gatewayDevicesNotFound, ...state.gateways.slice( indexGatewayDevicesNotFound + 1 )]
            }
        case types.GET_DEVICES:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.GET_DEVICES_EN : constants.GET_DEVICES_ES,
                alertShow: false,
            }
        case types.GET_DEVICES_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: null,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.GET_DEVICES_ERROR_EN : constants.GET_DEVICES_ERROR_ES,
            }
        case types.GET_DEVICES_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: null,
                alertShow: true,
                alertSeverity: constants.SUCCESS,
                alertMessage: state.language === constants.LAN_EN ? constants.GET_DEVICES_SUCCESS_EN : constants.GET_DEVICES_SUCCESS_ES,
                gateways: action.payload
            }
        case types.CREATE_DEVICES:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.CREATE_DEVICES_EN : constants.CREATE_DEVICES_ES,
                alertShow: false,
                alertMessage: null,
            }
        case types.CREATE_DEVICES_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,            
                alertMessage: state.language === constants.LAN_EN ? constants.CREATE_DEVICES_SUCCESS_EN : constants.CREATE_DEVICES_SUCCESS_ES,
                gateways: [ ...state.gateways.filter( gateway => gateway.id !== action.payload.id ), action.payload ]
            }
        case types.CREATE_DEVICES_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.CREATE_DEVICES_ERROR_EN : constants.CREATE_DEVICES_ERROR_ES
            }
        case types.UPDATE_DEVICES:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.UPDATE_DEVICES_EN : constants.UPDATE_DEVICES_ES,
                alertShow: false,
                alertMessage: null,
            }
        case types.UPDATE_DEVICES_SUCCESS:
            const index = state.gateways.findIndex( gateway => gateway.id === action.payload.id )
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,            
                alertMessage: state.language === constants.LAN_EN ? constants.UPDATE_DEVICES_SUCCESS_EN : constants.UPDATE_DEVICES_SUCCESS_ES,
                gateways: [ ...state.gateways.slice( 0, index ), action.payload, ...state.gateways.slice( index + 1 ),]
            }
        case types.UPDATE_DEVICES_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.UPDATE_DEVICES_ERROR_EN : constants.UPDATE_DEVICES_ERROR_ES
            }
        
        case types.DELETE_DEVICES:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.DELETE_DEVICES_EN : constants.DELETE_DEVICES_ES,
                alertShow: false,
                alertMessage: null,
            }
        case types.DELETE_DEVICES_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,            
                alertMessage: state.language === constants.LAN_EN ? constants.DELETE_DEVICES_SUCCESS_EN : constants.DELETE_DEVICES_SUCCESS_ES,
                gateways: state.gateways.filter( gateway => !action.payload.includes(gateway.id) )
            }
        case types.DELETE_DEVICES_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.DELETE_DEVICES_ERROR_EN : constants.DELETE_DEVICES_ERROR_ES
            }       
        case types.DELETE_DEVICES_THERE_ARE_PROGRAMS_ERROR:
            const messageEn =  constants.IOWATTS_DELETE_THERE_ARE_PROGRAMS_ERROR_EN.replaceAll('@count', action.payload.length )
            const messageEs =  constants.IOWATTS_DELETE_THERE_ARE_PROGRAMS_ERROR_ES.replaceAll('@count', action.payload.length )
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage:  state.language === constants.LAN_EN ? messageEn : messageEs
            }
        case types.LIST_VARIABLES:
            return {
                ...state,
                loading: action.payload,
                loadingMessage: state.language === constants.LAN_EN ? constants.LIST_VARIABLES_EN : constants.LIST_VARIABLES_ES,
                alertShow: false,
            }
        case types.LIST_VARIABLES_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.LIST_VARIABLES_ERROR_EN : constants.LIST_VARIABLES_ERROR_ES,
            }
        case types.LIST_VARIABLES_SUCCESS:
            const items = action.payload.variables.items
            const nextToken = action.payload.variables.nextToken
            let variables = action.payload.isFirst ? items : state.variables.concat(items)
            return {
                ...state,
                loading: false,
                loadingMessage: constants.SUCCESS,
                alertShow: false,
                alertSeverity: constants.SUCCESS,
                alertMessage: state.language === constants.LAN_EN ? constants.LIST_VARIABLES_SUCCESS_EN : constants.LIST_VARIABLES_SUCCESS_ES,
                variables: variables,
                countVariablePages: Math.ceil( variables.length / 10 ) ,
                nextToken: nextToken
            }
        default:
            return state;
    }
}