export const TRUE = true
export const FALSE = false
export const SUCCESS = 'success'
export const ERROR = 'error'
export const END_SUCCESS = 'End successful'
export const END_ERROR = 'End with errors'
export const LAN_ES = 'es'
export const LAN_EN = 'us'
export const STATUS_SUCCESS = 'SUCCESS'
export const STATUS_COMPLETE = 'COMPLETE'
export const STATUS_ERROR = 'ERROR'
export const STATUS_PENDING = 'PENDING'
export const LOADING_EN = 'Loading...'
export const LOADING_ES = 'Cargando...'
export const OK = 'OK'
export const UNKNOWN  = 'UNKNOWN'

export const UPDATED_AT  = 'updatedAt'
export const CREATED_AT  = 'createdAt'
export const ORDER_ASC  = 'ASC'
export const ORDER_DESC  = 'DESC'

export const ACTION_CREATE_OR_UPDATE  = 'createOrUpdate'
export const ACTION_DELETE  = 'delete'

export const STATUS_UNHEALTHY  = 'UNHEALTHY'
export const STATUS_HEALTHY  = 'HEALTHY'
export const NUCLEUS_COMPONENT_NAME  = 'aws.greengrass.Nucleus'
export const CLI_COMPONENT_NAME  = 'aws.greengrass.Cli'
export const SECRET_COMPONENT_NAME  = 'aws.greengrass.SecretManager'
export const STREAM_COMPONENT_NAME  = 'aws.greengrass.StreamManager'
export const OWNER_STREAM_COMPONENT_NAME  = 'com.iowatt.CircuitorStreamComponent'
export const OWNER_DEVICES_COMPONENT_NAME  = 'com.iowatt.CircuitorDevicesComponent'
export const OWNER_CONFIG_COMPONENT_NAME  = 'com.iowatt.CircuitorConfigComponent'
export const NUCLEUS_COMPONENT_ARN = `arn:aws:greengrass:${process.env.REACT_APP_REGION}:aws:components:aws.greengrass.Nucleus`
export const CLI_COMPONENT_ARN = `arn:aws:greengrass:${process.env.REACT_APP_REGION}:aws:components:aws.greengrass.Cli`
export const SECRET_COMPONENT_ARN = `arn:aws:greengrass:${process.env.REACT_APP_REGION}:aws:components:aws.greengrass.SecretManager`
export const STREAM_COMPONENT_ARN = `arn:aws:greengrass:${process.env.REACT_APP_REGION}:aws:components:aws.greengrass.StreamManager`
export const OWNER_STREAM_COMPONENT_ARN = `arn:aws:greengrass:${process.env.REACT_APP_REGION}:${process.env.REACT_APP_ACCOUNT}:components:com.iowatt.CircuitorStreamComponent`
export const OWNER_DEVICES_COMPONENT_ARN = `arn:aws:greengrass:${process.env.REACT_APP_REGION}:${process.env.REACT_APP_ACCOUNT}:components:com.iowatt.CircuitorDevicesComponent`
export const OWNER_CONFIG_COMPONENT_ARN = `arn:aws:greengrass:${process.env.REACT_APP_REGION}:${process.env.REACT_APP_ACCOUNT}:components:com.iowatt.CircuitorConfigComponent`

export const CUSTOMERS_GETTING_EN = 'Fetching customers'
export const CUSTOMERS_GETTING_ES = 'Obteniendo clientes'
export const CUSTOMERS_GETTING_ERROR_EN = 'Fetching customers error, please view app logs'
export const CUSTOMERS_GETTING_ERROR_ES = 'Error obteniendo clientes, informa al administrador'
export const CUSTOMERS_CREATING_EN = 'Creating customer'
export const CUSTOMERS_CREATING_ES = 'Creando cliente'
export const CUSTOMERS_CREATING_PERMISSIONS_EN = 'Creating customer permissions'
export const CUSTOMERS_CREATING_PERMISSIONS_ES = 'Creando privilrgios del cliente'
export const CUSTOMERS_CREATE_PERMISSIONS_SUCCESS_EN = 'Create customer permissions successfull'
export const CUSTOMERS_CREATE_PERMISSIONS_SUCCESS_ES = 'Permisos del cliente creados exitosamente'
export const CUSTOMERS_CREATE_PERMISSIONS_ERROR_EN = 'Create customer permissions error, please view logs'
export const CUSTOMERS_CREATE_PERMISSIONS_ERROR_ES = 'Error creando permisos del cliente, por favor revisar el log de aplicación'
export const CREATE_CUSTOMER_MAGNITUDES_EN = 'Creating customer magnitudes'
export const CREATE_CUSTOMER_MAGNITUDES_ES = 'Creando magnitudes del cliente'
export const CREATE_CUSTOMER_MAGNITUDES_SUCCESS_EN = 'Create customer magnitudes successfull'
export const CREATE_CUSTOMER_MAGNITUDES_SUCCESS_ES = 'Manitudes del cliente creadas exitosamente'
export const CREATE_CUSTOMER_MAGNITUDES_ERROR_EN = 'Create customer magnitudes error, please view logs'
export const CREATE_CUSTOMER_MAGNITUDES_ERROR_ES = 'Error creando magnitudes del cliente, por favor revisar el log de aplicación'
export const CLIENTS_GETTING_EN = 'Fetching clients'
export const CLIENTS_GETTING_ES = 'Obteniendo clientes'
export const CLIENTS_GETTING_ERROR_EN = 'Fetching clients error, please view app logs'
export const CLIENTS_GETTING_ERROR_ES = 'Error obteniendo clientes, informa al administrador'
export const CLIENTS_CREATING_EN = 'Creating client'
export const CLIENTS_CREATING_ES = 'Creando cliente'
export const CLIENTS_UPDATING_EN = 'Updating client'
export const CLIENTS_UPDATING_ES = 'Actualizando cliente'
export const CLIENTS_DELETING_EN = 'Calendar deleting'
export const CLIENTS_DELETING_ES = 'Borrando cliente'
export const CLIENTS_DELETE_SUCCESS_EN = 'Delete client successfull'
export const CLIENTS_DELETE_SUCCESS_ES = 'Cliente borrado exitosamente'
export const CLIENTS_DELETE_ERROR_EN = 'Delete customers error, please view logs'
export const CLIENTS_DELETE_ERROR_ES = 'Error borrando clientes, por favor revisar el log de aplicación'
export const CLIENTS_CREATE_SUCCESS_EN = 'Create client successfull'
export const CLIENTS_CREATE_SUCCESS_ES = 'Client creado exitosamente'
export const CLIENTS_CREATE_ERROR_EN = 'Create client error, please view logs'
export const CLIENTS_CREATE_ERROR_ES = 'Error creando cliente, por favor revisar el log de aplicación'
export const CLIENTS_UPDATE_SUCCESS_EN = 'Update client successfull'
export const CLIENTS_UPDATE_SUCCESS_ES = 'Client actualizado exitosamente'
export const CLIENTS_UPDATE_ERROR_EN = 'Update client error, please view logs'
export const CLIENTS_UPDATE_ERROR_ES = 'Error actualizando cliente, por favor revisar el log de aplicación'


export const REQUIRED_FIELS_MESSAGE_EN = 'Required field'
export const REQUIRED_FIELS_MESSAGE_ES = 'Campo obligatorio'
export const CALENDAR_BASE_ARRAY = ['Everydays', 'Weekdays', 'Weekends', 'Hollidays']
export const CALENDAR_GETTING_EN = 'Fetching cumtom calendars'
export const CALENDAR_GETTING_ES = 'Obteniendo calendarios personalizados'
export const CALENDAR_GETTING_ERROR_EN = 'Fetching calendars error, please view app logs'
export const CALENDAR_GETTING_ERROR_ES = 'Error obteniendo calendarios, informa al administrador'
export const CALENDAR_CREATING_EN = 'Creating calendar'
export const CALENDAR_CREATING_ES = 'Creando calendario'
export const CALENDAR_UPDATING_EN = 'Updating calendar'
export const CALENDAR_UPDATING_ES = 'Actualizando calendario'
export const CALENDAR_DEFAULT_BEGIN_HOUR = '00:00:00'
export const CALENDAR_DEFAULT_END_HOUR = '23:59:59'
export const CALENDAR_ACTION_CREATE = 'create'
export const CALENDAR_ACTION_UPDATE = 'update'
export const CALENDAR_DELETING_EN = 'Calendar deleting'
export const CALENDAR_DELETING_ES = 'Borrando calendario'
export const CALENDAR_DELETE_SUCCESS_EN = 'Delete calendar successfull'
export const CALENDAR_DELETE_SUCCESS_ES = 'Calendario borrado exitosamente'
export const CALENDAR_DELETE_ERROR_EN = 'Delete customers error, please view logs'
export const CALENDAR_DELETE_ERROR_ES = 'Error borrando clientes, por favor revisar el log de aplicación'
export const CALENDAR_CREATE_SUCCESS_EN = 'Create calendar successfull'
export const CALENDAR_CREATE_SUCCESS_ES = 'Calendario creado exitosamente'
export const CALENDAR_CREATE_ERROR_EN = 'Create calendar error, please view logs'
export const CALENDAR_CREATE_ERROR_ES = 'Error creando calendario, por favor revisar el log de aplicación'
export const CALENDAR_UPDATE_SUCCESS_EN = 'Update calendar successfull'
export const CALENDAR_UPDATE_SUCCESS_ES = 'Calendario actualizado exitosamente'
export const CALENDAR_UPDATE_ERROR_EN = 'Update calendar error, please view logs'
export const CALENDAR_UPDATE_ERROR_ES = 'Error actualizando calendario, por favor revisar el log de aplicación'
export const CALENDAR_DELETE_QUESTION_EN = 'Do you want to delete this calendar?' 
export const CALENDAR_DELETE_QUESTION_ES = '¿Desea borrar el this calendario?' 
export const CALENDAR_DELETE_QUESTION_EXPLAIN_EN = 'If you delete this calendar all programs using this calendar are gonna use default Everyday calendar' 
export const CALENDAR_DELETE_QUESTION_EXPLAIN_ES = 'If borras este calendario all los programas que los usan quedarán con el calendario "Everyday"' 
export const CALENDAR_EXCECUTION_HOUR_MESSAGE_EN = 'Calendar will be executed between these hours:' 
export const CALENDAR_EXCECUTION_HOUR_MESSAGE_ES = 'El calendario se ejecutará en el siguiente intervalo de horas:' 
export const CALENDAR_RULE_GREATER_THAN_EN = 'Begin hour is greater than end hour' 
export const CALENDAR_RULE_GREATER_THAN_ES = 'Hora inicial mayor a hora final' 


export const CALENDAR_GETTING_HOLLIDAYS_EN = 'Fetching hollidays'
export const CALENDAR_GETTING_HOLLIDAYS_ES = 'Obteniendo días festivos'
export const CALENDAR_GETTING_HOLLIDAYS_ERROR_EN = 'Fetching hollidays error, please view logs'
export const CALENDAR_GETTING_HOLLIDAYS_ERROR_ES = 'Error obteniendo días festivos, por favor revisar el log de aplicación'
export const CALENDAR_CREATING_HOLLIDAYS_EN = 'Creating hollidays'
export const CALENDAR_CREATING_HOLLIDAYS_ES = 'Creando días festivos'
export const CALENDAR_CREATE_HOLLIDAYS_SUCCESS_EN = 'Create hollidays successfull'
export const CALENDAR_CREATE_HOLLIDAYS_SUCCESS_ES = 'Días festivos creados exitosamente'
export const CALENDAR_CREATE_HOLLIDAYS_ERROR_EN = 'Create hollidays error, please view logs'
export const CALENDAR_CREATE_HOLLIDAYS_ERROR_ES = 'Error creando días festivos, por favor revisar el log de aplicación'
export const CALENDAR_UPDATING_HOLLIDAYS_EN = 'Updating hollidays'
export const CALENDAR_UPDATING_HOLLIDAYS_ES = 'Actualizando días festivos'
export const CALENDAR_UPDATE_HOLLIDAYS_SUCCESS_EN = 'Update hollidays successfull'
export const CALENDAR_UPDATE_HOLLIDAYS_SUCCESS_ES = 'Días festivos actualizados exitosamente'
export const CALENDAR_UPDATE_HOLLIDAYS_ERROR_EN = 'Update hollidays error, please view logs'
export const CALENDAR_UPDATE_HOLLIDAYS_ERROR_ES = 'Error actualizando días festivos, por favor revisar el log de aplicación'


export const DRIVER_LOADING_IMAGE_EN = 'Loading driver image'
export const DRIVER_LOADING_IMAGE_ES = 'Cargando imagen del driver'
export const DRIVER_GETTING_EN = 'Fetching drivers'
export const DRIVER_GETTING_ES = 'Obteniendo drivers'
export const DRIVER_GETTING_ERROR_EN = 'Fetching drivers error, please view app logs'
export const DRIVER_GETTING_ERROR_ES = 'Error obteniendo drivers, informa al administrador'
export const DRIVER_CREATING_EN = 'Creating driver'
export const DRIVER_CREATING_ES = 'Creando driver'
export const DRIVER_UPDATING_EN = 'Updating driver'
export const DRIVER_UPDATING_ES = 'Actualizando driver'
export const DRIVER_DELETING_EN = 'Calendar deleting'
export const DRIVER_DELETING_ES = 'Borrando driver'
export const DRIVER_DELETE_SUCCESS_EN = 'Delete driver successfull'
export const DRIVER_DELETE_SUCCESS_ES = 'Driver borrado exitosamente'
export const DRIVER_DELETE_ERROR_EN = 'Delete customers error, please view logs'
export const DRIVER_DELETE_ERROR_ES = 'Error borrando drivers, por favor revisar el log de aplicación'
export const DRIVER_CREATE_SUCCESS_EN = 'Create driver successfull'
export const DRIVER_CREATE_SUCCESS_ES = 'Driver creado exitosamente'
export const DRIVER_CREATE_ERROR_EN = 'Create driver error, please view logs'
export const DRIVER_CREATE_ERROR_ES = 'Error creando driver, por favor revisar el log de aplicación'
export const DRIVER_UPDATE_SUCCESS_EN = 'Update driver successfull'
export const DRIVER_UPDATE_SUCCESS_ES = 'Driver actualizado exitosamente'
export const DRIVER_UPDATE_ERROR_EN = 'Update driver error, please view logs'
export const DRIVER_UPDATE_ERROR_ES = 'Error actualizando driver, por favor revisar el log de aplicación'


export const POWER_PLANT_GETTING_EN = 'Fetching power plants'
export const POWER_PLANT_GETTING_ES = 'Obteniendo plantas de producción'
export const POWER_PLANT_GETTING_ERROR_EN = 'Fetching power plants error, please view app logs'
export const POWER_PLANT_GETTING_ERROR_ES = 'Error obteniendo plantas de producción, informa al administrador'
export const POWER_PLANT_CREATING_EN = 'Creating power plant'
export const POWER_PLANT_CREATING_ES = 'Creando planta de producción'
export const POWER_PLANT_UPDATING_EN = 'Updating power plant'
export const POWER_PLANT_UPDATING_ES = 'Actualizando planta de producción'
export const POWER_PLANT_DELETING_EN = 'Calendar deleting'
export const POWER_PLANT_DELETING_ES = 'Borrando planta de producción'
export const POWER_PLANT_DELETE_SUCCESS_EN = 'Delete power plant successfull'
export const POWER_PLANT_DELETE_SUCCESS_ES = 'Planta de producción borrada exitosamente'
export const POWER_PLANT_DELETE_ERROR_EN = 'Delete power plant error, please view logs'
export const POWER_PLANT_DELETE_ERROR_ES = 'Error borrando planta de producción, por favor revisar el log de aplicación'
export const POWER_PLANT_CREATE_SUCCESS_EN = 'Create power plant successfull'
export const POWER_PLANT_CREATE_SUCCESS_ES = 'Planta de producción creada exitosamente'
export const POWER_PLANT_CREATE_ERROR_EN = 'Create power plant error, please view logs'
export const POWER_PLANT_CREATE_ERROR_ES = 'Error creando planta de producción, por favor revisar el log de aplicación'
export const POWER_PLANT_UPDATE_SUCCESS_EN = 'Update power plant successfull'
export const POWER_PLANT_UPDATE_SUCCESS_ES = 'Planta de producción actualizada exitosamente'
export const POWER_PLANT_UPDATE_ERROR_EN = 'Update power plant error, please view logs'
export const POWER_PLANT_UPDATE_ERROR_ES = 'Error actualizando planta de producción, por favor revisar el log de aplicación'


export const MAGNITUDE_GETTING_EN = 'Fetching magnitudes'
export const MAGNITUDE_GETTING_ES = 'Obteniendo magnitudes'
export const MAGNITUDE_GETTING_ERROR_EN = 'Fetching magnitudes error, please view app logs'
export const MAGNITUDE_GETTING_ERROR_ES = 'Error obteniendo magnitudes, informa al administrador'
export const MAGNITUDE_CREATING_EN = 'Creating magnitude'
export const MAGNITUDE_CREATING_ES = 'Creando magnitud'
export const MAGNITUDE_UPDATING_EN = 'Updating magnitude'
export const MAGNITUDE_UPDATING_ES = 'Actualizando magnitud'
export const MAGNITUDE_DELETING_EN = 'Magnitude deleting'
export const MAGNITUDE_DELETING_ES = 'Borrando magnitud'
export const MAGNITUDE_DELETE_SUCCESS_EN = 'Delete magnitude successfull'
export const MAGNITUDE_DELETE_SUCCESS_ES = 'Magnitud borrada exitosamente'
export const MAGNITUDE_DELETE_ERROR_EN = 'Delete magnitude error, please view logs'
export const MAGNITUDE_DELETE_ERROR_ES = 'Error borrando magnitud, por favor revisar el log de aplicación'
export const MAGNITUDE_CREATE_SUCCESS_EN = 'Create magnitude successfull'
export const MAGNITUDE_CREATE_SUCCESS_ES = 'Magnitud creada exitosamente'
export const MAGNITUDE_CREATE_ERROR_EN = 'Create magnitude error, please view logs'
export const MAGNITUDE_CREATE_ERROR_ES = 'Error creando magnitud, por favor revisar el log de aplicación'
export const MAGNITUDE_UPDATE_SUCCESS_EN = 'Update magnitude successfull'
export const MAGNITUDE_UPDATE_SUCCESS_ES = 'Magnitud actualizada exitosamente'
export const MAGNITUDE_UPDATE_ERROR_EN = 'Update magnitude error, please view logs'
export const MAGNITUDE_UPDATE_ERROR_ES = 'Error actualizando magnitud, por favor revisar el log de aplicación'


export const PRODUCT_GETTING_EN = 'Fetching products'
export const PRODUCT_GETTING_ES = 'Obteniendo productos'
export const PRODUCT_GETTING_ERROR_EN = 'Fetching products error, please view app logs'
export const PRODUCT_GETTING_ERROR_ES = 'Error obteniendo productos, informa al administrador'
export const PRODUCT_CREATING_EN = 'Creating product'
export const PRODUCT_CREATING_ES = 'Creando producto'
export const PRODUCT_UPDATING_EN = 'Updating product'
export const PRODUCT_UPDATING_ES = 'Actualizando producto'
export const PRODUCT_DELETING_EN = 'Product deleting'
export const PRODUCT_DELETING_ES = 'Borrando producto'
export const PRODUCT_DELETE_SUCCESS_EN = 'Delete product successfull'
export const PRODUCT_DELETE_SUCCESS_ES = 'Producto borrada exitosamente'
export const PRODUCT_DELETE_ERROR_EN = 'Delete product error, please view logs'
export const PRODUCT_DELETE_ERROR_ES = 'Error borrando producto, por favor revisar el log de aplicación'
export const PRODUCT_CREATE_SUCCESS_EN = 'Create product successfull'
export const PRODUCT_CREATE_SUCCESS_ES = 'Producto creado exitosamente'
export const PRODUCT_CREATE_ERROR_EN = 'Create product error, please view logs'
export const PRODUCT_CREATE_ERROR_ES = 'Error creando producto, por favor revisar el log de aplicación'
export const PRODUCT_UPDATE_SUCCESS_EN = 'Update product successfull'
export const PRODUCT_UPDATE_SUCCESS_ES = 'Producto actualizado exitosamente'
export const PRODUCT_UPDATE_ERROR_EN = 'Update product error, please view logs'
export const PRODUCT_UPDATE_ERROR_ES = 'Error actualizando producto, por favor revisar el log de aplicación'


export const PUBSUB_INSTALL_SUCCESS_STARTED_EN = 'Install successfull started'
export const PUBSUB_INSTALL_SUCCESS_STARTED_ES = 'Instlación iniciada exitosamente'
export const PUBSUB_INSTALL_START_FAILED_EN = 'Start installation failed, check admin logs'
export const PUBSUB_INSTALL_START_FAILED_ES = 'Inicio de instalación falló, ver logs de aplicación'
export const PUBSUB_INSTALL_MSG_FAILED_EN = 'Incoming installation message failed, check admin logs'
export const PUBSUB_INSTALL_MSG_FAILED_ES = 'Fallo al recibir mensaje entrante, ver logs de aplicación'
export const PUBSUB_INSTALL_END_SUCCESS_EN = 'Installation successsfull ended'
export const PUBSUB_INSTALL_END_SUCCESS_ES = 'Instalación finalizó exitosamente'
export const PUBSUB_INSTALL_END_DISCONN_FAILED_EN = 'Disconnect failed'
export const PUBSUB_INSTALL_END_DISCONN_FAILED_ES = 'Falló la desconexión'


export const INSTALLATION_DISPATCHER_START_SUCESS_EN = 'Install starter successfull triggered'
export const INSTALLATION_DISPATCHER_START_SUCESS_ES = 'Iniciador de instalación disparado exitosamente'
export const INSTALLATION_DISPATCHER_START_ERROR_EN = 'Install starter trigger failed'
export const INSTALLATION_DISPATCHER_START_ERROR_ES = 'Disparador de instaalción falló'


export const IOWATTS_GETTING_EN = 'Fetching IoWatts'
export const IOWATTS_GETTING_ES = 'Obteniendo IoWatts'
export const IOWATTS_GETTING_ERROR_EN = 'Fetching IoWatts error, please view app logs'
export const IOWATTS_GETTING_ERROR_ES = 'Error obteniendo IoWatts, informa al administrador'
export const IOWATTS_CREATING_EN = 'Creating IoWatt'
export const IOWATTS_CREATING_ES = 'Creando IoWatt'
export const IOWATTS_CREATING_SUCCESS_EN = 'Create IoWatt successfull'
export const IOWATTS_CREATING_SUCCESS_ES = 'IoWatt creado exitosamente'
export const IOWATTS_CREATING_ERROR_EN = 'Create IoWatt error, please view app logs'
export const IOWATTS_CREATING_ERROR_ES = 'Error al crear el IoWatt, informa al administrador'
export const IOWATTS_DELETING_EN = 'Deleting IoWatt'
export const IOWATTS_DELETING_ES = 'Borrando IoWatt'
export const IOWATTS_DELETING_SUCCESS_EN = 'Delete IoWatt successfull'
export const IOWATTS_DELETING_SUCCESS_ES = 'IoWatt borrado exitosamente'
export const IOWATTS_DELETING_ERROR_EN = 'Delete IoWatt error, please view app logs'
export const IOWATTS_DELETING_ERROR_ES = 'Error al borrar el IoWatt, informa al administrador'
export const IOWATTS_DELETE_THERE_ARE_PROGRAMS_ERROR_EN = 'There are @count programs using this iowatt, delete programs first'
export const IOWATTS_DELETE_THERE_ARE_PROGRAMS_ERROR_ES = 'Hay @count programas usando este iowatt, primero elimine los programas'
export const DELETE_DEVICES_THERE_ARE_PROGRAMS_ERROR_EN = 'There are @count programs using this gateway, delete programs first'
export const DELETE_DEVICES_THERE_ARE_PROGRAMS_ERROR_ES = 'Hay @count programas usando este gateway, primero elimine los programas'



export const PULL_DEVICES_EN = 'Pulling Devices attached to gateway'
export const PULL_DEVICES_ES = 'Jalando los dispositivos adjuntos al gateway'
export const PULL_DEVICES_ERROR_EN = 'Gateway @gateway Pull devices order failed'
export const PULL_DEVICES_ERROR_ES = 'Falló la orden de rastreo de dispositivos del gateway @gateway'
export const PULL_DEVICES_SUCCESS_EN = 'Pull order was successfull executed with at least @devices devices'
export const PULL_DEVICES_SUCCESS_ES = 'Orden de rastreo ejecutada exitosamente para al manos @devices dispositivos'

export const GET_DEVICES_EN = 'Fetching gateways'
export const GET_DEVICES_ES = 'Obteniendo los gateways'
export const GET_DEVICES_ERROR_EN = 'Get gateways error, please view logs'
export const GET_DEVICES_ERROR_ES = 'Error obteniendo gateways'
export const GET_DEVICES_SUCCESS_EN = 'Fetch gateway was successfull'
export const GET_DEVICES_SUCCESS_ES = 'Recupeción de gateways exitosa'

export const CREATE_DEVICES_EN = 'Creating gateways and devices'
export const CREATE_DEVICES_ES = 'Creando gateways y dispositivos'
export const CREATE_DEVICES_SUCCESS_EN = 'Create gateway successfull'
export const CREATE_DEVICES_SUCCESS_ES = 'Creación exitosa de gateways'
export const CREATE_DEVICES_ERROR_EN = 'Create gateway failed, please view logs'
export const CREATE_DEVICES_ERROR_ES = 'Creación de gateway falló, ver logs'
export const UPDATE_DEVICES_EN = 'Updating gateways and devices'
export const UPDATE_DEVICES_ES = 'Actualizando gateways y dispositivos'
export const UPDATE_DEVICES_SUCCESS_EN = 'Update gateway successfull'
export const UPDATE_DEVICES_SUCCESS_ES = 'Actualización exitosa de gateways'
export const UPDATE_DEVICES_ERROR_EN = 'Update gateway failed, please view logs'
export const UPDATE_DEVICES_ERROR_ES = 'Actualización de gateway falló, ver logs'
export const DELETE_DEVICES_EN = 'Deleting gateways and devices'
export const DELETE_DEVICES_ES = 'Eliminando gateways y dispositivos'
export const DELETE_DEVICES_SUCCESS_EN = 'Delete gateway successfull'
export const DELETE_DEVICES_SUCCESS_ES = 'Eliminado exitoso de gateways'
export const DELETE_DEVICES_ERROR_EN = 'Delete gateway failed, please view logs'
export const DELETE_DEVICES_ERROR_ES = 'Eliminación de gateway falló, ver logs' 

export const LIST_VARIABLES_EN = 'Fetching variables'
export const LIST_VARIABLES_ES = 'Recuperando variables'
export const LIST_VARIABLES_ERROR_EN = 'Fetch variables error, please view logs'
export const LIST_VARIABLES_ERROR_ES = 'Error recuperando variables'
export const LIST_VARIABLES_SUCCESS_EN = 'Ready'
export const LIST_VARIABLES_SUCCESS_ES = 'Listo'
export const GET_VARIABLES_BY_IOWATT_ERROR_EN = 'Fetch variables error, please view logs'
export const GET_VARIABLES_BY_IOWATT_ERROR_ES = 'Error recuperando variables'
export const PROGRAMS_GETTING_EN = 'Fetching programs'
export const PROGRAMS_GETTING_ES = 'Obteniendo programas'
export const PROGRAMS_GETTING_SUCCESS_EN = 'Fetching programs success'
export const PROGRAMS_GETTING_SUCCESS_ES = 'Programas recuperados exitosamente'
export const PROGRAMS_GETTING_ERROR_EN = 'Fetching programs error, please view app logs'
export const PROGRAMS_GETTING_ERROR_ES = 'Error obteniendo programas, informa al administrador'

export const PROGRAMS_CREATING_EN = 'Creating programs'
export const PROGRAMS_CREATING_ES = 'Creando programas'
export const PROGRAMS_UPDATING_EN = 'Updating program'
export const PROGRAMS_UPDATING_ES = 'Actualizando programa'
export const PROGRAMS_DELETING_EN = 'Deleting program'
export const PROGRAMS_DELETING_ES = 'Borrando programa'
export const PROGRAMS_DELETE_SUCCESS_EN = 'Delete program successfull'
export const PROGRAMS_DELETE_SUCCESS_ES = 'Programa borrado exitosamente'
export const PROGRAMS_DELETE_ERROR_EN = 'Delete program error, please view logs'
export const PROGRAMS_DELETE_ERROR_ES = 'Error borrando programas, por favor revisar el log de aplicación'
export const PROGRAMS_CREATE_SUCCESS_EN = 'Create program successfull'
export const PROGRAMS_CREATE_SUCCESS_ES = 'Programa creado exitosamente'
export const PROGRAMS_CREATE_ERROR_EN = 'Create program error, please view logs'
export const PROGRAMS_CREATE_ERROR_ES = 'Error creando programa, por favor revisar el log de aplicación'
export const PROGRAMS_UPDATE_SUCCESS_EN = 'Update program successfull'
export const PROGRAMS_UPDATE_SUCCESS_ES = 'Programa actualizado exitosamente'
export const PROGRAMS_UPDATE_ERROR_EN = 'Update program error, please view logs'
export const PROGRAMS_UPDATE_ERROR_ES = 'Error actualizando programa, por favor revisar el log de aplicación'
export const IOWATT_GET_INFO_EN = 'Fetching Measures information'
export const IOWATT_GET_INFO_ES = 'Recuperando información de mediciones'
export const IOWATT_GET_INFO_ERROR_EN = 'Fetching measures data error, please view app logs'
export const IOWATT_GET_INFO_ERROR_ES = 'Error obteniendo información de mediciones, informa al administrador'

export const COMPONENT_LIST_EN = "Fetching component version list"
export const COMPONENT_LIST_ES = "Obteniedo versiones del componente"
export const COMPONENT_LIST_SUCESS_EN = "Fetch component version list success"
export const COMPONENT_LIST_SUCESS_ES = "Versiones del componente recuperadas exitosamente"
export const COMPONENT_LIST_ERROR_EN = "Fetch component version list error"
export const COMPONENT_LIST_ERROR_ES = "Error al recuperar versiones del componente"

export const CORES_HEALTH_LIST_EN = "Fetching cores health list"
export const CORES_HEALTH_LIST_ES = "Obteniedo salud  de los núcleos"
export const CORES_HEALTH_LIST_SUCCESS_EN = "Fetch cores healthlist success"
export const CORES_HEALTH_LIST_SUCCESS_ES = "Salud de los núcleos recuperada exitosamente"
export const CORES_HEALTH_LIST_ERROR_EN = "Fetch cores health list error"
export const CORES_HEALTH_LIST_ERROR_ES = "Error al recuperar la salud de los núcleos"

export const LIST_CONFIG_EN = "Fecthing initial iowatts physical config"
export const LIST_CONFIG_SUCESS_EN = "Fecth initial iowatt physical config success"
export const LIST_CONFIG_ERROR_EN = "Fecth initial iowatt physical config error"
export const LIST_CONFIG_ES = "Recuperando la configuración física inicial de iowatts"
export const LIST_CONFIG_SUCESS_ES = "Configuración físca de iowatts inicial recurperada exitosamente"
export const LIST_CONFIG_ERROR_ES = "Error al recuperar la configuración físca de iowatts inicial"

export const LIST_FULL_CONFIG_EN = "Fecthing full iowatts physical config"
export const LIST_FULL_CONFIG_SUCESS_EN = "Fecth full iowatt physical config success"
export const LIST_FULL_CONFIG_ERROR_EN = "Fecth full iowatt physical config error"
export const LIST_FULL_CONFIG_ES = "Recuperando la configuración física de iowatts"
export const LIST_FULL_CONFIG_SUCESS_ES = "Configuración físca de iowatts recurperada exitosamente"
export const LIST_FULL_CONFIG_ERROR_ES = "Error al recuperar la configuración físca de iowatts"

export const SET_CONFIG_EN = "Sending iowatt device command order"
export const SET_CONFIG_SUCESS_EN = "Iowatt device command order success"
export const SET_CONFIG_ERROR_EN = "Iowatt device command order error"
export const SET_CONFIG_ES = "Enviando orden de configuración de iowatt"
export const SET_CONFIG_SUCESS_ES = "Orden de configuración de iowatt enviada exitosamente"
export const SET_CONFIG_ERROR_ES = "Envío de orden de configuración de iowatt fallido"

export const GET_DB_CONFIG_EN = "Sending iowatt get local db config order"
export const GET_DB_CONFIG_SUCESS_EN = "Iowatt get local db config order success"
export const GET_DB_CONFIG_ERROR_EN = "Iowatt get local  db config order error"
export const GET_DB_CONFIG_ES = "Enviando orden de recuperación de base de datos local de iowatt"
export const GET_DB_CONFIG_SUCESS_ES = "Orden de recuperación de base de datos local enviada exitosamente"
export const GET_DB_CONFIG_ERROR_ES = "Error enviando orden de recuperación base de datos local de iowatt"

export const DELETE_DB_CONFIG_EN = "Sending iowatt delete local db config order"
export const DELETE_DB_CONFIG_SUCESS_EN = "Iowatt delete local db config order success"
export const DELETE_DB_CONFIG_ERROR_EN = "Iowatt delete local  db config order error"
export const DELETE_DB_CONFIG_ES = "Enviando orden de eliminación de base de datos local de iowatt"
export const DELETE_DB_CONFIG_SUCESS_ES = "Orden de eliminación de base de datos local enviada exitosamente"
export const DELETE_DB_CONFIG_ERROR_ES = "Error enviando orden de eliminación base de datos local de iowatt"

export const CHECK_RESULT_CONFIG_EN = "Checking iowatt physical configuration final result"
export const CHECK_RESULT_CONFIG_SUCESS_EN = "Iowatt physical configuration final result was successfully"
export const CHECK_RESULT_CONFIG_ERROR_EN = "Iowatt physical configuration final error result"
export const CHECK_RESULT_CONFIG_ES = "Chequeando resultados de la configuración final de iwatts"
export const CHECK_RESULT_CONFIG_SUCESS_ES = "Configración física de iwatts finalizada exitosamente"
export const CHECK_RESULT_CONFIG_ERROR_ES = "Configración física de iwatts finalizó con errores"

export const LIST_VERSION_EN = "Fecthing greengrass versions"
export const LIST_VERSION_SUCESS_EN = "Fecth greengrass versions success"
export const LIST_VERSION_ERROR_EN = "Fecth greengrass versions error"
export const LIST_VERSION_ES = "Recuperando versiones de greengrass"
export const LIST_VERSION_SUCESS_ES = "Versiones de greengrass recurperadas exitosamente"
export const LIST_VERSION_ERROR_ES = "Error recuperando versiones de greengrass"

export const IOWATT_INSTALLATION_FINAL_TASK = 'UPDATE_STATUS'

export const IOWATT_INSTALLATION_STEPS = [
    {
        step: 'CREATE_INPUT',
        short: 'Save pubs', 
        explain:{ 
            en: 'Save ByB security keys on remote iowatt gateways',
            es: 'Salva las llaves de seguridad de ByB en el iowatt gateway remoto'
        }
    },
    {
        step: 'INSTALL_PYTHON',
        short: 'Install python',
        explain:{ 
            en: 'Install python 3 on remote iowatt gateway',
            es: 'Instala python 3 en el iowatt gateway remoto '
        }
    },
    {
        step: 'DOWNLOAD_LOCAL_AWS_CLI',
        short: 'Get aws cli',
        explain:{ 
            en: 'Download aws cli installer on iowatt',
            es: 'Descarga el instalador de aws cli en el iowatt'
        }
    },
    {
        step: 'INSTALL_AWS_CLI_ON_IOWATT',
        short: 'Install cli on iowatt',
        explain:{ 
            en: 'Install aws cli on iowatt gateway',
            es: 'Instala aws cli en el gateway de iowatt'
        }
    },
    {
        step: 'DOWNLOAD_LOCAL_GREENGRASS_CORE', 
        short: 'Download aws gg core to iowatt',
        explain:{ 
            en: 'Download aws core to iowatt gateway',
            es: 'Descarga el instalador de aws core al gateway de iowatt'
        }
    },    
    {
        step: 'INSTALL_JAVA_11',
        short: 'Install java 11',
        explain:{ 
            en: 'Install java 11 on iowatt gateway',
            es: 'Instala java 11 en el iowatt gateway'
        }
    },
    {
        step: 'INSTALL_GREENGRASS_NUCLEUS',
        short: 'Install greengrass',
        explain:{ 
            en: 'Install aws greengrass nucleus on iowatt gateway',
            es: 'Instal el núcleo de aws greengrass en el iowatt gateway'
        }
    },
    {
        step: 'SET_GG_FOLDER_PERMISSIONS',
        short: 'Set permissions',
        explain:{ 
            en: 'Set greengrass folder permissions on iowatt',
            es: 'Configura los permisos sobre el direcorio de instalación de greengrass'
        }
    },    
    {
        step: 'DEPLOYMENT_COMPONENTS',
        short: 'Deployment components',
        explain:{ 
            en: 'Deploy iowatt custom components xml, config y  producer',
            es: 'Depliega los recursos xml, config y productot de iowatt'
        }
    },
    {
        step: 'CHECK_DEPLOYMENT_STATUS',
        short: 'Check deployment status',
        explain:{ 
            en: 'Check on core deployment component status post install',
            es: 'Chequea la salud de núcleo una vez instalado los componentes'
        }
    },
    {
        step: 'UPDATE_STATUS',
        short: 'Update final status',
        explain:{ 
            en: 'Update final installation status',
            es: 'Actualiza el estado final de la instalación'
        }
    }
]


