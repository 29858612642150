import * as constants from '../../constants/index'
import * as types from '../types/product'

const initialState = {
    language: 'us',
    loading: false,
    loadingMessage: null,
    alertShow: false,
    alertSeverity: constants.SUCCESS,
    alertMessage: null,
    products: [],
    lines: []
}

export default function(state = initialState, action){
    switch (action.type) {
        case types.SET_LANGUAGE:
            return {
                ...state,
                language: action.payload
            }
            case types.PRODUCT_LIST:
                return {
                    ...state,
                    loading: true,
                    loadingMessage: state.language === constants.LAN_EN ? constants.PRODUCT_GETTING_EN : constants.PRODUCT_GETTING_ES,
                    alertShow: false,
                }        
            case types.PRODUCT_LIST_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    loadingMessage: constants.END_SUCCESS,
                    alertShow: false,
                    alertSeverity: constants.SUCCESS,
                    alertMessage: null,
                    products: action.payload,
                    lines: [...new Set(action.payload.map(product => product.line))]
                }
            case types.PRODUCT_LIST_ERROR:
                return {
                    ...state,
                    loading: false,
                    loadingMessage: constants.END_ERROR,
                    alertShow: true,
                    alertSeverity: constants.ERROR,
                    alertMessage: state.language === constants.LAN_EN ? constants.PRODUCT_GETTING_ERROR_EN : constants.PRODUCT_GETTING_ERROR_ES,
                }
            case types.PRODUCT_CREATE:
                return {
                    ...state,
                    loading: true,
                    loadingMessage: state.language === constants.LAN_EN ? constants.PRODUCT_CREATING_EN: constants.PRODUCT_CREATING_ES,
                    alertShow: false,
                    alertMessage: null,
                }
            case types.PRODUCT_CREATE_SUCCESS:
                const createdProducts = [ ...state.products.filter( product => product.id !== action.payload.id ), action.payload ]
                return {
                    ...state,
                    loading: false,
                    loadingMessage: constants.END_SUCCESS,
                    alertShow: true,
                    alertSeverity: constants.SUCCESS,            
                    alertMessage: state.language === constants.LAN_EN ? constants.PRODUCT_CREATE_SUCCESS_EN : constants.PRODUCT_CREATE_SUCCESS_ES,
                    products: createdProducts,
                    lines: [...new Set(createdProducts.map(product => product.line))]
                }
            case types.PRODUCT_CREATE_ERROR:
                return {
                    ...state,
                    loading: false,
                    loadingMessage: constants.END_ERROR,
                    alertShow: true,
                    alertSeverity: constants.ERROR,
                    alertMessage: state.language === constants.LAN_EN ? constants.PRODUCT_CREATE_ERROR_EN : constants.PRODUCT_CREATE_ERROR_ES,
                }            
            case types.PRODUCT_UPDATE:
                return {
                    ...state,
                    loading: true,
                    loadingMessage: state.language === constants.LAN_EN ? constants.PRODUCT_UPDATING_EN : constants.PRODUCT_UPDATING_ES,
                    alertShow: false,
                    alertMessage: null,
                }
            case types.PRODUCT_UPDATE_SUCCESS:
                const index = state.products.findIndex( product => product.id === action.payload.id )
                const updatedProducts = [ ...state.products.slice( 0, index ), action.payload, ...state.products.slice( index + 1), ]
                return {
                    ...state,
                    loading: false,
                    loadingMessage: constants.END_SUCCESS,
                    alertShow: true,
                    alertSeverity: constants.SUCCESS,            
                    alertMessage: state.language === constants.LAN_EN ? constants.PRODUCT_UPDATE_SUCCESS_EN : constants.PRODUCT_UPDATE_SUCCESS_ES,
                    products: updatedProducts,
                    lines: [...new Set(updatedProducts.map(product => product.line))]
                }
            case types.PRODUCT_UPDATE_ERROR:
                return {
                    ...state,
                    loading: false,
                    loadingMessage: constants.END_ERROR,
                    alertShow: true,
                    alertSeverity: constants.ERROR,
                    alertMessage: state.language === constants.LAN_EN ? constants.PRODUCT_UPDATE_ERROR_EN : constants.PRODUCT_UPDATE_ERROR_ES,
                }    
            case types.PRODUCT_DELETE:
                return {
                    ...state,
                    loading: true,
                    loadingMessage: state.language === constants.LAN_ES ? constants.PRODUCT_DELETING_ES : constants.PRODUCT_DELETING_EN,
                    alertShow: false,
                    alertMessage: null
                }
            case types.PRODUCT_DELETE_SUCCESS:
                const restProducts = state.products.filter( product => !action.payload.includes( product.id ) )
                return {
                    ...state,
                    loading: false,
                    loadingMessage: constants.END_SUCCESS,
                    alertShow: true,
                    alertSeverity: constants.SUCCESS,            
                    alertMessage: state.language === constants.LAN_EN ? constants.PRODUCT_DELETE_SUCCESS_EN : constants.PRODUCT_DELETE_SUCCESS_ES,
                    products: restProducts,
                    lines: [...new Set(restProducts.map(product => product.line))]
                }
            case types.PRODUCT_DELETE_ERROR:
                return {
                    ...state,
                    loading: false,
                    loadingMessage: constants.END_ERROR,
                    alertShow: true,
                    alertSeverity: constants.ERROR,
                    alertMessage: state.language === constants.LAN_EN ? constants.PRODUCT_DELETE_ERROR_EN : constants.PRODUCT_DELETE_ERROR_ES,
                }        
            default:
                return state
    }
}