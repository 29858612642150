
import React from "react";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import ResetPassword from './pages/auth/ResetPassword';
import ForgotPassword from './pages/auth/ForgotPassword';
import ConfirmSignUp from './pages/auth/ConfirmSignUp';
import CustomerSelect from "./pages/customers/CustomerSelect";
import { connect } from "react-redux";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import maTheme from "./theme";
import Routes from "./routes/Routes";
import PrivateRoutes from "./routes/PrivateRoutes";
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';

awsconfig['aws_cloud_logic_custom'] = [
  {
    "name": process.env.REACT_APP_EXEC_API_NAME,
    "endpoint": process.env.REACT_APP_EXEC_API_ENDPOINT,
    "region": process.env.REACT_APP_REGION
  }
]
Amplify.configure(awsconfig);


function App({ theme }) {
  
  return (
    <React.Fragment>
      <StylesProvider injectFirst>
          <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
            <ThemeProvider theme={maTheme[theme.currentTheme]}>
              <Router>
                <Switch>
                  <Route exact path="/" component={SignIn} />
                  <Route exact path="/auth/sign-up" component={SignUp} />
                  <Route exact path="/auth/confirm-sign-up" component={ ConfirmSignUp} />
                  <Route exact path="/auth/reset-password" component={ResetPassword} />
                  <Route exact path="/auth/forgot-password" component={ForgotPassword} />
                  <PrivateRoutes exact path="/customer-select" component={CustomerSelect} />
                  <PrivateRoutes exact path="/home" component={Routes} />
                  <PrivateRoutes exact path="/iowatt/create" component={Routes} />
                  <PrivateRoutes exact path="/iowatt/program" component={Routes} />
                  <PrivateRoutes exact path="/settings/powerplant" component={Routes} />
                  <PrivateRoutes exact path="/settings/gateways" component={Routes} />
                  <PrivateRoutes exact path="/gateway/list" component={Routes} />
                </Switch>
              </Router>
            </ThemeProvider>
          </MuiThemeProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default connect(store => ({ theme: store.themeReducer }))(App);

