import * as constants from '../../constants/index'
import * as types from '../types/powerPlants'

const initialState = {
    language: 'us',
    loading: false,
    loadingMessage: null,
    alertShow: false,
    alertSeverity: constants.SUCCESS,
    alertMessage: null,
    powerPlants: []
}

export default function(state = initialState, action){
    switch (action.type) {
        case types.SET_LANGUAGE:
            return {
                ...state,
                language: action.payload
            }        
        case types.POWER_PLANT_LIST:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.POWER_PLANT_GETTING_EN : constants.POWER_PLANT_GETTING_ES,
                alertShow: false,
            }        
        case types.POWER_PLANT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: false,
                alertSeverity: constants.SUCCESS,
                alertMessage: null,
                powerPlants: action.payload
            }
        case types.POWER_PLANT_LIST_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.POWER_PLANT_GETTING_ERROR_EN : constants.POWER_PLANT_GETTING_ERROR_ES,
            }
        case types.POWER_PLANT_CREATE:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.POWER_PLANT_CREATING_EN: constants.POWER_PLANT_CREATING_ES,
                alertShow: false,
                alertMessage: null,
            }
        case types.POWER_PLANT_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,            
                alertMessage: state.language === constants.LAN_EN ? constants.POWER_PLANT_CREATE_SUCCESS_EN : constants.POWER_PLANT_CREATE_SUCCESS_ES,
                powerPlants: [ ...state.powerPlants.filter( powerPlant => powerPlant.id !== action.payload.id ), action.payload ],
            }
        case types.POWER_PLANT_CREATE_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.POWER_PLANT_CREATE_ERROR_EN : constants.POWER_PLANT_CREATE_ERROR_ES,
            }
        case types.POWER_PLANT_UPDATE:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.POWER_PLANT_UPDATING_EN : constants.POWER_PLANT_UPDATING_ES,
                alertShow: false,
                alertMessage: null,
            }
        case types.POWER_PLANT_UPDATE_SUCCESS:
            const index = state.powerPlants.findIndex( powerPlant => powerPlant.id === action.payload.id )
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,            
                alertMessage: state.language === constants.LAN_EN ? constants.POWER_PLANT_UPDATE_SUCCESS_EN : constants.POWER_PLANT_UPDATE_SUCCESS_ES,
                powerPlants: [ ...state.powerPlants.slice( 0, index ), action.payload, ...state.powerPlants.slice( index + 1), ],
            }
        case types.POWER_PLANT_UPDATE_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.POWER_PLANT_UPDATE_ERROR_EN : constants.POWER_PLANT_UPDATE_ERROR_ES,
            }
        case types.POWER_PLANT_DELETE:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_ES ? constants.POWER_PLANT_DELETING_ES : constants.POWER_PLANT_DELETING_EN,
                alertShow: false,
                alertMessage: null
            }
        case types.POWER_PLANT_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,            
                alertMessage: state.language === constants.LAN_EN ? constants.POWER_PLANT_DELETE_SUCCESS_EN : constants.POWER_PLANT_DELETE_SUCCESS_ES,
                powerPlants: state.powerPlants.filter( powerPlant => !action.payload.includes( powerPlant.id ) ),
            }
        case types.POWER_PLANT_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.POWER_PLANT_DELETE_ERROR_EN : constants.POWER_PLANT_DELETE_ERROR_ES,
            }
        default:
            return state;
    }
}