import * as types from '../types/auth';

const initialState = {
    username: null,
    authenticated: false,
    confirmed: false,
    message: null,
    loading: false,
    status: null,
    resendStatus: null,
    forgotStatus: null,
    apiResponse: null,
    sub: null,
    groups: [],
    country: null,
    company: null,
    customerId: null,
    newFlag: false,
    missingMagnitudes: false,
    root: null,
    actionType: null,
    customersCount: 0,
    pages : [],
    versions : []
}

export default function(state = initialState, action){
    switch (action.type) {
        case types.LOGOUT_USER:
            return {
                ...state,
                loading: true,
                status: null
            }
        case types.LOGOUT_USER_SUCCESS:
            return {
                ...state,
                username: null,
                authenticated: false,
                confirmed: false,
                message: null,
                loading: false,
                status: null,
                resendStatus: null,
                forgotStatus: null,
                apiResponse: null,
                sub: null,
                groups: [],
                country: null,
                company: null,
                customerId: null,
                newFlag: false,
                root: null,
                actionType: null,
                customersCount: 0    
            }
        case types.LOGOUT_USER_ERROR:
            return {
                ...state,
                loading: false,
                status: false
            }
        case types.LOGIN_USER:
            return {
                ...state,
                loading: true,
                status: null,
                actionType: types.LOGIN_USER
            }
        case types.LOGIN_SELECT_CUSTOMER:
            return {
                ...state,
                customerId: action.payload.id,
                pages : action.payload.pages,
                company: action.payload.name,
                country: action.payload.country
            }
        case types.LOGIN_SET_IDENTITY_ID:
            return {
                ...state,
                versions : action.payload.versions,
                identityId: action.payload.identityId
            }
        case types.LOGIN_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                message: 'login successfully',
                status: 'success',
                authenticated: true,
                customerId: action.payload.customerId,
                pages : action.payload.pages,
                versions : action.payload.versions,
                sub: action.payload.signInUserSession.accessToken.payload.sub,
                groups: action.payload.iowattGroups,
                country: action.payload.attributes["custom:country"],
                company: action.payload.attributes["custom:company"],
                phone: action.payload.attributes["phone_number"],
                email: action.payload.attributes["email"],
                username: action.payload.attributes["email"],
                newFlag: action.payload.newFlag,
                missingMagnitudes: action.payload.missingMagnitudes,
                root: action.payload.newRoot,
                customersCount: action.payload.customersCount,
                actionType: types.LOGIN_USER_SUCCESS
            }
        case types.LOGIN_USER_ERROR:
            return {
                ...state,
                loading: false,
                message: 'Invalid login or password',
                status: 'error',
                authenticated: false,
                actionType: types.LOGIN_USER_ERROR
            }
        case types.CREATE_USER:
            return {
                ...state,
                loading: true,
                status: null
            }
        case types.CREATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                message: null,
                status: null,
                username: action.payload
            }
        case types.CREATE_USER_ERROR:
            return {
                ...state,
                loading: false,
                message: 'Create user failed',
                status: 'error',
            }
        case types.CONFIRM_USER:
            return {
                ...state,
                loading: true,
                status: null,
                resendStatus: null
            }
        case types.CONFIRM_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                message: 'Confirm user successfully',
                status: 'success',
            }
        case types.CONFIRM_USER_ERROR:
            return {
                ...state,
                loading: false,
                message: 'Confirm user failed',
                status: 'error',
            }
        case types.RESEND_CODE:
            return {
                ...state,
                loading: true,
                status: null,
                resendStatus: null,
            }
        case types.RESEND_CODE_SUCCESS:
            return {
                ...state,
                loading: false,
                message: 'Resend code successfully',
                resendStatus: 'success',
            }
        case types.RESEND_CODE_ERROR:
            return {
                ...state,
                loading: false,
                message: 'Resend code failed',
                resendStatus: 'error',
            }
        case types.FORGOT_PASSWORD:
            return {
                ...state,
                loading: true,
                status: null,
                forgotStatus: null,
            }
        case types.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                message: 'Send confirmation code to email successfully',
                forgotStatus: 'success',
                username: action.payload,
            }
        case types.FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                message: 'Send confirmation code to email failed',
                forgotStatus: 'error',
            }
        case types.FORGOT_PASSWORD_SUBMIT:
            return {
                ...state,
                loading: true,
                status: null,
            }
        case types.FORGOT_PASSWORD_SUBMIT_SUCCESS:
            return {
                ...state,
                loading: false,
                message: 'New password successfully changed',
                status: 'success',
            }
        case types.FORGOT_PASSWORD_SUBMIT_ERROR:
            return {
                ...state,
                loading: false,
                message: 'Change new password failed',
                status: 'error',
            }
        default:
            return state;
    }
}
