import * as types from '../types/language'

export function changeLanguageAction(language) {
    return async (dispath) => {
        dispath( changeLanguage(language) );
    }    
}

const changeLanguage = language => ({
    type: types.CHANGE_LANGUAGE,
    payload: language
});