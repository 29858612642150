import * as types from '../types/clock';
import { delay } from '../../helpers'

export function startClockAction( interval ) {
    return async (dispath) => {
        dispath( startClock() );
        try{
            while(1){
                await delay( interval )
                dispath( startClockSuccess() );
            }            
        } catch (error) {
            console.log(error);
        }
    }    
}

export const SET_START_CLOCK = ''
export const EMMIT_INTERVAL_HAPPENS = ''

const startClock = () => ({
    type: types.SET_START_CLOCK,
    payload: true
});

const startClockSuccess = () => ({
    type: types.EMMIT_INTERVAL_HAPPENS,
    payload: true
});