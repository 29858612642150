import React from 'react';
import { Alert } from '@material-ui/lab';
import { Typography } from "@material-ui/core";

const ByBAlert = ({ show, type, severity, message}) => {
    return ( 
        <>
            { 
                show && type === 'title' ? <Alert mb={4} severity={ severity } align="center">{message}</Alert>
                    : null
            }   
            {
                show && type === 'field' ?  <Typography component="h2" variant="body1" color={ severity } >{message}</Typography>
                : null
            }
        </>
    );
}
 
export default ByBAlert;