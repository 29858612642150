import * as constants from '../../constants/index'
import * as types from '../types/customers'

const initialState = {
    language: 'us',
    loading: false,
    loadingMessage: null,
    alertShow: false,
    alertSeverity: constants.SUCCESS,
    alertMessage: null,
    customers: []
}

export default function(state = initialState, action){
    switch (action.type) {
        case types.SET_LANGUAGE:
            return {
                ...state,
                language: action.payload
            }
        case types.LIST_CUSTOMERS:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.CUSTOMERS_GETTING_EN : constants.CUSTOMERS_GETTING_ES,
                alertShow: false,
            }
        case types.LIST_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: false,
                alertSeverity: constants.SUCCESS,
                alertMessage: null,
                customers: action.payload
            }
        case types.LIST_CUSTOMERS_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.CUSTOMERS_GETTING_ERROR_EN : constants.CUSTOMERS_GETTING_ERROR_ES,
            }        
        case types.CREATE_CUSTOMER_PERMISSIONS:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.CUSTOMERS_CREATING_PERMISSIONS_EN : constants.CUSTOMERS_CREATING_PERMISSIONS_ES,
                alertShow: false,
                alertMessage: null,
            }
        case types.CREATE_CUSTOMER_PERMISSIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,            
                alertMessage: state.language === constants.LAN_EN ? constants.CUSTOMERS_CREATE_PERMISSIONS_SUCCESS_EN : constants.CUSTOMERS_CREATE_PERMISSIONS_SUCCESS_ES,
            }
        case types.CREATE_CUSTOMER_PERMISSIONS_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.CUSTOMERS_CREATE_PERMISSIONS_ERROR_EN : constants.CUSTOMERS_CREATE_PERMISSIONS_ERROR_ES
            }
            case types.CREATE_CUSTOMER_MAGNITUDES:
                return {
                    ...state,
                    loading: true,
                    loadingMessage: state.language === constants.LAN_EN ? constants.CREATE_CUSTOMER_MAGNITUDES_EN : constants.CREATE_CUSTOMER_MAGNITUDES_ES,
                    alertShow: false,
                    alertMessage: null,
                }
            case types.CREATE_CUSTOMER_MAGNITUDES_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    loadingMessage: constants.END_SUCCESS,
                    alertShow: true,
                    alertSeverity: constants.SUCCESS,            
                    alertMessage: state.language === constants.LAN_EN ? constants.CREATE_CUSTOMER_MAGNITUDES_SUCCESS_EN : constants.CREATE_CUSTOMER_MAGNITUDES_SUCCESS_ES,
                }
            case types.CREATE_CUSTOMER_MAGNITUDES_ERROR:
                return {
                    ...state,
                    loading: false,
                    loadingMessage: constants.END_ERROR,
                    alertShow: true,
                    alertSeverity: constants.ERROR,
                    alertMessage: state.language === constants.LAN_EN ? constants.CREATE_CUSTOMER_MAGNITUDES_ERROR_EN : constants.CREATE_CUSTOMER_MAGNITUDES_ERROR_ES
                }
        default:
            return state;
    }    
}