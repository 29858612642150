export const SET_LANGUAGE = 'SET_LANGUAGE'

export const DRIVER_LIST = 'DRIVERS_LIST'
export const DRIVERS_LOADING_IMAGE = 'DRIVERS_LOADING_IMAGE'
export const DRIVERS_LIST_SUCCESS = 'DRIVERS_LIST_SUCCESS'
export const DRIVERS_LIST_ERROR = 'DRIVERS_LIST_ERROR'
export const DRIVERS_CREATE = 'DRIVERS_CREATE'
export const DRIVERS_CREATE_SUCCESS = 'DRIVERS_CREATE_SUCCESS'
export const DRIVERS_CREATE_ERROR = 'DRIVERS_CREATE_ERROR'
export const DRIVERS_UPDATE = 'DRIVERS_UPDATE'
export const DRIVERS_UPDATE_SUCCESS = 'DRIVERS_UPDATE_SUCCESS'
export const DRIVERS_UPDATE_ERROR = 'DRIVERS_UPDATE_ERROR'
export const DRIVERS_DELETE = 'DRIVERS_DELETE'
export const DRIVERS_DELETE_SUCCESS = 'DRIVERS_DELETE_SUCCESS'
export const DRIVERS_DELETE_ERROR = 'DRIVERS_DELETE_ERROR'
