/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      slug
      name
      root
      country
      clients {
        items {
          name
          type
          country
          phone
          id
          createdAt
          updatedAt
          customerClientsId
          owner
        }
        nextToken
      }
      pages {
        items {
          role
          page
          head
          children
          section
          permissions
          id
          customerPagesId
          owner
        }
        nextToken
      }
      calendars {
        items {
          name
          type
          schedule
          hollidays
          beginHour
          endHour
          id
          createdAt
          updatedAt
          customerCalendarsId
          owner
        }
        nextToken
      }
      hollidays {
        items {
          name
          schedule
          id
          createdAt
          updatedAt
          customerHollidaysId
          owner
        }
        nextToken
      }
      products {
        items {
          name
          line
          description
          price
          fees
          id
          createdAt
          updatedAt
          customerProductsId
          owner
        }
        nextToken
      }
      powerPlants {
        items {
          name
          googlePlaceId
          country
          formattedAddress
          addressComponents
          lat
          lng
          description
          id
          createdAt
          updatedAt
          customerPowerPlantsId
          owner
        }
        nextToken
      }
      iowatts {
        items {
          name
          powerPlant
          hardware
          core
          coreGroup
          ip
          proxy
          status
          installStatus
          secretVersion
          streamVersion
          cliVersion
          nucleusVersion
          ownerStreamsVersion
          ownerDevicesVersion
          ownerConfigsVersion
          createdAt
          id
          updatedAt
          customerIowattsId
          owner
        }
        nextToken
      }
      gateways {
        items {
          name
          ip
          driverType
          type
          dataLake
          status
          timeOffset
          id
          createdAt
          updatedAt
          customerGatewaysId
          ioWattGatewaysId
          owner
        }
        nextToken
      }
      devices {
        items {
          type
          typeDescription
          description
          serialNumber
          master
          status
          deviceId
          id
          createdAt
          updatedAt
          customerDevicesId
          gatewaysDevicesId
          owner
        }
        nextToken
      }
      programs {
        items {
          name
          goal
          goalUnits
          fees
          currency
          startTime
          endTime
          products
          customers
          variables
          gateways
          devices
          projections
          description
          id
          createdAt
          updatedAt
          customerProgramsId
          owner
        }
        nextToken
      }
      configs {
        items {
          iowattId
          code
          orderType
          message
          description
          config
          createdAt
          updatedAt
          customerConfigsId
          owner
        }
        nextToken
      }
      versions {
        items {
          id
          coreGroup
          cli
          nucleus
          secret
          stream
          createdAt
          updatedAt
          customerVersionsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      slug
      name
      root
      country
      clients {
        items {
          name
          type
          country
          phone
          id
          createdAt
          updatedAt
          customerClientsId
          owner
        }
        nextToken
      }
      pages {
        items {
          role
          page
          head
          children
          section
          permissions
          id
          customerPagesId
          owner
        }
        nextToken
      }
      calendars {
        items {
          name
          type
          schedule
          hollidays
          beginHour
          endHour
          id
          createdAt
          updatedAt
          customerCalendarsId
          owner
        }
        nextToken
      }
      hollidays {
        items {
          name
          schedule
          id
          createdAt
          updatedAt
          customerHollidaysId
          owner
        }
        nextToken
      }
      products {
        items {
          name
          line
          description
          price
          fees
          id
          createdAt
          updatedAt
          customerProductsId
          owner
        }
        nextToken
      }
      powerPlants {
        items {
          name
          googlePlaceId
          country
          formattedAddress
          addressComponents
          lat
          lng
          description
          id
          createdAt
          updatedAt
          customerPowerPlantsId
          owner
        }
        nextToken
      }
      iowatts {
        items {
          name
          powerPlant
          hardware
          core
          coreGroup
          ip
          proxy
          status
          installStatus
          secretVersion
          streamVersion
          cliVersion
          nucleusVersion
          ownerStreamsVersion
          ownerDevicesVersion
          ownerConfigsVersion
          createdAt
          id
          updatedAt
          customerIowattsId
          owner
        }
        nextToken
      }
      gateways {
        items {
          name
          ip
          driverType
          type
          dataLake
          status
          timeOffset
          id
          createdAt
          updatedAt
          customerGatewaysId
          ioWattGatewaysId
          owner
        }
        nextToken
      }
      devices {
        items {
          type
          typeDescription
          description
          serialNumber
          master
          status
          deviceId
          id
          createdAt
          updatedAt
          customerDevicesId
          gatewaysDevicesId
          owner
        }
        nextToken
      }
      programs {
        items {
          name
          goal
          goalUnits
          fees
          currency
          startTime
          endTime
          products
          customers
          variables
          gateways
          devices
          projections
          description
          id
          createdAt
          updatedAt
          customerProgramsId
          owner
        }
        nextToken
      }
      configs {
        items {
          iowattId
          code
          orderType
          message
          description
          config
          createdAt
          updatedAt
          customerConfigsId
          owner
        }
        nextToken
      }
      versions {
        items {
          id
          coreGroup
          cli
          nucleus
          secret
          stream
          createdAt
          updatedAt
          customerVersionsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      slug
      name
      root
      country
      clients {
        items {
          name
          type
          country
          phone
          id
          createdAt
          updatedAt
          customerClientsId
          owner
        }
        nextToken
      }
      pages {
        items {
          role
          page
          head
          children
          section
          permissions
          id
          customerPagesId
          owner
        }
        nextToken
      }
      calendars {
        items {
          name
          type
          schedule
          hollidays
          beginHour
          endHour
          id
          createdAt
          updatedAt
          customerCalendarsId
          owner
        }
        nextToken
      }
      hollidays {
        items {
          name
          schedule
          id
          createdAt
          updatedAt
          customerHollidaysId
          owner
        }
        nextToken
      }
      products {
        items {
          name
          line
          description
          price
          fees
          id
          createdAt
          updatedAt
          customerProductsId
          owner
        }
        nextToken
      }
      powerPlants {
        items {
          name
          googlePlaceId
          country
          formattedAddress
          addressComponents
          lat
          lng
          description
          id
          createdAt
          updatedAt
          customerPowerPlantsId
          owner
        }
        nextToken
      }
      iowatts {
        items {
          name
          powerPlant
          hardware
          core
          coreGroup
          ip
          proxy
          status
          installStatus
          secretVersion
          streamVersion
          cliVersion
          nucleusVersion
          ownerStreamsVersion
          ownerDevicesVersion
          ownerConfigsVersion
          createdAt
          id
          updatedAt
          customerIowattsId
          owner
        }
        nextToken
      }
      gateways {
        items {
          name
          ip
          driverType
          type
          dataLake
          status
          timeOffset
          id
          createdAt
          updatedAt
          customerGatewaysId
          ioWattGatewaysId
          owner
        }
        nextToken
      }
      devices {
        items {
          type
          typeDescription
          description
          serialNumber
          master
          status
          deviceId
          id
          createdAt
          updatedAt
          customerDevicesId
          gatewaysDevicesId
          owner
        }
        nextToken
      }
      programs {
        items {
          name
          goal
          goalUnits
          fees
          currency
          startTime
          endTime
          products
          customers
          variables
          gateways
          devices
          projections
          description
          id
          createdAt
          updatedAt
          customerProgramsId
          owner
        }
        nextToken
      }
      configs {
        items {
          iowattId
          code
          orderType
          message
          description
          config
          createdAt
          updatedAt
          customerConfigsId
          owner
        }
        nextToken
      }
      versions {
        items {
          id
          coreGroup
          cli
          nucleus
          secret
          stream
          createdAt
          updatedAt
          customerVersionsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createVersion = /* GraphQL */ `
  mutation CreateVersion(
    $input: CreateVersionInput!
    $condition: ModelVersionConditionInput
  ) {
    createVersion(input: $input, condition: $condition) {
      id
      coreGroup
      cli
      nucleus
      secret
      stream
      createdAt
      updatedAt
      customerVersionsId
      owner
    }
  }
`;
export const updateVersion = /* GraphQL */ `
  mutation UpdateVersion(
    $input: UpdateVersionInput!
    $condition: ModelVersionConditionInput
  ) {
    updateVersion(input: $input, condition: $condition) {
      id
      coreGroup
      cli
      nucleus
      secret
      stream
      createdAt
      updatedAt
      customerVersionsId
      owner
    }
  }
`;
export const deleteVersion = /* GraphQL */ `
  mutation DeleteVersion(
    $input: DeleteVersionInput!
    $condition: ModelVersionConditionInput
  ) {
    deleteVersion(input: $input, condition: $condition) {
      id
      coreGroup
      cli
      nucleus
      secret
      stream
      createdAt
      updatedAt
      customerVersionsId
      owner
    }
  }
`;
export const createConfig = /* GraphQL */ `
  mutation CreateConfig(
    $input: CreateConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    createConfig(input: $input, condition: $condition) {
      iowattId
      code
      orderType
      message
      description
      config
      createdAt
      updatedAt
      customerConfigsId
      owner
    }
  }
`;
export const updateConfig = /* GraphQL */ `
  mutation UpdateConfig(
    $input: UpdateConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    updateConfig(input: $input, condition: $condition) {
      iowattId
      code
      orderType
      message
      description
      config
      createdAt
      updatedAt
      customerConfigsId
      owner
    }
  }
`;
export const deleteConfig = /* GraphQL */ `
  mutation DeleteConfig(
    $input: DeleteConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    deleteConfig(input: $input, condition: $condition) {
      iowattId
      code
      orderType
      message
      description
      config
      createdAt
      updatedAt
      customerConfigsId
      owner
    }
  }
`;
export const createPage = /* GraphQL */ `
  mutation CreatePage(
    $input: CreatePageInput!
    $condition: ModelPageConditionInput
  ) {
    createPage(input: $input, condition: $condition) {
      role
      page
      head
      children
      section
      permissions
      id
      customerPagesId
      owner
    }
  }
`;
export const updatePage = /* GraphQL */ `
  mutation UpdatePage(
    $input: UpdatePageInput!
    $condition: ModelPageConditionInput
  ) {
    updatePage(input: $input, condition: $condition) {
      role
      page
      head
      children
      section
      permissions
      id
      customerPagesId
      owner
    }
  }
`;
export const deletePage = /* GraphQL */ `
  mutation DeletePage(
    $input: DeletePageInput!
    $condition: ModelPageConditionInput
  ) {
    deletePage(input: $input, condition: $condition) {
      role
      page
      head
      children
      section
      permissions
      id
      customerPagesId
      owner
    }
  }
`;
export const createProgram = /* GraphQL */ `
  mutation CreateProgram(
    $input: CreateProgramInput!
    $condition: ModelProgramConditionInput
  ) {
    createProgram(input: $input, condition: $condition) {
      name
      goal
      goalUnits
      fees
      currency
      startTime
      endTime
      products
      customers
      variables
      gateways
      devices
      iowatts {
        items {
          id
          programID
          ioWattID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      progCalendar {
        name
        type
        schedule
      }
      projections
      description
      id
      createdAt
      updatedAt
      customerProgramsId
      owner
    }
  }
`;
export const updateProgram = /* GraphQL */ `
  mutation UpdateProgram(
    $input: UpdateProgramInput!
    $condition: ModelProgramConditionInput
  ) {
    updateProgram(input: $input, condition: $condition) {
      name
      goal
      goalUnits
      fees
      currency
      startTime
      endTime
      products
      customers
      variables
      gateways
      devices
      iowatts {
        items {
          id
          programID
          ioWattID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      progCalendar {
        name
        type
        schedule
      }
      projections
      description
      id
      createdAt
      updatedAt
      customerProgramsId
      owner
    }
  }
`;
export const deleteProgram = /* GraphQL */ `
  mutation DeleteProgram(
    $input: DeleteProgramInput!
    $condition: ModelProgramConditionInput
  ) {
    deleteProgram(input: $input, condition: $condition) {
      name
      goal
      goalUnits
      fees
      currency
      startTime
      endTime
      products
      customers
      variables
      gateways
      devices
      iowatts {
        items {
          id
          programID
          ioWattID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      progCalendar {
        name
        type
        schedule
      }
      projections
      description
      id
      createdAt
      updatedAt
      customerProgramsId
      owner
    }
  }
`;
export const createIoWatt = /* GraphQL */ `
  mutation CreateIoWatt(
    $input: CreateIoWattInput!
    $condition: ModelIoWattConditionInput
  ) {
    createIoWatt(input: $input, condition: $condition) {
      name
      powerPlant
      hardware
      core
      coreGroup
      ip
      proxy
      status
      installStatus
      secretVersion
      streamVersion
      cliVersion
      nucleusVersion
      ownerStreamsVersion
      ownerDevicesVersion
      ownerConfigsVersion
      installCommands {
        items {
          executionId
          user
          step
          status
          instance
          stdout
          stderr
          error
          id
          createdAt
          updatedAt
          ioWattInstallCommandsId
          owner
        }
        nextToken
      }
      gateways {
        items {
          name
          ip
          driverType
          type
          dataLake
          status
          timeOffset
          id
          createdAt
          updatedAt
          customerGatewaysId
          ioWattGatewaysId
          owner
        }
        nextToken
      }
      programs {
        items {
          id
          programID
          ioWattID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      id
      updatedAt
      customerIowattsId
      owner
    }
  }
`;
export const updateIoWatt = /* GraphQL */ `
  mutation UpdateIoWatt(
    $input: UpdateIoWattInput!
    $condition: ModelIoWattConditionInput
  ) {
    updateIoWatt(input: $input, condition: $condition) {
      name
      powerPlant
      hardware
      core
      coreGroup
      ip
      proxy
      status
      installStatus
      secretVersion
      streamVersion
      cliVersion
      nucleusVersion
      ownerStreamsVersion
      ownerDevicesVersion
      ownerConfigsVersion
      installCommands {
        items {
          executionId
          user
          step
          status
          instance
          stdout
          stderr
          error
          id
          createdAt
          updatedAt
          ioWattInstallCommandsId
          owner
        }
        nextToken
      }
      gateways {
        items {
          name
          ip
          driverType
          type
          dataLake
          status
          timeOffset
          id
          createdAt
          updatedAt
          customerGatewaysId
          ioWattGatewaysId
          owner
        }
        nextToken
      }
      programs {
        items {
          id
          programID
          ioWattID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      id
      updatedAt
      customerIowattsId
      owner
    }
  }
`;
export const deleteIoWatt = /* GraphQL */ `
  mutation DeleteIoWatt(
    $input: DeleteIoWattInput!
    $condition: ModelIoWattConditionInput
  ) {
    deleteIoWatt(input: $input, condition: $condition) {
      name
      powerPlant
      hardware
      core
      coreGroup
      ip
      proxy
      status
      installStatus
      secretVersion
      streamVersion
      cliVersion
      nucleusVersion
      ownerStreamsVersion
      ownerDevicesVersion
      ownerConfigsVersion
      installCommands {
        items {
          executionId
          user
          step
          status
          instance
          stdout
          stderr
          error
          id
          createdAt
          updatedAt
          ioWattInstallCommandsId
          owner
        }
        nextToken
      }
      gateways {
        items {
          name
          ip
          driverType
          type
          dataLake
          status
          timeOffset
          id
          createdAt
          updatedAt
          customerGatewaysId
          ioWattGatewaysId
          owner
        }
        nextToken
      }
      programs {
        items {
          id
          programID
          ioWattID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      id
      updatedAt
      customerIowattsId
      owner
    }
  }
`;
export const createGateways = /* GraphQL */ `
  mutation CreateGateways(
    $input: CreateGatewaysInput!
    $condition: ModelGatewaysConditionInput
  ) {
    createGateways(input: $input, condition: $condition) {
      name
      ip
      driverType
      type
      dataLake
      status
      timeOffset
      iowatt {
        name
        powerPlant
        hardware
        core
        coreGroup
        ip
        proxy
        status
        installStatus
        secretVersion
        streamVersion
        cliVersion
        nucleusVersion
        ownerStreamsVersion
        ownerDevicesVersion
        ownerConfigsVersion
        installCommands {
          nextToken
        }
        gateways {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        id
        updatedAt
        customerIowattsId
        owner
      }
      devices {
        items {
          type
          typeDescription
          description
          serialNumber
          master
          status
          deviceId
          id
          createdAt
          updatedAt
          customerDevicesId
          gatewaysDevicesId
          owner
        }
        nextToken
      }
      variables {
        items {
          varId
          idEx
          title
          hasValue
          hasLogger
          hasForced
          sampleMode
          measureUnits
          unitsFactor
          decimals
          varType
          status
          deviceKey
          iowattKey
          id
          createdAt
          updatedAt
          gatewaysVariablesId
          devicesVariablesId
          owner
        }
        nextToken
      }
      id
      createdAt
      updatedAt
      customerGatewaysId
      ioWattGatewaysId
      owner
    }
  }
`;
export const updateGateways = /* GraphQL */ `
  mutation UpdateGateways(
    $input: UpdateGatewaysInput!
    $condition: ModelGatewaysConditionInput
  ) {
    updateGateways(input: $input, condition: $condition) {
      name
      ip
      driverType
      type
      dataLake
      status
      timeOffset
      iowatt {
        name
        powerPlant
        hardware
        core
        coreGroup
        ip
        proxy
        status
        installStatus
        secretVersion
        streamVersion
        cliVersion
        nucleusVersion
        ownerStreamsVersion
        ownerDevicesVersion
        ownerConfigsVersion
        installCommands {
          nextToken
        }
        gateways {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        id
        updatedAt
        customerIowattsId
        owner
      }
      devices {
        items {
          type
          typeDescription
          description
          serialNumber
          master
          status
          deviceId
          id
          createdAt
          updatedAt
          customerDevicesId
          gatewaysDevicesId
          owner
        }
        nextToken
      }
      variables {
        items {
          varId
          idEx
          title
          hasValue
          hasLogger
          hasForced
          sampleMode
          measureUnits
          unitsFactor
          decimals
          varType
          status
          deviceKey
          iowattKey
          id
          createdAt
          updatedAt
          gatewaysVariablesId
          devicesVariablesId
          owner
        }
        nextToken
      }
      id
      createdAt
      updatedAt
      customerGatewaysId
      ioWattGatewaysId
      owner
    }
  }
`;
export const deleteGateways = /* GraphQL */ `
  mutation DeleteGateways(
    $input: DeleteGatewaysInput!
    $condition: ModelGatewaysConditionInput
  ) {
    deleteGateways(input: $input, condition: $condition) {
      name
      ip
      driverType
      type
      dataLake
      status
      timeOffset
      iowatt {
        name
        powerPlant
        hardware
        core
        coreGroup
        ip
        proxy
        status
        installStatus
        secretVersion
        streamVersion
        cliVersion
        nucleusVersion
        ownerStreamsVersion
        ownerDevicesVersion
        ownerConfigsVersion
        installCommands {
          nextToken
        }
        gateways {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        id
        updatedAt
        customerIowattsId
        owner
      }
      devices {
        items {
          type
          typeDescription
          description
          serialNumber
          master
          status
          deviceId
          id
          createdAt
          updatedAt
          customerDevicesId
          gatewaysDevicesId
          owner
        }
        nextToken
      }
      variables {
        items {
          varId
          idEx
          title
          hasValue
          hasLogger
          hasForced
          sampleMode
          measureUnits
          unitsFactor
          decimals
          varType
          status
          deviceKey
          iowattKey
          id
          createdAt
          updatedAt
          gatewaysVariablesId
          devicesVariablesId
          owner
        }
        nextToken
      }
      id
      createdAt
      updatedAt
      customerGatewaysId
      ioWattGatewaysId
      owner
    }
  }
`;
export const createDevices = /* GraphQL */ `
  mutation CreateDevices(
    $input: CreateDevicesInput!
    $condition: ModelDevicesConditionInput
  ) {
    createDevices(input: $input, condition: $condition) {
      type
      typeDescription
      description
      serialNumber
      master
      status
      deviceId
      variables {
        items {
          varId
          idEx
          title
          hasValue
          hasLogger
          hasForced
          sampleMode
          measureUnits
          unitsFactor
          decimals
          varType
          status
          deviceKey
          iowattKey
          id
          createdAt
          updatedAt
          gatewaysVariablesId
          devicesVariablesId
          owner
        }
        nextToken
      }
      id
      createdAt
      updatedAt
      customerDevicesId
      gatewaysDevicesId
      owner
    }
  }
`;
export const updateDevices = /* GraphQL */ `
  mutation UpdateDevices(
    $input: UpdateDevicesInput!
    $condition: ModelDevicesConditionInput
  ) {
    updateDevices(input: $input, condition: $condition) {
      type
      typeDescription
      description
      serialNumber
      master
      status
      deviceId
      variables {
        items {
          varId
          idEx
          title
          hasValue
          hasLogger
          hasForced
          sampleMode
          measureUnits
          unitsFactor
          decimals
          varType
          status
          deviceKey
          iowattKey
          id
          createdAt
          updatedAt
          gatewaysVariablesId
          devicesVariablesId
          owner
        }
        nextToken
      }
      id
      createdAt
      updatedAt
      customerDevicesId
      gatewaysDevicesId
      owner
    }
  }
`;
export const deleteDevices = /* GraphQL */ `
  mutation DeleteDevices(
    $input: DeleteDevicesInput!
    $condition: ModelDevicesConditionInput
  ) {
    deleteDevices(input: $input, condition: $condition) {
      type
      typeDescription
      description
      serialNumber
      master
      status
      deviceId
      variables {
        items {
          varId
          idEx
          title
          hasValue
          hasLogger
          hasForced
          sampleMode
          measureUnits
          unitsFactor
          decimals
          varType
          status
          deviceKey
          iowattKey
          id
          createdAt
          updatedAt
          gatewaysVariablesId
          devicesVariablesId
          owner
        }
        nextToken
      }
      id
      createdAt
      updatedAt
      customerDevicesId
      gatewaysDevicesId
      owner
    }
  }
`;
export const createVariables = /* GraphQL */ `
  mutation CreateVariables(
    $input: CreateVariablesInput!
    $condition: ModelVariablesConditionInput
  ) {
    createVariables(input: $input, condition: $condition) {
      varId
      idEx
      title
      hasValue
      hasLogger
      hasForced
      sampleMode
      measureUnits
      unitsFactor
      decimals
      varType
      status
      deviceKey
      iowattKey
      id
      createdAt
      updatedAt
      gatewaysVariablesId
      devicesVariablesId
      owner
    }
  }
`;
export const updateVariables = /* GraphQL */ `
  mutation UpdateVariables(
    $input: UpdateVariablesInput!
    $condition: ModelVariablesConditionInput
  ) {
    updateVariables(input: $input, condition: $condition) {
      varId
      idEx
      title
      hasValue
      hasLogger
      hasForced
      sampleMode
      measureUnits
      unitsFactor
      decimals
      varType
      status
      deviceKey
      iowattKey
      id
      createdAt
      updatedAt
      gatewaysVariablesId
      devicesVariablesId
      owner
    }
  }
`;
export const deleteVariables = /* GraphQL */ `
  mutation DeleteVariables(
    $input: DeleteVariablesInput!
    $condition: ModelVariablesConditionInput
  ) {
    deleteVariables(input: $input, condition: $condition) {
      varId
      idEx
      title
      hasValue
      hasLogger
      hasForced
      sampleMode
      measureUnits
      unitsFactor
      decimals
      varType
      status
      deviceKey
      iowattKey
      id
      createdAt
      updatedAt
      gatewaysVariablesId
      devicesVariablesId
      owner
    }
  }
`;
export const createInstallCommand = /* GraphQL */ `
  mutation CreateInstallCommand(
    $input: CreateInstallCommandInput!
    $condition: ModelInstallCommandConditionInput
  ) {
    createInstallCommand(input: $input, condition: $condition) {
      executionId
      user
      step
      status
      instance
      stdout
      stderr
      error
      id
      createdAt
      updatedAt
      ioWattInstallCommandsId
      owner
    }
  }
`;
export const updateInstallCommand = /* GraphQL */ `
  mutation UpdateInstallCommand(
    $input: UpdateInstallCommandInput!
    $condition: ModelInstallCommandConditionInput
  ) {
    updateInstallCommand(input: $input, condition: $condition) {
      executionId
      user
      step
      status
      instance
      stdout
      stderr
      error
      id
      createdAt
      updatedAt
      ioWattInstallCommandsId
      owner
    }
  }
`;
export const deleteInstallCommand = /* GraphQL */ `
  mutation DeleteInstallCommand(
    $input: DeleteInstallCommandInput!
    $condition: ModelInstallCommandConditionInput
  ) {
    deleteInstallCommand(input: $input, condition: $condition) {
      executionId
      user
      step
      status
      instance
      stdout
      stderr
      error
      id
      createdAt
      updatedAt
      ioWattInstallCommandsId
      owner
    }
  }
`;
export const createPowerPlant = /* GraphQL */ `
  mutation CreatePowerPlant(
    $input: CreatePowerPlantInput!
    $condition: ModelPowerPlantConditionInput
  ) {
    createPowerPlant(input: $input, condition: $condition) {
      name
      googlePlaceId
      country
      formattedAddress
      addressComponents
      lat
      lng
      description
      iowatts {
        items {
          name
          powerPlant
          hardware
          core
          coreGroup
          ip
          proxy
          status
          installStatus
          secretVersion
          streamVersion
          cliVersion
          nucleusVersion
          ownerStreamsVersion
          ownerDevicesVersion
          ownerConfigsVersion
          createdAt
          id
          updatedAt
          customerIowattsId
          owner
        }
        nextToken
      }
      id
      createdAt
      updatedAt
      customerPowerPlantsId
      owner
    }
  }
`;
export const updatePowerPlant = /* GraphQL */ `
  mutation UpdatePowerPlant(
    $input: UpdatePowerPlantInput!
    $condition: ModelPowerPlantConditionInput
  ) {
    updatePowerPlant(input: $input, condition: $condition) {
      name
      googlePlaceId
      country
      formattedAddress
      addressComponents
      lat
      lng
      description
      iowatts {
        items {
          name
          powerPlant
          hardware
          core
          coreGroup
          ip
          proxy
          status
          installStatus
          secretVersion
          streamVersion
          cliVersion
          nucleusVersion
          ownerStreamsVersion
          ownerDevicesVersion
          ownerConfigsVersion
          createdAt
          id
          updatedAt
          customerIowattsId
          owner
        }
        nextToken
      }
      id
      createdAt
      updatedAt
      customerPowerPlantsId
      owner
    }
  }
`;
export const deletePowerPlant = /* GraphQL */ `
  mutation DeletePowerPlant(
    $input: DeletePowerPlantInput!
    $condition: ModelPowerPlantConditionInput
  ) {
    deletePowerPlant(input: $input, condition: $condition) {
      name
      googlePlaceId
      country
      formattedAddress
      addressComponents
      lat
      lng
      description
      iowatts {
        items {
          name
          powerPlant
          hardware
          core
          coreGroup
          ip
          proxy
          status
          installStatus
          secretVersion
          streamVersion
          cliVersion
          nucleusVersion
          ownerStreamsVersion
          ownerDevicesVersion
          ownerConfigsVersion
          createdAt
          id
          updatedAt
          customerIowattsId
          owner
        }
        nextToken
      }
      id
      createdAt
      updatedAt
      customerPowerPlantsId
      owner
    }
  }
`;
export const createAlarmRule = /* GraphQL */ `
  mutation CreateAlarmRule(
    $input: CreateAlarmRuleInput!
    $condition: ModelAlarmRuleConditionInput
  ) {
    createAlarmRule(input: $input, condition: $condition) {
      name
      description
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAlarmRule = /* GraphQL */ `
  mutation UpdateAlarmRule(
    $input: UpdateAlarmRuleInput!
    $condition: ModelAlarmRuleConditionInput
  ) {
    updateAlarmRule(input: $input, condition: $condition) {
      name
      description
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAlarmRule = /* GraphQL */ `
  mutation DeleteAlarmRule(
    $input: DeleteAlarmRuleInput!
    $condition: ModelAlarmRuleConditionInput
  ) {
    deleteAlarmRule(input: $input, condition: $condition) {
      name
      description
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      name
      type
      country
      phone
      id
      createdAt
      updatedAt
      customerClientsId
      owner
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      name
      type
      country
      phone
      id
      createdAt
      updatedAt
      customerClientsId
      owner
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      name
      type
      country
      phone
      id
      createdAt
      updatedAt
      customerClientsId
      owner
    }
  }
`;
export const createCalendar = /* GraphQL */ `
  mutation CreateCalendar(
    $input: CreateCalendarInput!
    $condition: ModelCalendarConditionInput
  ) {
    createCalendar(input: $input, condition: $condition) {
      name
      type
      schedule
      hollidays
      beginHour
      endHour
      id
      createdAt
      updatedAt
      customerCalendarsId
      owner
    }
  }
`;
export const updateCalendar = /* GraphQL */ `
  mutation UpdateCalendar(
    $input: UpdateCalendarInput!
    $condition: ModelCalendarConditionInput
  ) {
    updateCalendar(input: $input, condition: $condition) {
      name
      type
      schedule
      hollidays
      beginHour
      endHour
      id
      createdAt
      updatedAt
      customerCalendarsId
      owner
    }
  }
`;
export const deleteCalendar = /* GraphQL */ `
  mutation DeleteCalendar(
    $input: DeleteCalendarInput!
    $condition: ModelCalendarConditionInput
  ) {
    deleteCalendar(input: $input, condition: $condition) {
      name
      type
      schedule
      hollidays
      beginHour
      endHour
      id
      createdAt
      updatedAt
      customerCalendarsId
      owner
    }
  }
`;
export const createHolliday = /* GraphQL */ `
  mutation CreateHolliday(
    $input: CreateHollidayInput!
    $condition: ModelHollidayConditionInput
  ) {
    createHolliday(input: $input, condition: $condition) {
      name
      schedule
      id
      createdAt
      updatedAt
      customerHollidaysId
      owner
    }
  }
`;
export const updateHolliday = /* GraphQL */ `
  mutation UpdateHolliday(
    $input: UpdateHollidayInput!
    $condition: ModelHollidayConditionInput
  ) {
    updateHolliday(input: $input, condition: $condition) {
      name
      schedule
      id
      createdAt
      updatedAt
      customerHollidaysId
      owner
    }
  }
`;
export const deleteHolliday = /* GraphQL */ `
  mutation DeleteHolliday(
    $input: DeleteHollidayInput!
    $condition: ModelHollidayConditionInput
  ) {
    deleteHolliday(input: $input, condition: $condition) {
      name
      schedule
      id
      createdAt
      updatedAt
      customerHollidaysId
      owner
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      name
      line
      description
      price
      fees
      id
      createdAt
      updatedAt
      customerProductsId
      owner
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      name
      line
      description
      price
      fees
      id
      createdAt
      updatedAt
      customerProductsId
      owner
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      name
      line
      description
      price
      fees
      id
      createdAt
      updatedAt
      customerProductsId
      owner
    }
  }
`;
export const createDrivers = /* GraphQL */ `
  mutation CreateDrivers(
    $input: CreateDriversInput!
    $condition: ModelDriversConditionInput
  ) {
    createDrivers(input: $input, condition: $condition) {
      name
      description
      file {
        bucket
        region
        key
      }
      command {
        name
        script
      }
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateDrivers = /* GraphQL */ `
  mutation UpdateDrivers(
    $input: UpdateDriversInput!
    $condition: ModelDriversConditionInput
  ) {
    updateDrivers(input: $input, condition: $condition) {
      name
      description
      file {
        bucket
        region
        key
      }
      command {
        name
        script
      }
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteDrivers = /* GraphQL */ `
  mutation DeleteDrivers(
    $input: DeleteDriversInput!
    $condition: ModelDriversConditionInput
  ) {
    deleteDrivers(input: $input, condition: $condition) {
      name
      description
      file {
        bucket
        region
        key
      }
      command {
        name
        script
      }
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createMagnitude = /* GraphQL */ `
  mutation CreateMagnitude(
    $input: CreateMagnitudeInput!
    $condition: ModelMagnitudeConditionInput
  ) {
    createMagnitude(input: $input, condition: $condition) {
      name
      nameEs
      units
      unitsEs
      badge
      badgeEs
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateMagnitude = /* GraphQL */ `
  mutation UpdateMagnitude(
    $input: UpdateMagnitudeInput!
    $condition: ModelMagnitudeConditionInput
  ) {
    updateMagnitude(input: $input, condition: $condition) {
      name
      nameEs
      units
      unitsEs
      badge
      badgeEs
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteMagnitude = /* GraphQL */ `
  mutation DeleteMagnitude(
    $input: DeleteMagnitudeInput!
    $condition: ModelMagnitudeConditionInput
  ) {
    deleteMagnitude(input: $input, condition: $condition) {
      name
      nameEs
      units
      unitsEs
      badge
      badgeEs
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createProgramIowatts = /* GraphQL */ `
  mutation CreateProgramIowatts(
    $input: CreateProgramIowattsInput!
    $condition: ModelProgramIowattsConditionInput
  ) {
    createProgramIowatts(input: $input, condition: $condition) {
      id
      programID
      ioWattID
      program {
        name
        goal
        goalUnits
        fees
        currency
        startTime
        endTime
        products
        customers
        variables
        gateways
        devices
        iowatts {
          nextToken
        }
        progCalendar {
          name
          type
          schedule
        }
        projections
        description
        id
        createdAt
        updatedAt
        customerProgramsId
        owner
      }
      ioWatt {
        name
        powerPlant
        hardware
        core
        coreGroup
        ip
        proxy
        status
        installStatus
        secretVersion
        streamVersion
        cliVersion
        nucleusVersion
        ownerStreamsVersion
        ownerDevicesVersion
        ownerConfigsVersion
        installCommands {
          nextToken
        }
        gateways {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        id
        updatedAt
        customerIowattsId
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProgramIowatts = /* GraphQL */ `
  mutation UpdateProgramIowatts(
    $input: UpdateProgramIowattsInput!
    $condition: ModelProgramIowattsConditionInput
  ) {
    updateProgramIowatts(input: $input, condition: $condition) {
      id
      programID
      ioWattID
      program {
        name
        goal
        goalUnits
        fees
        currency
        startTime
        endTime
        products
        customers
        variables
        gateways
        devices
        iowatts {
          nextToken
        }
        progCalendar {
          name
          type
          schedule
        }
        projections
        description
        id
        createdAt
        updatedAt
        customerProgramsId
        owner
      }
      ioWatt {
        name
        powerPlant
        hardware
        core
        coreGroup
        ip
        proxy
        status
        installStatus
        secretVersion
        streamVersion
        cliVersion
        nucleusVersion
        ownerStreamsVersion
        ownerDevicesVersion
        ownerConfigsVersion
        installCommands {
          nextToken
        }
        gateways {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        id
        updatedAt
        customerIowattsId
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProgramIowatts = /* GraphQL */ `
  mutation DeleteProgramIowatts(
    $input: DeleteProgramIowattsInput!
    $condition: ModelProgramIowattsConditionInput
  ) {
    deleteProgramIowatts(input: $input, condition: $condition) {
      id
      programID
      ioWattID
      program {
        name
        goal
        goalUnits
        fees
        currency
        startTime
        endTime
        products
        customers
        variables
        gateways
        devices
        iowatts {
          nextToken
        }
        progCalendar {
          name
          type
          schedule
        }
        projections
        description
        id
        createdAt
        updatedAt
        customerProgramsId
        owner
      }
      ioWatt {
        name
        powerPlant
        hardware
        core
        coreGroup
        ip
        proxy
        status
        installStatus
        secretVersion
        streamVersion
        cliVersion
        nucleusVersion
        ownerStreamsVersion
        ownerDevicesVersion
        ownerConfigsVersion
        installCommands {
          nextToken
        }
        gateways {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        id
        updatedAt
        customerIowattsId
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
