import * as types from '../types/iowatts'
import { API, graphqlOperation } from 'aws-amplify'
import { deleteIoWatt, deleteInstallCommand } from '../../graphql/mutations'
import { createIoWatt } from '../../graphql-custom/mutations'
import { powerPlantsByCustomer } from '../../graphql-custom/queries'
import { getCurrentProgramsByIowatt, deleteVariablesByIoWatt } from './iowattUtils'

/* **************************************************************** */
/* *************************** list iowatts *********************** */
/* **************************************************************** */

export function listIoWattsAction( customerId ){
    return async (dispath) => {
        dispath( listIoWattsStart() )
        try {
            const result = await API.graphql(graphqlOperation( powerPlantsByCustomer, { id : customerId } ))
            dispath( listIoWattsSuccess( result.data.getCustomer.powerPlants.items.map( p => ({
                id: p.id,
                updatedAt: p.updatedAt,
                name: p.name,
                googlePlaceId: p.googlePlaceId,
                country: p.country,
                formattedAddress: p.formattedAddress,
                addressComponents: p.addressComponents,
                lat: p.lat,
                lng: p.lng,
                description: p.description,
                iowatts: p.iowatts
            }))))
        } catch (error) {
            console.log(error)
            dispath( listIoWattsError() )
        }    
    }    
}

const listIoWattsStart = () => ({
    type: types.IOWATTS_LIST,
    payload: true
})

const listIoWattsSuccess = iowatts => ({
    type: types.IOWATTS_LIST_SUCCESS,
    payload: iowatts
});

const listIoWattsError = () => ({
    type: types.IOWATTS_LIST_ERROR,
    payload: false
});

/* **************************************************************** */
/* ************************** create iowatts ********************** */
/* **************************************************************** */

export function createIowattAction ( customerId, powerPlant, iowatt ){
    return async (dispath) => {
        dispath( createIowattStart())
        delete iowatt['mode']
        iowatt['customerIowattsId'] = customerId
        iowatt['powerPlant'] = powerPlant
        iowatt['coreGroup'] = '--'
        iowatt['core'] = '--'
        iowatt['status'] = '0%'
        iowatt['installStatus'] = 'PENDING'
        delete iowatt.secret
        try{
            const result = await  API.graphql(graphqlOperation( createIoWatt, { input: iowatt } ))
            dispath( createIowattSuccess(result.data.createIoWatt))
        } catch( error ){
            console.log(error)
            dispath( createIowattError())
        }
    }
}

const createIowattStart = () => ({
    type: types.IOWATTS_CREATE,
    payload: true
})

const createIowattSuccess = ( iowatt ) => ({
    type: types.IOWATTS_CREATE_SUCCESS,
    payload: iowatt
})

const createIowattError = () => ({
    type: types.IOWATTS_CREATE_ERROR,
    payload: false
})

/* **************************************************************** */
/* ************************** delete ********************** */
/* **************************************************************** */

export function deleteIoWattAction( ioWatt ){
    return async (dispath) => {
        dispath( deleteIoWattStart() )
        try{
            const programs = await getCurrentProgramsByIowatt(  ioWatt.id )
            if( programs && programs.length > 0 ) {
                dispath( thereAreProgramsError( programs ))
                return
            }
            if( ioWatt.installCommands && Array.isArray(ioWatt.installCommands) && ioWatt.installCommands.length > 0 ){
                await Promise.all( ioWatt.installCommands.map( async ( command ) => {
                    await API.graphql(graphqlOperation( deleteInstallCommand, { input: { id : command.id } } ))
                }))
            }
            await API.graphql(graphqlOperation( deleteIoWatt, { input: { id : ioWatt.id } } ))
            dispath( deleteIoWattSuccess( ioWatt.id ))
            deleteVariablesByIoWatt( ioWatt.id )
        } catch (error) {
            console.log(error);
            dispath( deleteIoWattError() );
        }
    }
}

const deleteIoWattStart = () => ({
    type: types.IOWATTS_DELETE,
    payload: true
})

const deleteIoWattSuccess = ( iowattId ) => ({
    type: types.IOWATTS_DELETE_SUCCESS,
    payload: iowattId
})

const deleteIoWattError = () => ({
    type: types.IOWATTS_DELETE_ERROR,
    payload: false
})

const thereAreProgramsError =  ( programs ) => ({
    type: types.IOWATTS_DELETE_THERE_ARE_PROGRAMS_ERROR,
    payload: programs
})


/* **************************************************************** */
/* *************************** set language *********************** */
/* **************************************************************** */

export function setLanguageAction( lan ){
    return async (dispath) => {
        dispath( setLanguage( lan ) )
    }
}

const setLanguage = (lan) => ({
    type: types.SET_LANGUAGE,
    payload: lan
})


/* **************************************************************** */
/* ************************* clear states ************************* */
/* **************************************************************** */

export function iowattClearStatesAction(){
    return async (dispath) => {
        dispath( setClearStates() )
    }
}

const setClearStates = () => ({
    type: types.SET_CLEAR_STATES,
    payload: true
})


