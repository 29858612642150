export const SET_LANGUAGE = 'SET_LANGUAGE'
export const LIST_CUSTOMERS = 'LIST_CUSTOMERS'
export const LIST_CUSTOMERS_SUCCESS = 'LIST_CUSTOMERS_SUCCESS'
export const LIST_CUSTOMERS_ERROR = 'LIST_CUSTOMERS_ERROR'

export const CREATE_CUSTOMER_PERMISSIONS = 'CREATE_CUSTOMER_PERMISSIONS'
export const CREATE_CUSTOMER_PERMISSIONS_SUCCESS = 'CREATE_CUSTOMER_PERMISSIONS_SUCCESS'
export const CREATE_CUSTOMER_PERMISSIONS_ERROR = 'CREATE_CUSTOMER_PERMISSIONS_ERROR'

export const CREATE_CUSTOMER_MAGNITUDES = 'CREATE_CUSTOMER_MAGNITUDES'
export const CREATE_CUSTOMER_MAGNITUDES_SUCCESS = 'CREATE_CUSTOMER_MAGNITUDES_SUCCESS'
export const CREATE_CUSTOMER_MAGNITUDES_ERROR = 'CREATE_CUSTOMER_MAGNITUDES_ERROR'