import React from 'react';
import { Dialog, DialogContent, DialogTitle, CircularProgress as MuiCircularProgress, } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import styled from "styled-components";
import { makeStyles } from '@material-ui/core/styles';

const CircularProgress = styled(MuiCircularProgress)(spacing);


const useStyles = makeStyles({
    root: {
        height: "100px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    },
});

const ModalProgress = ({open, modalTitle}) => {
    const classes = useStyles();
    return ( 
        <Dialog open={open} aria-labelledby="alert-dialog-title">
            <DialogTitle id="alert-dialog-title">
                {`${modalTitle}`}
            </DialogTitle>
            <DialogContent id="loading-dialog-content" classes={{ root: classes.root}} label="loading">
                <CircularProgress m={2} color="secondary" /> 
            </DialogContent>
        </Dialog> 
    );
}
 
export default ModalProgress;