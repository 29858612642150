import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
import { Grid, Hidden, Menu, MenuItem, AppBar as MuiAppBar, IconButton as MuiIconButton, Toolbar} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import {  Power } from "react-feather";
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguageAction  } from '../redux/actions/languageActions'
import { logoutUserAction } from '../redux/actions/authActions'
import { devicesClearStatesAction } from '../redux/actions/gatewayDevicesActions'
import { gatewayConfigClearStatesAction } from '../redux/actions/gatewayConfigActions'
import { programClearStatesAction } from '../redux/actions/programActions'
import { iowattClearStatesAction } from '../redux/actions/iowattActions'


const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;

function LanguageMenu() {
  
  const dispacth = useDispatch();
  const languageSrc = useSelector( state => state.languageReducer.src )
  const languageAlt = useSelector( state => state.languageReducer.src )
  const changeLanguage = (language) => dispacth( changeLanguageAction(language) )
  
  useEffect(() => {
    setAnchorMenu(null);
  }, [languageSrc]);
  
  const [anchorMenu, setAnchorMenu] = useState(null);

  const toggleMenu = event => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenuEnglish = () => {
    changeLanguage({ src : 'us', alt : 'English' })
  };

  const closeMenuSpanish = () => {
    changeLanguage({ src : 'es', alt : 'Spanish' })
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <React.Fragment>
      <IconButton
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        <Flag src={`/static/img/flags/${languageSrc}.png`} alt={`${languageAlt}`} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={closeMenuEnglish}>
          English
        </MenuItem>
        <MenuItem onClick={closeMenuSpanish}>
          Spanish
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

function UserMenu() {
  const dispacth = useDispatch();
  const logoutUser = () => dispacth( logoutUserAction() )
  const devicesClearStatesTrigger = () =>  dispacth( devicesClearStatesAction() ) 
  const gatewayConfigClearStatesTrigger = () =>  dispacth( gatewayConfigClearStatesAction() ) 
  const programClearStatesTrigger = () =>  dispacth( programClearStatesAction() )
  const iowattClearStatesTrigger = () =>  dispacth( iowattClearStatesAction() ) 
  
  const logoutApp = async () => {
    devicesClearStatesTrigger()
    gatewayConfigClearStatesTrigger()
    programClearStatesTrigger()
    iowattClearStatesTrigger()
    logoutUser()
  }

  return (
    <React.Fragment>
      <IconButton
        onClick={logoutApp}
        color="inherit"
      >
        <Power />
      </IconButton>
    </React.Fragment>
  );
}

const Header = ({ onDrawerToggle }) => (
  <React.Fragment>
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center">
          <Hidden mdUp>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          <Grid item xs />
          <Grid item>
            <LanguageMenu />
            <UserMenu />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  </React.Fragment>
);

export default connect()(withTheme(Header));
