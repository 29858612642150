import React, {useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Helmet from 'react-helmet';

import { spacing } from "@material-ui/system";
import styled from "styled-components";
import {
  FormControl,
  Input,
  InputLabel,
  Button as MuiButton,
  Paper,
  Typography,
  Grid as MuiGrid,
  Link as HyperLink
} from "@material-ui/core";

import ModalProgress from '../../components/dialog/ModalProgress' 
import ByBAlert from '../../components/alerts/ByBAlert'
import { confirmUserAction, resendCodeAction  } from '../../redux/actions/authActions'


const Button = styled(MuiButton)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Spacer = styled.div(spacing);
const Wrapper = styled(Paper)`
  background: ${props => props.theme.body.background};
  padding: ${props => props.theme.spacing(6)}px;
  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
  width: 420px;
`;


function ConfirmSignUp() {
  const history = useHistory();
  const [ account, setAccount ] = React.useState({
    username: '',
    code: ''
  });

  const [ error, setError ] = React.useState({
    username: false,
    code: false
  });

  /*const [ resend, setResend ] = React.useState({
    show : false,
    message : ''
  });*/

  const [ alert, setAlert ] = React.useState(false);
  const [ start, setStart ] = React.useState(false);
  const [ filling, setFilling ] = React.useState(false);
  
  const dispacth = useDispatch();
  const confirmStatus = useSelector( state => state.authReducer.status );
  const loading = useSelector( state => state.authReducer.loading );
  const confirmMessage = useSelector( state => state.authReducer.message );
  const confirmUsername = useSelector( state => state.authReducer.username );
  const resendCodeStatus = useSelector( state => state.authReducer.resendStatus );
  
  const confirmUser = (user) => dispacth( confirmUserAction(user) );
  const resendCode = (user) => dispacth( resendCodeAction(user) );

  
  useEffect(() => {

  }, []);
  

  const handleChange = (event) => {
    setFilling(true);
    //setResend({show : false , message : ''})
    setAccount({
      ...account,
      [ event.target.name]: event.target.value,
    });
    setError({
      ...error,
      [ event.target.name ]: event.target.value.trim() ? true : false,
    });
  };

  const { code } = account

  const submitAccount = e => {
    e.preventDefault();
    setFilling(false);
    if( code.trim() === '' ){
      setStart(true);
      setAlert(true);
      return;
    }else{
      setAlert(false);  
    }
    
    confirmUser({'username' : confirmUsername, code});
    setAccount({ username: '', code: '' });
    setError({ username: false, code: false })
    setStart(false);
    history.push('/auth/confirm-sign-up')
  }

  const submitResendCode = e => {
    e.preventDefault();
    resendCode({'username' : confirmUsername});
    //setResend({show : true, message : 'code sent successfully'})
  }
  
  return (
    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
      <Wrapper variant="outlined">
        <Helmet title="Sign Up" />
        
        <ByBAlert show={ confirmStatus && !filling && !alert } type="title" severity={ confirmStatus } message={ confirmMessage }/> 
        <ByBAlert show={!filling && alert} type="title" severity="error" message="Required fields are missing!"/>   
        <Spacer mb={4} />     
        <ModalProgress open = { loading } modalTitle = 'Confirming account...'/>

        <Typography component="h1" variant="h4" align="center" gutterBottom>Confirm Sign Up</Typography>

        <form>
          
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="name">Username</InputLabel>
            <Input id="name" name="name" autoFocus inputProps={{name:'username'}} value={confirmUsername} disabled />
          </FormControl>

          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="code">Confirmation code</InputLabel>
            <Input id="code" name="code" autoComplete="code" inputProps={{name:'code'}} error={( !error.code && start )} onChange={handleChange} value={account.code} />
          </FormControl>
          <Spacer mb={8} />

          <Button fullWidth variant="contained" color="primary" mt={2} onClick={submitAccount} >Confirm</Button>

        </form>

        <Spacer mb={4} />

        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <Typography component="h2" variant="body1">Lost your code? {" "}
              <HyperLink component="button" onClick={ submitResendCode } >Resend Code</HyperLink>
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <Typography component="h2" variant="body1"><HyperLink href="/">Back to Sign In</HyperLink></Typography>
          </Grid>
        </Grid>

        <ByBAlert show={ resendCodeStatus && !filling} type="title" severity="success" message={ confirmMessage }/> 

      </Wrapper>
    </div>
  )
}
 
export default ConfirmSignUp;