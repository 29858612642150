function createShadow(px) {
  return `0 0 ${px}px 0 rgba(53,64,82,.05)`;
}

function createCardShadow(px) {
  return `2px 2px 2px 1px rgba(0, 0, 0, 0.${px})` 
}

const shadows = [
  "none",
  createCardShadow(1),
  createCardShadow(2),
  createCardShadow(3),
  createCardShadow(4),
  createCardShadow(5),
  createShadow(18),
  createShadow(19),
  createShadow(20),
  createShadow(21),
  createShadow(22),
  createShadow(22),
  createShadow(24),
  createShadow(25),
  createShadow(26),
  createShadow(26),
  createShadow(27),
  createShadow(29),
  createShadow(29),
  createShadow(30),
  createShadow(31),
  createShadow(32),
  createShadow(33),
  createShadow(34),
  createShadow(35),
  createShadow(36)
];

export default shadows;
