import * as constants from '../../constants/index'
import * as types from '../types/programs'

const initialState = {
    language: 'us',
    loading: false,
    loadingMessage: null,
    alertShow: false,
    alertSeverity: constants.SUCCESS,
    alertMessage: null,
    programs: [],
    variablesByIoWatt: [],
    iowattInfo: {},
    programming: false
}

export default function(state = initialState, action){
    switch (action.type) {
        case types.SET_LANGUAGE:
            return {
                ...state,
                language: action.payload
            }
        case types.SET_CLEAR_STATES:
            return {
                language: 'us',
                loading: false,
                loadingMessage: null,
                alertShow: false,
                alertSeverity: constants.SUCCESS,
                alertMessage: null,
                programs: [],
                variablesByIoWatt: [],
                iowattInfo: {}
            }
        case types.IOWATT_GET_INFO:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.IOWATT_GET_INFO_EN : constants.IOWATT_GET_INFO_ES,
                alertShow: false,
            }
        case types.IOWATT_GET_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: false,
                alertSeverity: constants.SUCCESS,
                alertMessage: null,
                iowattInfo: action.payload
            }
        case types.IOWATT_GET_INFO_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.IOWATT_GET_INFO_ERROR_EN : constants.IOWATT_GET_INFO_ERROR_ES,
            }
        case types.IOWATT_RELEASE_INFO:
            return {
                ...state,
                iowattInfo: {}
            }
        case types.GET_VARIABLES_BY_IOWATT:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.LOADING_EN : constants.LOADING_ES,
                alertShow: false,
            }
        case types.GET_VARIABLES_BY_IOWATT_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: false,
                alertSeverity: constants.SUCCESS,
                alertMessage: constants.END_SUCCESS,
                variablesByIoWatt: action.payload
            }
        case types.GET_VARIABLES_BY_IOWATT_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.GET_VARIABLES_BY_IOWATT_ERROR_EN : constants.GET_VARIABLES_BY_IOWATT_ERROR_ES,
            }
        
        case types.PROGRAMS_LIST:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.PROGRAMS_GETTING_EN : constants.PROGRAMS_GETTING_ES,
                alertShow: false,
            }
        case types.PROGRAMS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,
                alertMessage:  state.language === constants.LAN_EN ? constants.PROGRAMS_GETTING_SUCCESS_EN : constants.PROGRAMS_GETTING_SUCCESS_ES,
                programs: action.payload
            }
        case types.PROGRAMS_LIST_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.PROGRAMS_GETTING_ERROR_EN : constants.PROGRAMS_GETTING_ERROR_ES,
            }
        case types.PROGRAMS_CREATE:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.PROGRAMS_CREATING_EN : constants.PROGRAMS_CREATING_ES,
                alertShow: false,
                alertMessage: null,
                programming: true,
            }
        case types.PROGRAMS_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,            
                alertMessage: state.language === constants.LAN_EN ? constants.PROGRAMS_CREATE_SUCCESS_EN : constants.PROGRAMS_CREATE_SUCCESS_ES,
                programs: [ ...state.programs.filter( program => program.id !== action.payload.id ), action.payload ],
                programming: false,
            }
        case types.PROGRAMS_CREATE_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.PROGRAMS_CREATE_ERROR_EN : constants.PROGRAMS_CREATE_ERROR_ES,
                programming: false,
            }
        case types.PROGRAMS_SHADOW_LIST_SUCCESS:
            return {
                ...state,
                programs: action.payload,
            }
        case types.PROGRAMS_UPDATE:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.PROGRAMS_UPDATING_EN : constants.PROGRAMS_UPDATING_EN,
                alertShow: false,
                alertMessage: null,
                programming: true,
            }
        case types.PROGRAMS_UPDATE_SUCCESS:
            const index = state.programs.findIndex( program => program.id === action.payload.id )
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,            
                alertMessage: state.language === constants.LAN_EN ? constants.PROGRAMS_UPDATE_SUCCESS_EN : constants.PROGRAMS_UPDATE_SUCCESS_ES,
                programs: [ ...state.programs.slice( 0, index ), action.payload, ...state.programs.slice( index + 1 ),],
                programming: false,
            }
        case types.PROGRAMS_UPDATE_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.PROGRAMS_UPDATE_ERROR_EN : constants.PROGRAMS_UPDATE_ERROR_ES,
                programming: false,
            }
        case types.PROGRAMS_DELETE:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.PROGRAMS_DELETING_EN : constants.PROGRAMS_DELETING_ES,
                alertShow: false,
                alertMessage: null,
                programming: true,
            }
        case types.PROGRAMS_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,            
                alertMessage: state.language === constants.LAN_EN ? constants.PROGRAMS_DELETE_SUCCESS_EN : constants.PROGRAMS_DELETE_SUCCESS_ES,
                programs: state.programs.filter( program => action.payload !== program.id ),
                programming: false,
            }
        default:
            return state;
    }
}