import * as constants from '../../constants/index'
import * as types from '../types/drivers'

const initialState = {
    language: 'us',
    loading: false,
    loadingMessage: null,
    alertShow: false,
    alertSeverity: constants.SUCCESS,
    alertMessage: null,
    loadingImagePercent: 0,
    loadingImageMessage: null,
    drivers: []
}

export default function(state = initialState, action){
    switch (action.type) {
        case types.SET_LANGUAGE:
            return {
                ...state,
                language: action.payload
            }
        case types.DRIVERS_LOADING_IMAGE:
            return {
                ...state,
                loading: false,
                loadingMessage: state.language === constants.LAN_EN ? constants.DRIVER_LOADING_IMAGE_EN : constants.DRIVER_LOADING_IMAGE_ES,
                alertShow: false,
                alertSeverity: constants.SUCCESS,
                alertMessage: null,
                loadingImagePercent: action.payload,
                loadingImageMessage: state.language === constants.LAN_EN ? constants.DRIVER_LOADING_IMAGE_EN : constants.DRIVER_LOADING_IMAGE_ES
            }
            
        case types.DRIVER_LIST:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.DRIVER_GETTING_EN : constants.DRIVER_GETTING_ES,
                alertShow: false,
            }
        case types.DRIVERS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: false,
                alertSeverity: constants.SUCCESS,
                alertMessage: null,
                drivers: action.payload
            }
        case types.DRIVERS_LIST_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.DRIVER_GETTING_ERROR_EN : constants.DRIVER_GETTING_ERROR_ES,
            }
        case types.DRIVERS_CREATE:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.DRIVER_CREATING_EN : constants.DRIVER_CREATING_ES,
                alertShow: false,
                alertMessage: null,
            }
        case types.DRIVERS_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,            
                alertMessage: state.language === constants.LAN_EN ? constants.DRIVER_CREATE_SUCCESS_EN : constants.DRIVER_CREATE_SUCCESS_ES,
                drivers: [ ...state.drivers.filter( driver => driver.id !== action.payload.id ), action.payload ],
                loadingImageMessage: constants.END_SUCCESS,
                loadingImagePercent: 0,
            }
        case types.DRIVERS_CREATE_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.DRIVER_CREATE_ERROR_EN : constants.DRIVER_CREATE_ERROR_ES,
                loadingImageMessage: null,
                loadingImagePercent: 0,
            }
        case types.DRIVERS_UPDATE:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_EN ? constants.DRIVER_UPDATING_EN : constants.DRIVER_UPDATING_ES,
                alertShow: false,
                alertMessage: null,
            }
        case types.DRIVERS_UPDATE_SUCCESS:
            const index = state.drivers.findIndex( driver => driver.id === action.payload.id )
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,            
                alertMessage: state.language === constants.LAN_EN ? constants.DRIVER_UPDATE_SUCCESS_EN : constants.DRIVER_UPDATE_SUCCESS_ES,
                drivers: [ ...state.drivers.slice( 0, index ), action.payload, ...state.drivers.slice( index + 1), ],
                loadingImageMessage: constants.END_SUCCESS,
                loadingImagePercent: 0,
            }
        case types.DRIVERS_UPDATE_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.DRIVER_UPDATE_ERROR_EN : constants.DRIVER_UPDATE_ERROR_ES,
                loadingImageMessage: constants.END_ERROR,
                loadingImagePercent: 0,
            }
        case types.DRIVERS_DELETE:
            return {
                ...state,
                loading: true,
                loadingMessage: state.language === constants.LAN_ES ? constants.DRIVER_DELETING_ES : constants.DRIVER_DELETING_EN,
                alertShow: false,
                alertMessage: null
            }
        case types.DRIVERS_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_SUCCESS,
                alertShow: true,
                alertSeverity: constants.SUCCESS,            
                alertMessage: state.language === constants.LAN_EN ? constants.DRIVER_DELETE_SUCCESS_EN : constants.DRIVER_DELETE_SUCCESS_ES,
                drivers: state.drivers.filter( driver => !action.payload.includes( driver.id ) ),
                loadingImageMessage: constants.END_SUCCESS,
                loadingImagePercent: 0,
            }
        case types.DRIVERS_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                loadingMessage: constants.END_ERROR,
                alertShow: true,
                alertSeverity: constants.ERROR,
                alertMessage: state.language === constants.LAN_EN ? constants.DRIVER_DELETE_ERROR_EN : constants.DRIVER_DELETE_ERROR_ES,
                loadingImageMessage: constants.END_ERROR,
                loadingImagePercent: 0,
            }
        default:
            return state;
    }
}