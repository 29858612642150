import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { devicesClearStatesAction } from '../redux/actions/gatewayDevicesActions'
import { gatewayConfigClearStatesAction } from '../redux/actions/gatewayConfigActions'
import { programClearStatesAction } from '../redux/actions/programActions'
import { iowattClearStatesAction } from '../redux/actions/iowattActions'
import { logoutUserAction } from '../redux/actions/authActions'

const PrivateRoutes = ({ component: Component, ...props  }) => {

    const dispacth = useDispatch();
    const devicesClearStatesTrigger = () =>  dispacth( devicesClearStatesAction() ) 
    const gatewayConfigClearStatesTrigger = () =>  dispacth( gatewayConfigClearStatesAction() ) 
    const programClearStatesTrigger = () =>  dispacth( programClearStatesAction() )
    const iowattClearStatesTrigger = () =>  dispacth( iowattClearStatesAction() ) 
    const logoutUser = () =>  dispacth( logoutUserAction() ) 
    
    const authenticated = useSelector( state => state.authReducer.authenticated );
    const loading = useSelector( state => state.authReducer.loading );
    
    useEffect(() => {
        if(  !authenticated ){
            devicesClearStatesTrigger()
            gatewayConfigClearStatesTrigger()
            programClearStatesTrigger()
            iowattClearStatesTrigger()
            logoutUser()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ authenticated ]);

    return ( 
        <Route { ...props } render={ props => !authenticated && !loading ?  
            (
                <Redirect to="/" />  
            )  : (
                <Component {...props} />
            ) 
        } />
    );
}
 
export default PrivateRoutes;