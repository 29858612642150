import { createMuiTheme } from "@material-ui/core/styles";

import variants from "./variants";
import typography from "./typography";
import overrides from "./overrides";
import breakpoints from "./breakpoints";
import props from "./props";
import shadows from "./shadows";

const theme = variant => {
  return createMuiTheme(
    {
      palette: variant.palette,
      typography: typography,
      spacing: 4,
      breakpoints: breakpoints,
      overrides: overrides,
      props: props,      
      shadows: shadows,
      body: variant.body,
      header: variant.header,      
      sidebar: variant.sidebar,
      settingsCard: variant.settingsCard,
      deviceCard: variant.deviceCard,
      chip: variant.chip,
      table: variant.table,
      button: variant.button
    },
    variant.name
  );
};

const themes = variants.map(variant => theme(variant));

export default themes;
