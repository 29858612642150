export const SET_LANGUAGE = 'SET_LANGUAGE'

export const POWER_PLANT_LIST = 'POWER_PLANT_LIST'
export const POWER_PLANT_LOADING_IMAGE = 'POWER_PLANT_LOADING_IMAGE'
export const POWER_PLANT_LIST_SUCCESS = 'POWER_PLANT_LIST_SUCCESS'
export const POWER_PLANT_LIST_ERROR = 'POWER_PLANT_LIST_ERROR'
export const POWER_PLANT_CREATE = 'POWER_PLANT_CREATE'
export const POWER_PLANT_CREATE_SUCCESS = 'POWER_PLANT_CREATE_SUCCESS'
export const POWER_PLANT_CREATE_ERROR = 'POWER_PLANT_CREATE_ERROR'
export const POWER_PLANT_UPDATE = 'POWER_PLANT_UPDATE'
export const POWER_PLANT_UPDATE_SUCCESS = 'POWER_PLANT_UPDATE_SUCCESS'
export const POWER_PLANT_UPDATE_ERROR = 'POWER_PLANT_UPDATE_ERROR'
export const POWER_PLANT_DELETE = 'POWER_PLANT_DELETE'
export const POWER_PLANT_DELETE_SUCCESS = 'POWER_PLANT_DELETE_SUCCESS'
export const POWER_PLANT_DELETE_ERROR = 'POWER_PLANT_DELETE_ERROR'