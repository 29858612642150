const typography = {
  useNextVariants: true,
  fontFamily: [
    "Nunito",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(","),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  h1: {
    fontSize: "3rem",
    fontWeight: 200,
    '@media (min-width:600px)': {
      fontSize: '4rem',
    },
  },
  h2: {
    fontSize: "1.65rem",
    fontWeight: 300,
    lineHeight: 1.0,
    '@media (min-width:600px)': {
      fontSize: '3rem',
    },
  },
  h3: {
    fontSize: "1.25rem", 
    fontWeight: 600,
    lineHeight: 1.2,
    '@media (min-width:600px)': {
      fontSize: '1.5rem',
    },
  },
  h4: {
    fontSize: "1.125rem", 
    fontWeight: 600,
    lineHeight: 1.2,
    '@media (min-width:600px)': {
      fontSize: '1.25rem',
    },
  },
  h5: {
    fontSize: "1.125rem",
    fontWeight: 600,
    lineHeight: 1.2,
    '@media (min-width:600px)': {
      fontSize: '1.0625rem'
    },
  },
  h6: {
    fontSize: "1.0000rem",
    fontWeight: 600,
    lineHeight: 1.2,
    '@media (min-width:600px)': {
      fontSize: '1.0625rem'
    },
  },
  body1: {
    fontSize: 14
  },
  body2:{
    fontSize: 12
  },
  subtitle1:{
    fontSize: 18
  },
  button: {
    textTransform: "none"
  }
};

export default typography;