export const getProductsByCustomer = /* GraphQL */ `
    query GetCustomer($id: ID!) {
        getCustomer(id: $id) {
            id
            slug
            name
            root
            lines {
                items {
                    name
                    description
                    id
                    createdAt
                    updatedAt
                    customerLinesId
                    owner
                    products {
                        items {
                            name
                            description
                            price
                            fees
                            id
                            createdAt
                            updatedAt
                            lineProductsId
                            owner
                        }
                        nextToken
                    }
                }
                nextToken
            }
            createdAt
            updatedAt
            owner
        }
    }
`;
export const getDriverByCustomer = /* GraphQL */ `
    query GetCustomer($id: ID!) {
        getCustomer(id: $id) {
            id
            slug
            name
            root
            drivers {
                items {
                    name
                    description
                    id
                    createdAt
                    updatedAt
                    customerDriversId
                    owner
                    file {
                        bucket
                        region
                        key
                    }
                    command {
                        name
                        script
                    }
                }
                nextToken
            }
            createdAt
            updatedAt
            owner
        }
    }
`;
export const getGatewayById = /* GraphQL */ `
  query GetGateways($id: ID!) {
    getGateways(id: $id) {
      id
      devices {
        items {
          type
          typeDescription
          description
          serialNumber
          master
          status
          deviceId
          id
          createdAt
          updatedAt
          customerDevicesId
          gatewaysDevicesId
          owner
        }
        nextToken
      }
    }
  }
`;
export const getGatewaysByCustomer = /* GraphQL */  `
    query GetCustomer($id: ID!) {
        getCustomer(id: $id) {
            id
            slug
            name
            root
            gateways {
                items {
                    name
                    ip
                    driverType
                    type
                    dataLake
                    status
                    devices {
                        items {
                            type
                            typeDescription
                            description
                            serialNumber
                            master
                            status
                            deviceId
                            id
                            createdAt
                            updatedAt
                            gatewaysDevicesId
                            owner
                        }
                        nextToken
                    }
                    iowatt {
                        name
                        powerPlant
                        hardware
                        coreGroup
                        core
                        ip
                        status
                        installStatus
                        id
                    }
                    id
                    createdAt
                    updatedAt
                    customerGatewaysId
                    ioWattGatewaysId
                    owner
                }
                nextToken
            }
            createdAt
            updatedAt
            owner
        }
    }
`;
export const variableByIoWatt =  /* GraphQL */ `
  query VariableByIoWatt(
    $iowattKey: String!, 
    $statusIdEx: ModelVariablesByIoWattCompositeKeyConditionInput, 
    $sortDirection: ModelSortDirection, 
    $filter: ModelVariablesFilterInput, 
    $limit: Int, 
    $nextToken: String
  ) {
    variableByIoWatt(
      iowattKey: $iowattKey, 
      statusIdEx: $statusIdEx, 
      sortDirection: $sortDirection, 
      filter: $filter, 
      limit: $limit, 
      nextToken: $nextToken
    ) {
      items {
        varId
        idEx
        title
        hasValue
        hasLogger
        hasForced
        sampleMode
        measureUnits
        unitsFactor
        decimals
        varType
        status
        deviceKey
        iowattKey
        id
        createdAt
        updatedAt
        gatewaysVariablesId
        devicesVariablesId
        owner
      }
      nextToken
    }
  }
`;
export const variableByDevice = /* GraphQL */ `
  query VariableByDevice(
    $deviceKey: String!
    $statusVarId: ModelVariablesByDeviceCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVariablesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    variableByDevice(
      deviceKey: $deviceKey
      statusVarId: $statusVarId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        varId
        idEx
        title
        hasValue
        hasLogger
        hasForced
        sampleMode
        measureUnits
        unitsFactor
        decimals
        varType
        status
        deviceKey
        iowattKey
        id
        createdAt
        updatedAt
        gatewaysVariablesId
        devicesVariablesId
        owner
      }
      nextToken
    }
  }
`;
export const getProgramsByCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      slug
      name
      root
      programs {
        items {
          name
          goal
          goalUnits
          fees
          currency
          startTime
          endTime      
          products
          customers
          variables
          gateways
          devices
          iowatts {
            items {
              ioWatt{
                id
                ip
                name
                powerPlant
                hardware
                core
                status
              }
            }
            nextToken
          }
          projections
          description
          progCalendar {
            name
            schedule
            type
          }
          id
          createdAt
          updatedAt
          customerProgramsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const getIoWatt = /* GraphQL */ `
  query GetIoWatt($id: ID!) {
    getIoWatt(id: $id) {
      name
      powerPlant
      hardware
      coreGroup
      core
      ip
      gateways {
        items {
          name
          ip
          driverType
          type
          dataLake
          ioWattGatewaysId
          timeOffset
          devices {
            items {
              type
              serialNumber
              master
              deviceId
              gatewaysDevicesId
              id
            }
            nextToken
          }
          variables {
            items {
              varId
              idEx
              title
              deviceKey
              id
              updatedAt
              devicesVariablesId
              gatewaysVariablesId
              owner
            }
            nextToken
          }
          id
        }
        nextToken
      }
      id
    }
  }
`;
export const getIoWattPrograms = /* GraphQL */ `
  query GetIoWatt($id: ID!) {
    getIoWatt(id: $id) {
      id
      name
      ip
      powerPlant
      hardware
      coreGroup
      core
      programs{
        items {
          program {
            id
            name
            goal
            goalUnits
            fees
            currency
            startTime
            endTime      
            products
            customers
            devices
            variables
            gateways
            projections
            description
            progCalendar {
              name
              schedule
              type
            }
            createdAt
            updatedAt
            customerProgramsId
          }
        }
        nextToken
      }            
    }
  }
`;
export const getProgramRelations = /* GraphQL */ `
  query GetProgram($id: ID!) {
    getProgram(id: $id) {
      id
      iowatts {
        items {
          id
          programID
          ioWattID
          ioWatt {
            id
            name 
          }
        }
        nextToken
      }
    }
  }
`;
export const getProgramWithIowatts = /* GraphQL */ `
query GetProgram($id: ID!) {
    getProgram(id: $id) {
      id
      name
      goal
      goalUnits
      fees
      currency
      startTime
      endTime
      products
      customers
      variables
      gateways
      devices
      iowatts {
        items {
          ioWatt {
            id
            name
            programs{
              items{
                program {
                  id
                  name
                  goal
                  goalUnits
                  fees
                  currency
                  startTime
                  endTime
                  products
                  customers
                  variables
                  gateways
                  devices
                  progCalendar {
                    name
                    type
                    schedule
                  }
                  projections
                  description
                  id
                  createdAt
                  updatedAt
                  customerProgramsId
                  owner
                }
              }
            } 
          }
        }
        nextToken
      }
      progCalendar {
        name
        type
        schedule
      }
      projections
      description
      id
      createdAt
      updatedAt
      customerProgramsId
      owner
    }
  }
`;

/* **************************************************************** */
/* ************************** config queries ********************** */
/* **************************************************************** */

export const getCalendarsByCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      slug
      name
      root
      country      
      calendars {
        items {
          name
          type
          schedule
          hollidays
          beginHour
          endHour
          id
          createdAt
          updatedAt
          customerCalendarsId
          owner
        }
        nextToken
      }
      hollidays {
        items {
          name
          schedule
          id
          createdAt
          updatedAt
          customerHollidaysId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const getClientsByCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      slug
      name
      root
      country
      clients {
        items {
          name
          type
          country
          phone
          id
          createdAt
          updatedAt
          customerClientsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const getPowerPlantsByCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      slug
      name
      root
      country
      powerPlants {
        items {
          name
          googlePlaceId
          country
          formattedAddress
          addressComponents
          lat
          lng
          description
          id
          createdAt
          updatedAt
          customerPowerPlantsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const getSimpleProductsByCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      slug
      name
      root
      country
      products {
        items {
          name
          line
          description
          price
          fees
          id
          createdAt
          updatedAt
          customerProductsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;


/* **************************************************************** */
/* ************************** connect action ********************** */
/* **************************************************************** */

export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        slug
        name
        root
        country
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

export const listMagnitudes = /* GraphQL */ `
  query ListMagnitudes(
    $filter: ModelMagnitudeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMagnitudes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        nameEs
        units
        unitsEs
        badge
        badgeEs
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

export const customerByName = /* GraphQL */ `
    query CustomerByName(
        $name: String!
        $id: ModelIDKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelCustomerFilterInput
        $limit: Int
        $nextToken: String
    ) {
        customerByName(
            name: $name
            id: $id
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                slug
                name
                root
                country
                pages {
                    items {
                        role
                        page
                        head
                        children
                        section
                        permissions
                        id
                        customerPagesId
                        owner
                    }
                    nextToken
                }
                versions {
                  items {
                    id
                    coreGroup
                    cli
                    nucleus
                    secret
                    stream
                    createdAt
                    updatedAt
                    customerVersionsId
                    owner
                  }
                  nextToken
                }
                createdAt
                updatedAt
                owner
            }
            nextToken
        }
    }
`;

export const powerPlantsByCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      slug
      name
      root
      powerPlants {
        items {
          name
          googlePlaceId
          country
          formattedAddress
          addressComponents
          lat
          lng
          description
          iowatts {
            items {
                name
                powerPlant
                hardware
                coreGroup
                core
                ip
                proxy
                status
                installStatus
                secretVersion
                streamVersion
                cliVersion
                nucleusVersion
                ownerStreamsVersion
                ownerDevicesVersion
                ownerConfigsVersion
                installCommands {
                  items {
                    executionId
                    user
                    step
                    status
                    instance
                    stdout
                    stderr
                    error
                    id
                    createdAt
                    updatedAt
                    ioWattInstallCommandsId
                    owner
                  }
                  nextToken
                }
                programs {
                  items {
                    program {
                      id
                      name
                      goal
                      goalUnits
                      progCalendar {
                        name
                        schedule
                        type
                      }
                    }
                  }
                  nextToken
                }
                createdAt
                id
                updatedAt
                customerIowattsId
                owner
            }
            nextToken
          }
          id
          createdAt
          updatedAt
          customerPowerPlantsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;

export const listPages = /* GraphQL */ `
  query ListPages(
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        role
        page
        head
        children
        section
        permissions
        id
        customerPagesId
        owner
      }
      nextToken
    }
  }
`;

export const iowattByCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      iowatts {
        items {
          id
          name
          coreGroup
          core
          status
          installStatus
          customerIowattsId
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;

export const configByCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      configs {
        items {
          iowattId
          code
          orderType
          message
          description
          config
          customerConfigsId
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;