export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      slug
      name
      root
      country
    }
  }
`;

export const createPage = /* GraphQL */ `
  mutation CreatePage(
    $input: CreatePageInput!
    $condition: ModelPageConditionInput
  ) {
    createPage(input: $input, condition: $condition) {
      role
      page
      head
      children
      section
      permissions
      id
      customerPagesId
      owner
    }
  }
`;

export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      slug
      name
      root
    }
  }
`;

export const createMagnitude = /* GraphQL */ `
  mutation CreateMagnitude(
    $input: CreateMagnitudeInput!
    $condition: ModelMagnitudeConditionInput
  ) {
    createMagnitude(input: $input, condition: $condition) {
      name
      nameEs
      units
      unitsEs
      badge
      badgeEs
      id
      createdAt
      updatedAt
      owner
    }
  }
`;

export const createIoWatt = /* GraphQL */ `
  mutation CreateIoWatt(
    $input: CreateIoWattInput!
    $condition: ModelIoWattConditionInput
  ) {
    createIoWatt(input: $input, condition: $condition) {
      name
      powerPlant
      hardware
      core
      coreGroup
      ip
      proxy
      status
      installStatus
      secretVersion
      streamVersion
      cliVersion
      nucleusVersion
      ownerStreamsVersion
      ownerDevicesVersion
      ownerConfigsVersion
      installCommands {
        nextToken
      }
      gateways {
        nextToken
      }
      programs {
        nextToken
      }
      createdAt
      id
      updatedAt
      customerIowattsId
      owner
    }
  }
`;
